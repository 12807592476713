import { Accordion, Flex, Skeleton } from '@mantine/core';
import _ from 'lodash';
import { FunctionComponent, useState } from 'react';
import { getIcon } from '../../../data/icon-registry';
import { Quest, SortStructure } from '../../../data/quest';
import { QuestsList } from '../QuestsList/QuestsList';

export interface QuestAccordionProps {
  template: (string | null)[];
  quests: Quest[];
  isLoading?: boolean;
  onQuestToEdit?: (quest: Quest) => void;
  onSortChange: (sortStructure: SortStructure) => void;
}

const QuestAccordion: FunctionComponent<QuestAccordionProps> = props => {
  const { quests, onSortChange, isLoading, onQuestToEdit, template } = props;
  const [openedElements, setOpenedElements] = useState<string[]>([
    quests[0]?.template,
  ]);

  const getByTitle = (questType: string | null) => {
    if (!questType || questType === 'null') {
      return 'Generic';
    }
    return (
      questType.replace(/_/g, ' ').replace(/-/g, ' ').charAt(0).toUpperCase() +
      questType.replace(/_/g, ' ').replace(/-/g, ' ').slice(1)
    );
  };

  const getElements = () => {
    return template.map((questType, index) => {
      return (
        <Accordion.Item
          key={`accordion-item-${index}`}
          p={'0.5rem'}
          value={questType ?? ''}
        >
          <Accordion.Control
            key={`accordion-control-${index}`}
            icon={getIcon(quests.find(x => x?.icon === questType)?.icon)}
          >
            {getByTitle(questType)}
          </Accordion.Control>
          <Accordion.Panel key={`accordion-panel-${index}`}>
            <center>
              <QuestsList
                key={`quest-list-${index}`}
                quests={
                  quests.filter(
                    x =>
                      x.icon === questType || (!x.icon && questType === 'null')
                  ) ?? []
                }
                onSortChange={onSortChange}
                isLoading={isLoading}
                onQuestToEdit={onQuestToEdit}
              />
            </center>
          </Accordion.Panel>
        </Accordion.Item>
      );
    });
  };

  if (isLoading) {
    return (
      <Flex gap={'1rem'} direction={'column'}>
        {_.map(new Array(6), (_x, index) => {
          return <Skeleton key={index} height={'100px'} width={'28rem'} />;
        })}
      </Flex>
    );
  }

  return (
    <Accordion
      onChange={setOpenedElements}
      value={openedElements}
      multiple
      transitionDuration={700}
      variant="contained"
      miw={'50%'}
      w={'fit-content'}
    >
      {getElements()}
    </Accordion>
  );
};

export default QuestAccordion;
