import { Checkbox, Flex, MantineStyleProp, Table } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { TableSkeletons } from './componets/Skeletons';

export interface ForgeTableProps {
  striped?: boolean;
  skeletonAmount?: number;
  onRowSelection?: (index: number, value: boolean) => void;
  allowRowSelection?: boolean;
  isLoading?: boolean;
  headers: string[];
  rows?: Row[];
  style?: MantineStyleProp;
}

export interface Row {
  elements: any;
}
export const ForgeTable: FunctionComponent<ForgeTableProps> = props => {
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    setIsLoading(props.isLoading ?? false);
  }, [props.isLoading]);

  const getRows = () => {
    if (!props.rows) return null;
    const rows = props.rows.map((row, index) => (
      <Table.Tr
        key={`row-${index}`}
        bg={
          selectedRows.includes(index)
            ? 'var(--mantine-color-blue-light)'
            : undefined
        }
      >
        {props.allowRowSelection && (
          <Table.Td>
            <Checkbox
              size="xs"
              checked={selectedRows.includes(index)}
              onChange={e => {
                setSelectedRows(
                  e.currentTarget.checked
                    ? [...selectedRows, index]
                    : selectedRows.filter(i => i !== index)
                );
                props.onRowSelection?.(index, e.currentTarget.checked);
              }}
            ></Checkbox>
          </Table.Td>
        )}
        {row.elements.map((element: any, eIndex: any) => (
          <Table.Td key={`element-${eIndex}-from-${index}`}>{element}</Table.Td>
        ))}
      </Table.Tr>
    ));
    return rows;
  };

  if (!isLoading)
    return (
      <Table
        c={'black'}
        highlightOnHover
        horizontalSpacing={'xl'}
        style={{ ...props.style }}
      >
        <Table.Thead>
          <Table.Tr>
            {props.allowRowSelection && (
              <Table.Th>
                <Checkbox
                  size="xs"
                  onChange={e => {
                    e.currentTarget.checked
                      ? setSelectedRows(
                          props.rows?.map((_, index) => index) ?? []
                        )
                      : setSelectedRows([]);
                  }}
                ></Checkbox>
              </Table.Th>
            )}
            {props.headers.map((header, index) => (
              <Table.Th key={`header-${index}`}>{header}</Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{getRows()}</Table.Tbody>
      </Table>
    );

  return (
    <Flex h={'100%'} w={'100%'} direction={'column'}>
      {TableSkeletons(props.skeletonAmount ?? 10)}
    </Flex>
  );
};

export default Table;
