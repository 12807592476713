import { Button, Flex, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IoInformationCircleOutline, IoSearch } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import AccountInfo from '../../components/AccountInfo/AccountInfo';
import InformationTabs from '../../components/InformationTabs/InformationTabs';
import { notImplementedFeature } from '../../data/not-implemented-function';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useNotification } from '../../hooks/use-notification';
import { useTier } from '../../hooks/use-tier';
import { accountService } from '../../services/api-client/account-service';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const notification = useNotification();
  const navigate = useNavigate();
  const onUpsellClose = () => {
    navigate('/home');
  };
  const [{ isFreeTier, component, isClose, setIsClose, setContactUsReason }] =
    useTier(onUpsellClose);
  const urlParams = new URLSearchParams(window.location.search);
  const [accountName, setAccountName] = useState<string>(
    urlParams.get('userName') ?? ''
  );
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [accountInfoData, accountInfoDataLoading, setData] = useDataFetcher({
    serviceCall() {
      return accountService.getAccountByName(accountName);
    },
    conditionForExecution: !!accountName && !isFreeTier,
    dependencies: [searchTrigger, isFreeTier],
    onError() {
      setData(undefined);
    },
  });

  useEffect(() => {
    if (isFreeTier) {
      setContactUsReason('User Lookup Page');
      setIsClose(false);
    }
  }, [isFreeTier]);

  return (
    <Flex
      p={'1rem'}
      className="UserLookUpWrapper"
      direction={'column'}
      gap={'md'}
    >
      {isFreeTier && !isClose && component}
      <Flex gap={'0.3rem'} align={'flex-end'}>
        <TextInput
          placeholder="Type name or email"
          label="Account Name"
          leftSection={<IoSearch size={'1.5rem'} />}
          w={'fit-content'}
          maw={'20rem'}
          value={accountName}
          onChange={e => setAccountName(e.currentTarget.value)}
        />
        <Button
          aria-label={'search'}
          disabled={!accountName}
          onClick={() => {
            setSearchTrigger(!searchTrigger);
          }}
        >
          Search
        </Button>
      </Flex>
      <InformationTabs
        tabDefinitions={[
          {
            title: 'Account Info',
            icon: <IoInformationCircleOutline size={'1.5rem'} />,
            content: (
              <AccountInfo
                accountInfoData={accountInfoData}
                isLoading={accountInfoDataLoading}
                onRemoveAvatar={() => {
                  notImplementedFeature(notification);
                }}
              />
            ),
          },
        ]}
      ></InformationTabs>
    </Flex>
  );
};

export const UserLookupPage = withAuthProtection(Page);
