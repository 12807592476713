import { Divider, Flex } from '@mantine/core';
import { FunctionComponent } from 'react';
import { Socials } from '../../../data/campaign';
import SocialElement from '../../Campaign/VerticalCampaign/components/SocialElement';

export interface SocialSectionProps {
  socials: Socials;
  onSocialsChange?: (socials: Socials) => void;
}

const SocialSection: FunctionComponent<SocialSectionProps> = props => {
  const { socials, onSocialsChange } = props;

  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <Divider label={'Socials'} />
      <SocialElement
        defaultValue={socials?.discord}
        fieldName={'discord'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultValue={socials?.twitter}
        fieldName={'twitter'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultValue={socials?.youtube}
        fieldName={'youtube'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultValue={socials?.telegram}
        fieldName={'telegram'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultValue={socials?.medium}
        fieldName={'medium'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultValue={socials?.opensea}
        fieldName={'opensea'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
    </Flex>
  );
};

export default SocialSection;
