import { Anchor, Button, Flex, Image, Modal, Text, Title } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { LocalImages } from '../../assets/images';

interface PublishDialogProps {
  opened?: boolean;
  isLoading?: boolean;
  onPublish?: () => void;
  onClose?: () => void;
}

export const PublishDialog: FunctionComponent<PublishDialogProps> = props => {
  const [opened, setOpened] = useState<boolean>();

  useEffect(() => {
    setOpened(props.opened);
  }, [props.opened]);

  return (
    <Modal
      zIndex={600}
      opened={opened ?? false}
      onClose={() => props.onClose?.()}
      centered
    >
      <Flex direction={'column'} gap={'lg'} p={'sm'} pt={0} pb={'0'}>
        <Image w={'8rem'} src={LocalImages.logo} />
        <Flex direction={'column'} gap={'0.5rem'}>
          <Title order={3}>Publish</Title>
          <Text c={'gray'}>
            Onward and Upward! Click Publish to allow thousands of gamers to
            participate in your new loyalty program.
          </Text>
        </Flex>
        <Flex gap={'md'} justify={'center'} w={'100%'}>
          <Button
            variant="outline"
            w={'100%'}
            onClick={() => props.onClose?.()}
          >
            Cancel
          </Button>
          <Button
            w={'100%'}
            loading={props.isLoading}
            color={'blue'}
            onClick={() => props.onPublish?.()}
          >
            Publish
          </Button>
        </Flex>
        <Flex w={'100%'} align={'center'} direction={'column'}>
          <Text c={'dark'} size="sm">
            By publishing you accept our{' '}
            <Anchor
              href="https://forge-gg.gitbook.io/the-fine-print"
              target="_blank"
              underline="hover"
              c={'blue'}
            >
              Terms of Services
            </Anchor>
          </Text>
        </Flex>
      </Flex>
    </Modal>
  );
};
