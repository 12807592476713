import {
  Divider,
  Flex,
  MultiSelect,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { Campaign } from '../../../data/campaign';
import { gameModesData } from '../../../data/game-mode';
import { genresData, getSeparateElements } from '../../../data/genre';
import { isValidLink } from '../../../helper/validators';

export interface ExtraDataSectionProps {
  campaign?: Campaign;
  onCampaignChange?: (campaign: Campaign) => void;
  setPublishEnabled: (enabled: boolean) => void;
}

const ExtraDataSection: FunctionComponent<ExtraDataSectionProps> = props => {
  const { onCampaignChange, campaign } = props;
  const [description, setDescription] = useState(props.campaign?.description);
  const [genre, setGenre] = useState(props.campaign?.genre);
  const [website, setWebsite] = useState(props.campaign?.extra_data?.website);
  const [playNow, setPlayNow] = useState(props.campaign?.extra_data?.play_now);
  const [partnerName, setPartnerName] = useState(
    props.campaign?.extra_data?.partner_name
  );
  const [releaseDate, setReleaseDate] = useState(
    props.campaign?.extra_data?.release_date
  );
  const [gameModes, setGameModes] = useState(
    props.campaign?.extra_data?.game_mode
  );
  const [platform, setPlatform] = useState(
    props.campaign?.extra_data?.platform
  );
  const [developer, setDeveloper] = useState(
    props.campaign?.extra_data?.developer
  );

  useEffect(() => {
    evaluateConditionForPublish();
  }, [
    description,
    genre,
    website,
    playNow,
    partnerName,
    releaseDate,
    gameModes,
    platform,
    developer,
  ]);

  const evaluateConditionForPublish = () => {
    const isValid =
      !!description &&
      description !== '' &&
      !!genre &&
      genre !== '' &&
      isValidLink(website) &&
      !!playNow &&
      playNow !== '' &&
      !!partnerName &&
      partnerName !== '' &&
      !!releaseDate &&
      releaseDate !== '' &&
      !!gameModes &&
      gameModes !== '' &&
      !!platform &&
      platform !== '' &&
      !!developer &&
      developer !== '';
    props.setPublishEnabled?.(isValid);
  };

  if (!campaign || !campaign?.extra_data) return <></>;
  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <Divider label={'Extra data'} />
      <Flex direction={'column'} align={'flex-end'} w={'100%'} gap={'0.15rem'}>
        <Textarea
          w={'100%'}
          error={
            !description || description === '' ? 'This field is required' : null
          }
          defaultValue={description}
          required
          maxLength={1000}
          resize="vertical"
          label={'Game Description'}
          description={`
                    The description of the game that will be displayed in the loyalty program.
                    `}
          onChange={event => {
            setDescription(event.currentTarget.value);
            onCampaignChange?.({
              ...campaign,
              description: event.currentTarget.value,
            });
          }}
        />
        {
          <Text size="xs" c={'#868e96'}>
            Limited to 1000 characters
          </Text>
        }
      </Flex>
      <MultiSelect
        label="Genre"
        description="Select the genre of your game."
        searchable
        data={genresData}
        defaultValue={getSeparateElements(genre) ?? []}
        w={'100%'}
        onChange={genres => {
          setGenre(genres.join(', '));
          onCampaignChange?.({
            ...campaign,
            genre: genres.join(', '),
          });
        }}
        required
        error={!genre || genre === '' ? 'This field is required' : null}
      />
      <TextInput
        w={'100%'}
        error={!isValidLink(website) ? 'This field is required' : null}
        required
        defaultValue={website}
        label={'Website'}
        description={'Set you game website for the users to access it easily.'}
        onChange={event => {
          setWebsite(event.currentTarget.value);
          onCampaignChange?.({
            ...campaign,
            extra_data: {
              ...campaign.extra_data,
              website: event.currentTarget.value,
            },
          });
        }}
      />
      <TextInput
        w={'100%'}
        error={!platform || platform === '' ? 'This field is required' : null}
        required
        defaultValue={platform}
        label={'Platform'}
        description={
          'Set you game platform for the users to know the options they have to play your game.'
        }
        onChange={event => {
          setPlatform(event.currentTarget.value);
          onCampaignChange?.({
            ...campaign,
            extra_data: {
              ...campaign.extra_data,
              platform: event.currentTarget.value,
            },
          });
        }}
      />
      <TextInput
        w={'100%'}
        error={!developer || developer === '' ? 'This field is required' : null}
        required
        defaultValue={developer}
        label={'Developer'}
        description={'Set the game developer.'}
        onChange={event => {
          setDeveloper(event.currentTarget.value);
          onCampaignChange?.({
            ...campaign,
            extra_data: {
              ...campaign.extra_data,
              developer: event.currentTarget.value,
            },
          });
        }}
      />
      <MultiSelect
        label="Game Mode"
        description="Select your game mode."
        defaultValue={getSeparateElements(gameModes) ?? []}
        data={gameModesData}
        w={'100%'}
        onChange={gameModes => {
          setGameModes(gameModes.join(', '));
          onCampaignChange?.({
            ...campaign,
            extra_data: {
              ...campaign.extra_data,
              game_mode: gameModes.join(', '),
            },
          });
        }}
        required
        error={!gameModes || gameModes === '' ? 'This field is required' : null}
      />
      <TextInput
        w={'100%'}
        error={
          !releaseDate || releaseDate === '' ? 'This field is required' : null
        }
        required
        defaultValue={releaseDate}
        label={'Release Date'}
        description={'Set the release date of your campaign.'}
        onChange={event => {
          setReleaseDate(event.currentTarget.value);
          onCampaignChange?.({
            ...campaign,
            extra_data: {
              ...campaign.extra_data,
              release_date: event.currentTarget.value,
            },
          });
        }}
      />
      <TextInput
        w={'100%'}
        error={
          !partnerName || partnerName === '' ? 'This field is required' : null
        }
        required
        defaultValue={partnerName}
        label={'Partner Name'}
        description={'Set the partner name of your game.'}
        onChange={event => {
          setPartnerName(event.currentTarget.value);
          onCampaignChange?.({
            ...campaign,
            extra_data: {
              ...campaign.extra_data,
              partner_name: event.currentTarget.value,
            },
          });
        }}
      />
      <TextInput
        w={'100%'}
        error={!playNow || playNow === '' ? 'This field is required' : null}
        required
        defaultValue={playNow}
        label={'Play Now Link'}
        description={
          'Link for users to download or play your game. If still in the pre-testing phase, link to your website.'
        }
        onChange={event => {
          setPlayNow(event.currentTarget.value);
          onCampaignChange?.({
            ...campaign,
            extra_data: {
              ...campaign.extra_data,
              play_now: event.currentTarget.value,
            },
          });
        }}
      />
    </Flex>
  );
};

export default ExtraDataSection;
