import { Accordion, Flex } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { StripeCharge } from '../../../data/stripe-transaction-data';
import ChargeAccordionItem, {
  ChargeAccordionItemProps,
} from './components/ChargeAccordionItem';

export interface ChargesAccordionProps {
  charges: StripeCharge[];
}

const ChargesAccordion: FunctionComponent<ChargesAccordionProps> = props => {
  const { charges } = props;
  const [itemsProps, setItemsProps] = useState<ChargeAccordionItemProps[]>([]);
  useEffect(() => {
    if (charges) {
      const itemsProps: ChargeAccordionItemProps[] = charges.map(charge => {
        return {
          id: charge.id,
          status: charge.paid ? 'Paid' : 'Not Paid',
          amount: (charge.amount / 100).toFixed(2),
          currency: charge.currency,
          paymentDetail: {
            method: charge.payment_method_details.card ? 'card' : 'Unknown',
            lastCard4Numbers: charge.payment_method_details.card.last4,
            cardType: charge.payment_method_details.card.brand,
          },
        } as ChargeAccordionItemProps;
      });
      setItemsProps(itemsProps);
    }
  }, [charges]);

  const getItems = () => {
    if (itemsProps) {
      return itemsProps.map((itemProps, index) => {
        return (
          <Accordion.Item
            key={`accordion-item-${index}`}
            w={'100%'}
            value={itemProps.id}
          >
            <Accordion.Control chevron={' '} key={`accordion-control-${index}`}>
              <ChargeAccordionItem {...itemProps} />
            </Accordion.Control>
          </Accordion.Item>
        );
      });
    }
  };

  return (
    <Flex justify={'center'} w={'100%'}>
      <Accordion
        style={{ cursor: 'default' }}
        chevronPosition="right"
        w={'100%'}
        variant="contained"
      >
        {getItems()}
      </Accordion>
    </Flex>
  );
};

export default ChargesAccordion;
