import { useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { NotificationService } from '../services/notification-service';

export function useNotification() {
  const toast = useToast();
  const [dialogService] = useState<NotificationService>(
    new NotificationService((options: object) => {
      toast(options);
    })
  );
  return dialogService;
}
