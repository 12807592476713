import { Button, Card, Flex, Text, Title } from '@mantine/core';
import _ from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { IoGameControllerOutline } from 'react-icons/io5';
import { Campaign } from '../../data/campaign';
import { CampaignAssetModification } from '../../data/campaign-assets';
import { CampaignCommonAssetsForm } from '../Campaign/CampaignCommonAssetsForm/CampaignCommonAssetsForm';
import { CampaignScreenshotsForm } from '../Campaign/CampaignScreenshotsForm/CampaignScreenshotsForm';
import BasicInfoSection from './components/BasicAssetsSection';
import ExtraDataSection from './components/ExtradataSection';
import SocialSection from './components/SosialSection';

export interface GameEditionSectionProps {
  campaign?: Campaign;
  addNewDraft?: () => void;
  addingDraft?: boolean;
  onCampaignChange: (
    editedCampaign: Campaign,
    newAssets: CampaignAssetModification
  ) => void;
  setSaveEnabled?: (enabled: boolean) => void;
  onPublishEnabled?: (enabled: boolean) => void;
}

const GameEditionSection: FunctionComponent<
  GameEditionSectionProps
> = props => {
  const {
    addNewDraft,
    addingDraft,
    onCampaignChange,
    setSaveEnabled,
    onPublishEnabled,
  } = props;
  const initialCampaignState = { ...props.campaign };
  const [campaign, setCampaign] = useState(props.campaign);
  const [publishEnabledFromBasicSection, setPublishEnabledFromBasicSection] =
    useState(false);
  const [
    publicEnabledFromExtraDataSection,
    setPublishEnabledFromExtraDataSection,
  ] = useState(false);
  const [publishEnabledFromCommonAssets, setPublishEnabledFromCommonAssets] =
    useState(false);
  const [commonAssets, setCommonAssets] = useState<CampaignAssetModification>(
    {}
  );
  useEffect(() => {
    onPublishEnabled?.(
      publishEnabledFromBasicSection &&
        publicEnabledFromExtraDataSection &&
        publishEnabledFromCommonAssets
    );
  }, [
    publishEnabledFromBasicSection,
    publicEnabledFromExtraDataSection,
    publishEnabledFromCommonAssets,
  ]);
  const [screenshotsAssets, setScreenshotsAssets] =
    useState<CampaignAssetModification>({});

  useEffect(() => {
    setCampaign(props.campaign);
  }, [props.campaign]);

  useEffect(() => {
    handleCampaignChange();
  }, [campaign, commonAssets, screenshotsAssets]);

  const handleCampaignChange = () => {
    if (!campaign) {
      return;
    }
    const assets: CampaignAssetModification = {
      ...commonAssets,
      screenshots: screenshotsAssets.screenshots,
    };
    onCampaignChange(campaign, assets);
  };

  const IsExtraDataChanged = (newCampaign: Campaign) => {
    return !_.isEqual(newCampaign, initialCampaignState);
  };

  const getContent = () => {
    if (!campaign) return;
    return (
      <Flex
        direction={'column'}
        w={'100%'}
        h={'100%'}
        align={'center'}
        justify={'center'}
      >
        <Title order={1}>
          {campaign.title || campaign.title !== ''
            ? campaign.title
            : '[Game Name]'}
        </Title>
        <BasicInfoSection
          title={campaign?.title}
          subtitle={campaign?.subtitle}
          onTitleChange={title => {
            const updatedCampaign = { ...campaign, title: title };
            setSaveEnabled?.(IsExtraDataChanged(updatedCampaign));
            setCampaign(updatedCampaign);
          }}
          onSubtitleChange={subtitle => {
            const updatedCampaign = { ...campaign, subtitle: subtitle };
            setSaveEnabled?.(IsExtraDataChanged(updatedCampaign));
            setCampaign(updatedCampaign);
          }}
          setPublishEnabled={setPublishEnabledFromBasicSection}
        />
        <CampaignCommonAssetsForm
          campaign={campaign}
          onAssetsChange={(assets: CampaignAssetModification) => {
            setSaveEnabled?.(
              !!assets && Object.values(assets).some(x => x !== undefined)
            );
            setCommonAssets(assets);
          }}
          setPublishEnabled={setPublishEnabledFromCommonAssets}
        />
        <ExtraDataSection
          campaign={campaign}
          onCampaignChange={(editedCampaign: Campaign) => {
            const isExtraDataChanged = IsExtraDataChanged(editedCampaign);
            setSaveEnabled?.(isExtraDataChanged);
            setCampaign(editedCampaign);
          }}
          setPublishEnabled={setPublishEnabledFromExtraDataSection}
        />
        <SocialSection
          socials={campaign.extra_data.social}
          onSocialsChange={socials => {
            const updatedCampaign = {
              ...campaign,
              extra_data: { ...campaign.extra_data, social: socials },
            };
            setSaveEnabled?.(IsExtraDataChanged(updatedCampaign));
            setCampaign(updatedCampaign);
            setSaveEnabled?.(true);
          }}
        />
        <CampaignScreenshotsForm
          campaign={campaign}
          onSaved={(assets: CampaignAssetModification) => {
            setSaveEnabled?.(
              !!assets.screenshots &&
                Object.values(assets.screenshots).some(x => x !== undefined)
            );
            setScreenshotsAssets(assets);
            setSaveEnabled?.(true);
          }}
        />
      </Flex>
    );
  };
  const getEmptyState = () => {
    return (
      <Flex w={'100%'} h={'100%'} align={'center'} justify={'center'}>
        <Flex
          direction={'column'}
          align={'center'}
          gap={'1rem'}
          justify={'center'}
        >
          <IoGameControllerOutline color="#1c7ed6" size={'13.375rem'} />
          <Card p={'2rem'} maw={'25rem'} withBorder={true} radius={'1rem'}>
            <Text ta="center">
              Customize your campaigns and increase your player engagement. Add
              new drafts campaign to your game and make it more fun 🙂.
            </Text>
          </Card>
          <Flex direction={'row'} gap={'1rem'}>
            <Button loading={addingDraft} onClick={addNewDraft}>
              Add new Draft
            </Button>
          </Flex>
        </Flex>
      </Flex>
    );
  };
  return (
    <Flex w={'70%'} h={'100%'}>
      {campaign ? getContent() : getEmptyState()}
    </Flex>
  );
};

export default GameEditionSection;
