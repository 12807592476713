import { configuration } from '../../configuration';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class TwitterVerificationService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'twitter');
  }

  public async isValidAccountName(name: string): Promise<boolean> {
    return await this.apiClient.get(this.url(`account/isValid/${name}`));
  }

  public async getAccountIdFromUserName(name: string): Promise<string> {
    return await this.apiClient.get(this.url(`account/userId/${name}`));
  }
}
export const twitterVerificationService = new TwitterVerificationService(
  new BearerTokenApiClient(configuration.backendApi.url)
);
