import { configuration } from '../../configuration';
import { Organization } from '../../data/organization';
import { SignUpCredentials } from '../../data/sign-up-credentials';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class OrganizationService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'organization');
  }

  public async create(signUpCredentials: SignUpCredentials) {
    return await this.apiClient.post(this.url('signup'), signUpCredentials);
  }

  public async getOrganizationsByEmail(email: string): Promise<Organization[]> {
    const url = `${this.url()}${email}`;
    const partners = await this.apiClient.get<Organization[]>(url);
    if (!partners) {
      return [];
    }
    return partners;
  }
}
export const organizationService = new OrganizationService(
  new BearerTokenApiClient(configuration.backendApi.url)
);
