import { Flex, Skeleton } from '@mantine/core';
import { FunctionComponent } from 'react';

import { CollaboratorInvite } from '../../data/collaborator-invite';
import CollaboratorInviteElement from './components/CollaboratorInviteElement';

export interface CollaboratorInvitesProps {
  onRefresh?: (invite: CollaboratorInvite) => void;
  invites: CollaboratorInvite[];
  isLoading: boolean;
}

const CollaboratorInviteList: FunctionComponent<
  CollaboratorInvitesProps
> = props => {
  if (props.isLoading) {
    return (
      <>
        {Array.from({ length: 5 }).map((_, index) => {
          return <Skeleton key={index} w={'100%'} h={'3rem'} />;
        })}
      </>
    );
  }

  if (!props.invites || props.invites.length === 0) {
    return <div>No invites</div>;
  }

  return (
    <Flex
      className="CollaboratorInvitesWrapper"
      w={'100%'}
      gap={'sm'}
      direction={'column'}
    >
      {props.invites.map((invite, index) => {
        return (
          <CollaboratorInviteElement
            key={`invite-${index}`}
            invite={invite}
            onRefresh={() => props.onRefresh?.(invite)}
          />
        );
      })}
    </Flex>
  );
};

export default CollaboratorInviteList;
