import { Flex, Text } from '@mantine/core';

export const actionPageHints = [
  {
    id: 'important-note',

    label: 'Important note',
    description: 'To keep in mind',
    content:
      'You will get the most value out of your Forge Loyalty Program if there are many Actions for users to complete, with new Actions added regularly throughout the course of your campaign, even as often as daily.',
  },

  {
    id: 'ideas',
    label: 'Some ideas to get started',
    description: 'Ready to get inspired?',
    content: (
      <Flex pl={'1rem'} pr={'1rem'}>
        <ul>
          <li>
            <Text>Log PvP matches during a playtest (Forge API)</Text>
          </li>
          <li>
            <Text>Add an in-game friend (Forge API)</Text>
          </li>
          <li>
            <Text>Complete the tutorial (Forge API)</Text>
          </li>
          <li>
            <Text>Drive engagement to your latest announcement on X</Text>
          </li>
          <li>
            <Text>Post about or add a hashtag to X username</Text>
          </li>
          <li>
            <Text>Tune into your AMA on X or Discord</Text>
          </li>
          <li>
            <Text>Join your Telegram or Discord channel</Text>
          </li>
          <li>
            <Text>Wishlist your game on Steam</Text>
          </li>
          <li>
            <Text>Reply to a X post with fan art</Text>
          </li>
          <li>
            <Text>Watch your latest gameplay trailer on YouTube</Text>
          </li>
          <li>
            <Text>Tune into your weekly Twitch live stream</Text>
          </li>
          <li>
            <Text>...and more! Get creative with it 🙂</Text>
          </li>
        </ul>
      </Flex>
    ),
  },
];
