import {
  Anchor,
  Avatar,
  Button,
  Flex,
  FlexProps,
  Menu,
  Select,
  Text,
  Tooltip,
} from '@mantine/core';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { BiCheck, BiLogOut, BiUser } from 'react-icons/bi';
import { MdArrowDropDown } from 'react-icons/md';
import { configuration } from '../../configuration';
import { CampaignDetail } from '../../data/campaign';
import { CampaignOption } from '../../data/campaign-option';
import { Organization } from '../../data/organization';
import CampaignSelector from '../CampaignSelector/CampaignSelector';
import { PublishDialog } from '../PublishDialog/PublishDialog';
import './ToolBar.scss';

export interface AvatarMenuItem {
  displayName: string;
  icon: ReactNode;
  onClick?: () => void;
}

export interface ToolBarProps {
  onOrganizationChange?: (partnerSlug: string | null) => void;
  organizations: Organization[];
  selectedOrganization: Organization | null;
  selectedCampaign: CampaignDetail | null;
  name?: string;
  profileImage?: string;
  avatarMenuItems?: AvatarMenuItem[];
  expanded?: boolean;
  onLogoutClick?: () => void;
  onMenuClick?: () => void;
  onSearchClick?: (value: string) => void;
  style?: FlexProps;
  campaignOptions?: CampaignOption[];
  onAddCampaign?: () => void;
  onCampaignSelection?: (campaignOption: CampaignOption) => void;
  selectedCampaignOption?: CampaignOption;
  loadingCampaignsOptions?: boolean;
  addingCampaign?: boolean;
  publishingCampaign?: boolean;
  showMultiCampaign?: boolean;
  cloneOption: boolean;
  setCloneOption?: (checked: boolean) => void;
  isCampaignValid?: boolean;
  onPublish?: () => void;
  loadingCampaignData?: boolean;
  publishDialogOpened?: boolean;
}

export const ToolBar: FunctionComponent<ToolBarProps> = props => {
  const {
    onAddCampaign,
    onCampaignSelection,
    selectedCampaignOption,
    loadingCampaignsOptions,
    addingCampaign,
    showMultiCampaign,
    cloneOption,
    setCloneOption,
    onOrganizationChange,
    selectedOrganization,
    publishingCampaign,
    onPublish,
    isCampaignValid,
    loadingCampaignData,
    publishDialogOpened,
  } = props;
  const baseUrl = configuration.forgeApi.baseUrl.replace('/api', '');
  const [isPublishOpened, setIsPublishOpened] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState<
    CampaignOption[] | undefined
  >(props.campaignOptions);

  useEffect(() => {
    if (showMultiCampaign) {
      const tempIsPreview =
        campaignOptions?.find(
          x => x.draft_campaign_id === selectedCampaignOption?.draft_campaign_id
        )?.published === false;
      setIsPreview(tempIsPreview);
      return;
    }
    setIsPreview(props.selectedCampaign?.hidden === false);
  }, [selectedCampaignOption, props.selectedCampaign]);

  const getCampaignPageUrl = () => {
    if (showMultiCampaign) {
      return `${baseUrl}/game/${selectedOrganization?.gameName}${
        isPreview
          ? `/${selectedCampaignOption?.draft_campaign_id}?preview=true`
          : ''
      }`;
    }
    return `${baseUrl}/game/${selectedOrganization?.gameName}${isPreview ? `/${props.selectedCampaign?.campaignId}?preview=true` : ''}`;
  };
  const [organizations] = useState<Organization[] | undefined>(
    props.organizations
  );

  useEffect(() => {
    setCampaignOptions(props.campaignOptions);
  }, [props.campaignOptions]);

  useEffect(() => {
    setIsPublishOpened(publishDialogOpened ?? false);
  }, [publishDialogOpened]);

  const getSortedOrganizations = () => {
    if (!organizations) {
      return [];
    }
    return organizations.sort((a, b) => a.partner.localeCompare(b.partner));
  };
  return (
    <Flex
      w={'100%'}
      h={'100%'}
      bg="#fff"
      align={'center'}
      justify={'space-between'}
      p={'16px 32px'}
      gap={'md'}
      classNames={{ root: 'ToolBar' }}
      {...props.style}
    >
      <Flex align={'center'} direction={'row'} gap={'sm'}>
        {showMultiCampaign && (
          <CampaignSelector
            campaignOptions={campaignOptions}
            onAddCampaign={() => onAddCampaign?.()}
            onCampaignSelection={onCampaignSelection}
            selectedCampaignId={selectedCampaignOption?.draft_campaign_id}
            loadingCampaignsOptions={loadingCampaignsOptions}
            addingCampaign={addingCampaign}
            cloneOption={cloneOption}
            onCloneOptionChange={isChecked => setCloneOption?.(isChecked)}
          />
        )}
      </Flex>
      <Flex gap={'16px'} align={'center'}>
        {showMultiCampaign && (
          <Tooltip
            maw={'15rem'}
            multiline
            openDelay={500}
            label="Make sure all the required fields are properly field before publishing the changes."
          >
            <Button
              loading={
                publishingCampaign ||
                loadingCampaignsOptions ||
                addingCampaign ||
                loadingCampaignData
              }
              disabled={
                !selectedCampaignOption?.unpublished_changes || !isCampaignValid
              }
              onClick={() => setIsPublishOpened(true)}
            >
              Publish
            </Button>
          </Tooltip>
        )}
        <PublishDialog
          opened={isPublishOpened}
          isLoading={publishingCampaign}
          onPublish={onPublish}
          onClose={() => setIsPublishOpened(false)}
        />
        <Tooltip label={getCampaignPageUrl()} openDelay={500}>
          <Anchor href={getCampaignPageUrl()} target="_blank">
            <Button loading={loadingCampaignsOptions}>
              {isPreview ? 'Preview' : 'Game page'}
            </Button>
          </Anchor>
        </Tooltip>
        <Menu
          classNames={{
            dropdown: 'Dropdown',
            item: 'Item',
            itemSection: 'ItemSection',
          }}
          shadow="lg"
          position="bottom-end"
          offset={7}
        >
          <Menu.Target>
            <Flex
              className="AvatarContainer"
              align={'center'}
              c="#1F374E"
              gap={8}
            >
              <Avatar
                variant="filled"
                size={'32px'}
                color="#1F374E"
                alt={props.name}
                src={props.profileImage}
              >
                <BiUser size={'1.5rem'} />
              </Avatar>
              <Flex align={'center'}>
                <Text>{props.name}</Text>
                <MdArrowDropDown size={'1.5rem'} color="#6085A7" />
              </Flex>
            </Flex>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu
              classNames={{
                dropdown: 'Dropdown',
                item: 'Item',
                itemSection: 'ItemSection',
              }}
              width={200}
              shadow="md"
              position="left-start"
              closeOnItemClick={true}
            >
              <Select
                style={{ cursor: 'pointer' }}
                label="Switch Organizations"
                labelProps={{ pl: 'sm' }}
                leftSection={<BiCheck size={'1rem'} />}
                data={getSortedOrganizations().map(item => item.partner)}
                onChange={e => onOrganizationChange?.(e)}
                defaultValue={selectedOrganization?.partner.toString()}
                searchable={organizations && organizations.length >= 5}
              />
            </Menu>
            <Menu.Divider />
            <Menu.Item
              leftSection={<BiLogOut size={'1.6875rem'} />}
              onClick={props.onLogoutClick}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </Flex>
  );
};
