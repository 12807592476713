import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { authentication } from './authentication';
import { localStorageService } from './local-storage-service';

const ProtectedRouteElement: FunctionComponent<
  PropsWithChildren<any>
> = props => {
  const [isAuth, setAuth] = useState<boolean | null>(null);
  const location = useLocation();
  localStorageService.setActualPage(location.pathname);

  useEffect(() => {
    const auth = authentication.isAuthenticated();
    setAuth(auth);
  }, []);

  if (isAuth === null) {
    return <></>;
  }

  if (isAuth === false) {
    return <Navigate to={`/login?path=${location.pathname}`} replace />;
  }

  return props.children;
};

export const withAuthProtection = (Page: FunctionComponent) => {
  return () => {
    return (
      <ProtectedRouteElement>
        <Page />
      </ProtectedRouteElement>
    );
  };
};
