import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalImages } from '../../assets/images';
import { SignUp } from '../../components/SingUp/SignUp';
import { SignUpCredentials } from '../../data/sign-up-credentials';
import { useNotification } from '../../hooks/use-notification';
import { organizationService } from '../../services/api-client/organization-service';
import { MessageStatus } from '../../services/notification-service';

export const SignUpPage = () => {
  const navigate = useNavigate();
  const notification = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>();

  const handelSignUp = async (signUpCredentials: SignUpCredentials) => {
    try {
      setIsLoading(true);
      await organizationService.create(signUpCredentials);
      notification.notify(
        MessageStatus.Info,
        'Sign up',
        'Sign up was successful. Please login to continue with your account'
      );
      navigate('/login');
    } catch (e: any) {
      notification.notify(
        MessageStatus.Error,
        'Sign up',
        'Failed to sign up user.' + e.response.data.message
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <SignUp
      isLoading={isLoading}
      logo={LocalImages.logo}
      backToLogin={() => {
        navigate('/login');
      }}
      onSignUpClick={signUpCredentials => handelSignUp(signUpCredentials)}
    />
  );
};
