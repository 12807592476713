import {
  defaultMetadataDirectedFields,
  MetadataDirectedFields,
  notInheritedFromGamePage,
} from '../data/metadata-directed-fields';
import { Quest, QuestFieldMetadata, QuestVariable } from '../data/quest';
import { QuestTemplate } from '../data/templates';
import { formatDate } from '../helper/dates';

export const handleVariables = [
  'telegram_handle',
  'twitter_handle',
  'youtube_handle',
  'discord_handle',
  'discord_guild_id',
  'tweet_id',
  'discord_channel_id',
  'cashtag',
];

const notEditableQuestVariables = [
  (quest: Quest, questTemplate: QuestTemplate) => {
    if (questTemplate.template_variables.includes('campaign_id')) {
      quest.quest_variables.campaign_id = quest.campaign_id;
    }
  },

  (quest: Quest, questTemplate: QuestTemplate, questCount: number) => {
    if (questTemplate.template_variables.includes('quest_number')) {
      quest.quest_variables.quest_number = questCount + 1;
    }
  },
];

class QuestDataService {
  public getQuestTypes(quests: Quest[] | undefined) {
    if (!quests) {
      return [];
    }
    const result: (string | null)[] = [];
    quests.forEach(quest => {
      if (
        !result.includes(quest.icon) &&
        !(
          ['null', '', null, undefined].includes(quest.icon) &&
          result.includes('null')
        )
      ) {
        if (['null', '', null].includes(quest.icon)) {
          result.push('null');
        } else {
          result.push(quest.icon);
        }
      }
    });
    return result;
  }

  public getElementJson(
    questTemplate: QuestTemplate,
    questVariables: QuestVariable[],
    field: keyof QuestTemplate,
    metadataDirectedFields: MetadataDirectedFields
  ) {
    const object = questTemplate[field];
    let tempElementValue = JSON.stringify(object);
    if (!tempElementValue) {
      return;
    }
    questVariables.forEach(variable => {
      let tempVariableValue = this._getValue(
        variable.value,
        metadataDirectedFields
      );
      if (variable.key === field) {
        tempElementValue = tempVariableValue;
      }
      if (variable.key === 'link') {
        tempVariableValue = decodeURIComponent(tempVariableValue);
      }
      tempElementValue = tempElementValue
        ?.toString()
        .replaceAll(`{${variable.key}}`, tempVariableValue);
    });
    return JSON.parse(tempElementValue);
  }

  public getElement(
    questTemplate: QuestTemplate,
    questVariables: QuestVariable[],
    field: keyof QuestTemplate,
    metadataDirectedFields: MetadataDirectedFields
  ): any {
    let tempElementValue = this._getValue(
      questTemplate[field],
      metadataDirectedFields
    );
    if (!tempElementValue) {
      return;
    }
    questVariables.forEach(variable => {
      let tempVariableValue = variable.value;
      if (variable.key === field) {
        tempElementValue = tempVariableValue;
      }
      if (variable.key === 'link') {
        tempVariableValue = decodeURIComponent(tempVariableValue);
      }
      tempElementValue = tempElementValue
        ?.toString()
        .replaceAll(`{${variable.key}}`, tempVariableValue);
    });
    return tempElementValue;
  }

  public getQuestVariables(
    questVariables: QuestVariable[],
    fieldKey: string,
    value: string
  ): QuestVariable[] {
    const tempQuestVariables = [...questVariables];
    const index = tempQuestVariables.findIndex(x => x.key === fieldKey);
    let temValue = value;
    if (handleVariables?.includes(tempQuestVariables[index]?.key)) {
      temValue = temValue?.replace(/\s+/g, '').replaceAll('@', '');
    }
    if (index === -1) {
      tempQuestVariables.push({ key: fieldKey, value: temValue });
    } else {
      tempQuestVariables[index].value = temValue;
    }
    return tempQuestVariables;
  }

  public initializeQuestTemplate(
    questTemplate: QuestTemplate[],
    quest?: Quest
  ): QuestTemplate {
    if (!quest) {
      return questTemplate[0];
    }
    return (
      questTemplate.find(x => x.template_name === quest.template) ??
      questTemplate[0]
    );
  }

  public initializeQuestVariables(
    quest: Quest,
    questTemplate: QuestTemplate,
    questNumber: number
  ): QuestVariable[] {
    if (quest && quest.quest_variables) {
      notEditableQuestVariables.forEach(func => {
        func(quest, questTemplate, questNumber);
      });
      return Object.keys(quest.quest_variables).map(key => {
        return {
          key,
          value: quest.quest_variables[key],
        } as QuestVariable;
      });
    }
    return questTemplate.template_variables
      .join(',')
      .replace('{', '')
      .replace('}', '')
      .split(',')
      .map(variable => {
        return {
          key: variable,
          value: '',
        } as QuestVariable;
      });
  }

  public determineIfInheritedField = (field: string) => {
    return Object.keys(defaultMetadataDirectedFields).includes(field);
  };

  public determineIfNotInheritedFieldFromGamePage = (field: string) => {
    return notInheritedFromGamePage.includes(field);
  };

  public initializeQuest(
    questTemplate: QuestTemplate,
    campaignId: string,
    questNumber: number,
    questCount: number,
    metadataDirectedFields: MetadataDirectedFields,
    currentQuest?: Quest,
    questFields?: QuestFieldMetadata[]
  ): Quest {
    if (currentQuest) {
      return currentQuest;
    }
    const result: Quest = {
      campaign_id: campaignId,
      quest_id: questTemplate.quest_id
        .replaceAll('{campaign_id}', campaignId)
        .replaceAll('{quest_number}', questNumber?.toString()),
      template: questTemplate.template_name,
      points: questTemplate.points,
      enabled: true,
      hidden: false,
      start_datetime: formatDate(new Date()),
      quest_type: questTemplate.quest_type,
      icon: questTemplate.icon,
      description: this._getValue(
        questTemplate.description,
        metadataDirectedFields
      ),
      title: this._getValue(questTemplate.title, metadataDirectedFields),
      extra_data: questTemplate.extra_data,
      variable_json: this._initializeVariableJson(questTemplate, questFields),
      refresh_interval: questTemplate.refresh_interval,
      accomplishments_allowed_per_window:
        questTemplate.accomplishments_allowed_per_window,
      end_datetime: '',
      orderNo: questCount + 1,
      quest_variables: this._initializeQuestVariables(
        questTemplate,
        questFields
      ),
    };
    return result;
  }
  private _initializeQuestVariables(
    questTemplate: QuestTemplate,
    questFields?: QuestFieldMetadata[]
  ) {
    if (!questFields) {
      return {};
    }
    let jsonElement = {};
    questTemplate.template_variables.forEach(variable => {
      const index = questFields.findIndex(x => x.fieldKey === variable);
      if (index !== -1) {
        jsonElement = {
          ...jsonElement,
          [variable]: questFields[index].value?.toString(),
        };
      }
    });
    return jsonElement;
  }

  private _initializeVariableJson(
    questTemplate: QuestTemplate,
    questFields?: QuestFieldMetadata[]
  ) {
    if (!questTemplate.variable_json) {
      return {};
    }
    if (!questFields) {
      return questTemplate.variable_json;
    }
    let jsonElement = {};

    Object.keys(questTemplate.variable_json).forEach(key => {
      const index = questFields.findIndex(x => x.fieldKey === key);
      if (index !== -1) {
        jsonElement = {
          ...jsonElement,
          [key]: questFields[index].value?.toString(),
        };
      }
    });
    return jsonElement;
  }

  private _getValue(
    value: string,
    metadataDirectedFields: MetadataDirectedFields
  ) {
    let result = value;
    Object.entries(metadataDirectedFields).forEach(([key, val]) => {
      result = result?.toString().replaceAll(`{${key}}`, val);
    });
    return result;
  }
}

export const questDataService = new QuestDataService();
