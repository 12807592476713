import { Button, Flex, Modal, TextInput } from '@mantine/core';
import { isEmail, useField } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent } from 'react';
import { CollaboratorInvite } from '../../../data/collaborator-invite';

export interface NewCollaboratorInviteFormProps {
  savingNewCollaboratorInvite: boolean;
  onSaveNewCollaboratorInvite?: (invite: CollaboratorInvite) => void;
}

const NewCollaboratorInviteForm: FunctionComponent<
  NewCollaboratorInviteFormProps
> = props => {
  const [opened, { open, close }] = useDisclosure(false);
  const emailField = useField({
    initialValue: '',
    validateOnChange: true,
    validate: isEmail('Invalid email'),
  });

  const handleSave = () => {
    props.onSaveNewCollaboratorInvite?.({
      email: emailField.getValue().toLowerCase(),
      status: 'pending',
    });
    emailField.reset();
    close();
  };
  return (
    <Flex>
      <Modal
        zIndex={600}
        opened={opened}
        onClose={close}
        title="New collaborator invite"
        centered
        overlayProps={{
          backgroundOpacity: 0.2,
          blur: 0,
        }}
      >
        <Flex gap={'1rem'} direction={'column'} w={'100%'}>
          <Flex gap={'1rem'} w={'100%'}>
            <TextInput
              label="Email"
              w={'100%'}
              {...emailField.getInputProps()}
            />
          </Flex>
          <Flex justify="end" gap={'1rem'}>
            <Button
              w={'30%'}
              variant="outline"
              onClick={() => {
                emailField.reset();
                close();
              }}
              disabled={props.savingNewCollaboratorInvite}
            >
              Cancel
            </Button>
            <Button
              w={'30%'}
              disabled={!emailField.getValue() || !!emailField.error}
              loading={props.savingNewCollaboratorInvite}
              onClick={() => {
                handleSave();
              }}
            >
              Send
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Button onClick={open}>New Invite</Button>
    </Flex>
  );
};

export default NewCollaboratorInviteForm;
