import {
  Button,
  createTheme,
  Divider,
  Flex,
  MantineProvider,
  MultiSelect,
  ScrollArea,
  Switch,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { Campaign, getErrors } from '../../../data/campaign';
import { CampaignAssetModification } from '../../../data/campaign-assets';
import { gameModesData } from '../../../data/game-mode';
import { genresData } from '../../../data/genre';
import { getSkeletons } from '../../../helper/skeletons';
import { CampaignCommonAssetsForm } from '../CampaignCommonAssetsForm/CampaignCommonAssetsForm';
import { CampaignScreenshotsForm } from '../CampaignScreenshotsForm/CampaignScreenshotsForm';
import SocialElement from './components/SocialElement';

interface ModificationData {
  defaultValue: string;
  fieldName:
    | keyof Campaign
    | keyof Campaign['extra_data']
    | keyof Campaign['extra_data']['social'];
  updater: (
    fieldName:
      | keyof Campaign
      | keyof Campaign['extra_data']
      | keyof Campaign['extra_data']['social'],
    newValue: string
  ) => void;
  isTextArea?: boolean;
  notRequired?: boolean;
  maxLength?: number;
  label?: string;
  description?: string;
}

export interface VerticalCampaignProps {
  onCampaignPublished?: (hidden: boolean) => void;
  isCampaignPublished?: boolean;
  campaign?: Campaign;
  isLoading: boolean;
  savingNewCampaign: boolean;
  savingEditedCampaign: boolean;
  removingCampaign: boolean;
  onEditCampaignSave: (
    campaign: Campaign,
    newAssets: CampaignAssetModification
  ) => void;
}

export const VerticalCampaign: FunctionComponent<
  VerticalCampaignProps
> = props => {
  const { campaign } = props;
  console.log('campaign', campaign);
  const [canPublish, setCanPublish] = useState<boolean>(true);
  const [elementsInError, setElementInError] = useState<string[]>([]);
  const [isEditInProcess, setIsEditInProcess] = useState<boolean>(false);
  const [inEditionCampaign, setInEditionCampaign] = useState<
    Campaign | undefined
  >(undefined);
  const [commonAssets, setCommonAssets] = useState<CampaignAssetModification>(
    {}
  );
  const [screenshotsAssets, setScreenshotsAssets] =
    useState<CampaignAssetModification>({});
  const [metadataField, setMetadataField] = useState<keyof Campaign>();
  const [assetsField, setAssetsField] =
    useState<keyof Campaign['extra_data']>();
  const [socialField, setSocialField] =
    useState<keyof Campaign['extra_data']['social']>();

  useEffect(() => {
    if (elementsInError.length > 0) {
      setCanPublish(false);
      return;
    }
  }, [elementsInError]);

  useEffect(() => {
    if (!campaign) {
      return;
    }
    const errors = getErrors(campaign);
    if (errors.length === 0) {
      setCanPublish(true);
    }
    setElementInError(errors);
    setInEditionCampaign(campaign);
  }, [campaign]);

  useEffect(() => {
    if (!inEditionCampaign || !campaign) {
      setIsEditInProcess(false);
      return;
    }
    if (
      metadataField &&
      inEditionCampaign[metadataField] !== campaign[metadataField]
    ) {
      setIsEditInProcess(true);
      return;
    }
    if (
      assetsField &&
      inEditionCampaign.extra_data[assetsField] !==
        campaign.extra_data[assetsField]
    ) {
      setIsEditInProcess(true);
      return;
    }
    if (
      socialField &&
      inEditionCampaign.extra_data.social[socialField] !==
        campaign.extra_data.social[socialField]
    ) {
      setIsEditInProcess(true);
      return;
    }
    if (
      screenshotsAssets.screenshots ||
      commonAssets.icon ||
      commonAssets.background
    ) {
      setIsEditInProcess(true);
      return;
    }
    setIsEditInProcess(false);
  }, [
    metadataField,
    assetsField,
    inEditionCampaign,
    campaign,
    screenshotsAssets,
    commonAssets,
  ]);

  if (props.isLoading || props.savingEditedCampaign) {
    return (
      <Flex
        className="VerticalCampaignWrapper"
        w={'100%'}
        gap={'lg'}
        p={'lg'}
        align={'center'}
        justify={'center'}
        direction={'column'}
      >
        {getSkeletons(10)}
      </Flex>
    );
  }

  if (!campaign) {
    return <></>;
  }

  const handleOnSaveEdited = () => {
    if (!inEditionCampaign) {
      return;
    }
    const newAssets: CampaignAssetModification = {
      ...commonAssets,
      screenshots: screenshotsAssets.screenshots,
    };
    props.onEditCampaignSave?.(inEditionCampaign, newAssets);
    setInEditionCampaign(undefined);
    setIsEditInProcess(false);
    setCanPublish(true);
  };

  const updateCampaignField = (field: keyof Campaign, value: any) => {
    if (value === '') {
      setElementInError([...elementsInError, field]);
      return;
    }
    setElementInError(elementsInError.filter(e => e !== field));
    setMetadataField(field);
    setInEditionCampaign(prevCampaign =>
      prevCampaign ? { ...prevCampaign, [field]: value } : undefined
    );
  };

  const updateExtraDataField = (
    field: keyof Campaign['extra_data'],
    value: any
  ) => {
    if (value === '') {
      setElementInError([...elementsInError, field]);
      return;
    }
    setElementInError(elementsInError.filter(e => e !== field));
    setAssetsField(field);
    setInEditionCampaign(prevCampaign =>
      prevCampaign
        ? {
            ...prevCampaign,
            extra_data: {
              ...prevCampaign.extra_data,
              [field]: value,
            },
          }
        : undefined
    );
  };

  const updateSocialField = (
    field: keyof Campaign['extra_data']['social'],
    value: any,
    isRequired?: boolean
  ) => {
    if (value === '' && isRequired) {
      setElementInError([...elementsInError, field]);
      return;
    }
    setElementInError(elementsInError.filter(e => e !== field));
    setSocialField(field);
    setInEditionCampaign(prevCampaign =>
      prevCampaign
        ? {
            ...prevCampaign,
            extra_data: {
              ...prevCampaign.extra_data,
              social: {
                ...prevCampaign.extra_data.social,
                [field]: value,
              },
            },
          }
        : undefined
    );
  };

  const getError = (
    field:
      | keyof Campaign
      | keyof Campaign['extra_data']
      | keyof Campaign['extra_data']['social']
  ) => {
    if (elementsInError.includes(field)) {
      return `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
    }
    return undefined;
  };

  const getInputField = (modificationData: ModificationData) => {
    const tempFileName = modificationData.fieldName.replaceAll('_', ' ');
    const maxLength = modificationData.maxLength;
    const description = modificationData.description ?? undefined;
    const label =
      modificationData.label ??
      tempFileName.charAt(0).toUpperCase() + tempFileName.slice(1);

    if (modificationData.isTextArea) {
      return (
        <Flex
          direction={'column'}
          align={'flex-end'}
          w={'100%'}
          gap={'0.15rem'}
        >
          <Textarea
            label={label}
            description={description}
            defaultValue={modificationData.defaultValue}
            resize="vertical"
            w={'100%'}
            rows={5}
            maxLength={maxLength}
            onChange={e =>
              modificationData.updater(
                modificationData.fieldName,
                e.target.value
              )
            }
            required={!modificationData.notRequired}
            error={
              !modificationData.notRequired
                ? getError(modificationData.fieldName)
                : ''
            }
          />
          {maxLength && (
            <Text size="xs" c={'#868e96'}>
              Limited to {maxLength} characters
            </Text>
          )}
        </Flex>
      );
    }
    return (
      <TextInput
        error={
          !modificationData.notRequired
            ? getError(modificationData.fieldName)
            : ''
        }
        required={!modificationData.notRequired}
        label={label}
        description={description}
        defaultValue={modificationData.defaultValue}
        w={'100%'}
        onChange={e =>
          modificationData.updater(modificationData.fieldName, e.target.value)
        }
      />
    );
  };

  const theme = createTheme({
    cursorType: 'pointer',
  });

  const getSeparateElements = (elements: string | undefined) => {
    if (!elements || elements.length === 0) {
      return [];
    }
    return elements.replaceAll(', ', ',').split(',') ?? [];
  };

  if (!campaign) {
    return (
      <Flex direction={'column'} w={'100%'}>
        <Text>aaaa</Text>
      </Flex>
    );
  }

  return (
    <Flex direction={'column'} w={'100%'}>
      <Flex
        direction={'column'}
        pos={'sticky'}
        top={0}
        style={{ zIndex: 200 }}
        bg={'#f9fafb'}
      >
        <Flex align={'center'} justify={'flex-end'} gap={'md'} p={'xs'}>
          <MantineProvider theme={theme}>
            <Switch
              disabled={
                !props.isCampaignPublished &&
                (elementsInError.length > 0 || !canPublish)
              }
              checked={props.isCampaignPublished}
              label="Published"
              labelPosition="left"
              onChange={e => {
                props.onCampaignPublished?.(e.target.checked);
              }}
            />
          </MantineProvider>
          <Button
            disabled={!isEditInProcess}
            loading={props.savingEditedCampaign}
            onClick={handleOnSaveEdited}
          >
            Save
          </Button>
        </Flex>
        <Divider />
      </Flex>
      <ScrollArea h="100%" className="VerticalCampaignWrapper">
        <Flex
          className="VerticalCampaignWrapper"
          w={'100%'}
          align={'center'}
          p={'lg'}
          gap={'lg'}
          direction={'column'}
        >
          <Title order={1}>{campaign.title}</Title>
          <Flex align={'flex-start'} gap={'lg'} direction={'column'} w={'60%'}>
            {getInputField({
              defaultValue: campaign?.title,
              fieldName: 'title',
              label: 'Game Name',
              description: 'Please include the full name of your game',
              updater: (field, newValue) =>
                updateCampaignField(field as keyof Campaign, newValue),
            })}
            {getInputField({
              defaultValue: campaign?.subtitle,
              fieldName: 'subtitle',
              label: 'Loyalty program description',
              isTextArea: true,
              maxLength: 500,
              updater: (field, newValue) =>
                updateCampaignField(field as keyof Campaign, newValue),
            })}
            <CampaignCommonAssetsForm
              campaign={campaign}
              onAssetsChange={setCommonAssets}
            />
            {getInputField({
              defaultValue: campaign?.description,
              fieldName: 'description',
              isTextArea: true,
              label: 'Game Description',
              maxLength: 1000,
              updater: (field, newValue) =>
                updateCampaignField(field as keyof Campaign, newValue),
            })}
            <MultiSelect
              label="Genre"
              searchable
              data={genresData}
              defaultValue={getSeparateElements(campaign?.genre) ?? []}
              w={'100%'}
              onChange={genres =>
                updateCampaignField('genre', genres.join(', '))
              }
              required
              error={getError('genre')}
            />
            {getInputField({
              defaultValue: campaign?.extra_data?.website,
              fieldName: 'website',
              updater: (field, newValue) =>
                updateExtraDataField(
                  field as keyof Campaign['extra_data'],
                  newValue
                ),
            })}
            {getInputField({
              defaultValue: campaign?.extra_data?.platform,
              fieldName: 'platform',
              updater: (field, newValue) =>
                updateExtraDataField(
                  field as keyof Campaign['extra_data'],
                  newValue
                ),
            })}
            {getInputField({
              defaultValue: campaign?.extra_data?.developer,
              fieldName: 'developer',
              updater: (field, newValue) =>
                updateExtraDataField(
                  field as keyof Campaign['extra_data'],
                  newValue
                ),
            })}
            <MultiSelect
              label="Game Mode"
              defaultValue={
                getSeparateElements(campaign?.extra_data?.game_mode) ?? []
              }
              data={gameModesData}
              w={'100%'}
              onChange={modes =>
                updateExtraDataField('game_mode', modes.join(', '))
              }
              required
              error={getError('game_mode')}
            />
            {getInputField({
              defaultValue: campaign?.extra_data?.release_date,
              fieldName: 'release_date',
              updater: (field, newValue) =>
                updateExtraDataField(
                  field as keyof Campaign['extra_data'],
                  newValue
                ),
            })}
            {getInputField({
              defaultValue: campaign?.extra_data?.partner_name,
              fieldName: 'partner_name',
              updater: (field, newValue) =>
                updateExtraDataField(
                  field as keyof Campaign['extra_data'],
                  newValue
                ),
            })}
            {getInputField({
              defaultValue: campaign?.extra_data?.play_now,
              fieldName: 'play_now',
              updater: (field, newValue) =>
                updateExtraDataField(
                  field as keyof Campaign['extra_data'],
                  newValue
                ),
            })}
            <SocialElement
              defaultValue={campaign?.extra_data?.social.discord}
              fieldName={'discord'}
              onUpdate={(fieldName, newValue) =>
                updateSocialField(fieldName, newValue)
              }
            />
            <SocialElement
              defaultValue={campaign?.extra_data?.social.twitter}
              fieldName={'twitter'}
              onUpdate={(fieldName, newValue) =>
                updateSocialField(fieldName, newValue)
              }
            />
            <SocialElement
              defaultValue={campaign?.extra_data?.social.youtube}
              fieldName={'youtube'}
              onUpdate={(fieldName, newValue) =>
                updateSocialField(fieldName, newValue)
              }
            />
            <SocialElement
              defaultValue={campaign?.extra_data?.social.telegram}
              fieldName={'telegram'}
              onUpdate={(fieldName, newValue) =>
                updateSocialField(fieldName, newValue)
              }
            />
            <SocialElement
              defaultValue={campaign?.extra_data?.social.medium}
              fieldName={'medium'}
              onUpdate={(fieldName, newValue) =>
                updateSocialField(fieldName, newValue)
              }
            />
            <SocialElement
              defaultValue={campaign?.extra_data?.social.opensea}
              fieldName={'opensea'}
              onUpdate={(fieldName, newValue) =>
                updateSocialField(fieldName, newValue)
              }
            />
            <CampaignScreenshotsForm
              campaign={campaign}
              onSaved={setScreenshotsAssets}
            />
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};
