import { configuration } from '../../configuration';
import { CampaignAccountDetails } from '../../data/campaign-account';
import { Quest, QuestFetchResponse, SortStructure } from '../../data/quest';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

export const handleVariablesMap = ['twitter_handle', 'youtube_handle'];

class QuestService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'quest');
  }
  public async getQuestsByCampaignId(
    campaignId: string
  ): Promise<QuestFetchResponse> {
    return this.apiClient.get<QuestFetchResponse>(this.url(campaignId));
  }

  public async saveQuestOrder(sortStructure: SortStructure) {
    await this.apiClient.put(this.url('sorting'), sortStructure);
  }

  public async addQuest(quest: Quest) {
    await this.apiClient.post(this.url(), quest);
  }

  public async editQuest(quest: Quest): Promise<any> {
    await this.apiClient.put(this.url(), quest);
  }

  public async getCampaignAccountsDetails(
    campaignId: string
  ): Promise<CampaignAccountDetails> {
    return this.apiClient.get(this.url(`campaign-accounts/${campaignId}`));
  }

  public async updateCampaignAccounts(
    campaignId: string,
    selectedAccounts: string[]
  ) {
    await this.apiClient.put(this.url(`campaign-accounts/${campaignId}`), {
      selectedAccounts,
    });
  }
}

export const questService = new QuestService(
  new BearerTokenApiClient(configuration.backendApi.url)
);
