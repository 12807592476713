import { Tabs } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';

export interface MetricTabsProps {
  activeTab: string | null | undefined;
  onTabSelect?: (title: string | null) => void;
  options: MetricTabInfo[];
}
export interface MetricTabInfo {
  value: string;
  label: string;
  icon: ReactNode;
  element: ReactNode;
}

const MetricTabs: FunctionComponent<MetricTabsProps> = props => {
  return (
    <Tabs
      className="MetricTabs"
      w={'100%'}
      variant="outline"
      value={props.activeTab}
      onChange={e => {
        props.onTabSelect?.(e);
      }}
    >
      <Tabs.List>
        {props.options.map((option, index) => {
          return (
            <Tabs.Tab
              className="TabSection"
              value={option.value}
              key={index}
              leftSection={option.icon}
            >
              {option.label}
            </Tabs.Tab>
          );
        })}
      </Tabs.List>
      {props.options.map((option, index) => {
        return (
          <Tabs.Panel className="PanelSection" value={option.value} key={index}>
            {option.element}
          </Tabs.Panel>
        );
      })}
    </Tabs>
  );
};

export default MetricTabs;
