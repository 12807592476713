import {
  ActionIcon,
  Anchor,
  Blockquote,
  Button,
  Flex,
  Modal,
  PasswordInput,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { IoCopy, IoRefresh } from 'react-icons/io5';
import { ApiKeyGenerator } from '../../data/api-key-generator';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useNotification } from '../../hooks/use-notification';
import { apiKeysGeneratorService } from '../../services/api-client/api-keys-generator-service';
import { localStorageService } from '../../services/local-storage-service';
import { MessageStatus } from '../../services/notification-service';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const selectedCampaign = localStorageService.getSelectedCampaign();
  const selectedOrganization = localStorageService.getSelectedOrganization();
  const notification = useNotification();
  const [isRegenerateOpen, { open: openRegenerate, close: closeRegenerate }] =
    useDisclosure(false);
  const [apiKeyTrigger, setApiKeyTrigger] = useState(false);
  const [apiKeyGenerating, setApiKeyGenerating] = useState(false);

  const [apiKey, isApiKeyLoading] = useDataFetcher({
    serviceCall() {
      if (!selectedOrganization) {
        throw new Error('Invalid campaign');
      }
      return apiKeysGeneratorService.getKey(selectedOrganization.gameName);
    },
    dependencies: [apiKeyTrigger],
    onError(error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error fetching API key: ${error.message}`
      );
    },
  });

  const handleRegenerate = async () => {
    if (!selectedCampaign || !selectedOrganization) {
      return;
    }

    try {
      setApiKeyGenerating(true);
      const constantPortion = `${selectedOrganization?.partnerSlug}:${selectedOrganization.gameName}:1`;
      const apiKeyGenerator: ApiKeyGenerator = {
        gameTitle: selectedCampaign.title,
        apiKey: `${constantPortion}:${crypto.randomUUID().slice(0, 13).replaceAll('-', '')}`,
      };

      await apiKeysGeneratorService.createKey(
        selectedOrganization.gameName,
        apiKeyGenerator
      );
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error creating API key: ${error.message}`
      );
    } finally {
      setApiKeyGenerating(false);
      setApiKeyTrigger(!apiKeyTrigger);
      closeRegenerate();
      notification.notify(
        MessageStatus.Success,
        'API key',
        'New API key generated!'
      );
    }
  };
  const handleCopy = () => {
    if (!apiKey) {
      return;
    }

    navigator.clipboard.writeText(apiKey);
    notification.notify(
      MessageStatus.Success,
      'Copy',
      'Text copied to clipboard!'
    );

    return;
  };

  return (
    <Flex
      w={'100%'}
      p={'1rem'}
      direction={'column'}
      align={'center'}
      gap={'3rem'}
    >
      <Title>API Key</Title>
      <Flex
        w={'100%'}
        p={'1rem'}
        direction={'column'}
        align={'center'}
        gap={'1rem'}
      >
        <Flex align={'center'} gap={'0.5rem'} w={'60%'}>
          <PasswordInput w={'100%'} value={apiKey} contentEditable={false} />
          <Flex gap={'0.25rem'}>
            <ActionIcon
              size={'lg'}
              loading={isApiKeyLoading || apiKeyGenerating}
              onClick={() => {
                !apiKey ? handleRegenerate() : openRegenerate();
              }}
            >
              <IoRefresh size={'1.4rem'} />
            </ActionIcon>
            <ActionIcon size={'lg'} disabled={!apiKey} onClick={handleCopy}>
              <IoCopy size={'1.4rem'} />
            </ActionIcon>
          </Flex>
        </Flex>

        <Modal
          zIndex={600}
          opened={isRegenerateOpen}
          onClose={closeRegenerate}
          title="Confirm Regenerate"
        >
          <Flex direction={'column'} gap={'lg'}>
            <Text>
              Are you sure you want to <strong>regenerate</strong> this API key?
            </Text>
            <Blockquote color="yellow" p={'xs'}>
              This will
              <strong> delete</strong> the current API key.
            </Blockquote>
            <Flex gap={'0.5rem'} justify={'flex-end'}>
              <Button variant="outline" onClick={closeRegenerate}>
                Cancel
              </Button>
              <Button
                onClick={handleRegenerate}
                loading={isApiKeyLoading || apiKeyGenerating}
              >
                Regenerate
              </Button>
            </Flex>
          </Flex>
        </Modal>
        <Flex gap={'0.1rem'} justify={'center'}>
          <Text>
            Check our api documentations{' '}
            <Anchor href="https://docs.forge.gg/api-reference/introduction#api-key">
              here!
            </Anchor>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const ApiPage = withAuthProtection(Page);
