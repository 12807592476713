import { DateValue } from '@mantine/dates';

export const formatDate = (inputDate: DateValue) => {
  if (!inputDate) {
    return '';
  }

  const utcDate = getUTCDate(
    new Date(
      inputDate.getFullYear(),
      inputDate.getMonth(),
      inputDate.getDate() + 1
    )
  );
  const formattedDate = utcDate?.toISOString();

  return formattedDate;
};

export const getUTCDate = (input: Date | string) => {
  const date = new Date(input);

  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();

  return new Date(Date.UTC(year, month, day, 0, 0, 0));
};
