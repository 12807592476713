import { Divider, Flex, Grid, Text, Title } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { Campaign } from '../../../data/campaign';
import { CampaignAssetModification } from '../../../data/campaign-assets';
import ImageUploader from '../../Images/ImageUploader/ImageUploader';

export interface CampaignScreenshotsFormProps {
  campaign: Campaign;
  onSaved?: (elementsToSave: CampaignAssetModification) => void;
}

export const CampaignScreenshotsForm: FunctionComponent<
  CampaignScreenshotsFormProps
> = props => {
  const [campaignAssets, setCampaignAssets] =
    useState<CampaignAssetModification>({
      screenshots: props.campaign?.extra_data?.screenshots,
    });
  const [gridElements, setGridElements] = useState<JSX.Element[]>([]);

  useEffect(() => {
    setGridElements([...(getScreenShots() ?? []), addPlaceHolder()]);
  }, [campaignAssets]);

  const handleOnImageChange = (image: File, index: number) => {
    if (!campaignAssets?.screenshots) {
      campaignAssets.screenshots = [];
      setCampaignAssets({
        ...campaignAssets,
        screenshots: [image],
      });
      props.onSaved?.({
        ...campaignAssets,
        screenshots: [image],
      });
      return;
    }
    campaignAssets.screenshots[index] = image;
    const updatedAssets = { ...campaignAssets };
    setCampaignAssets(updatedAssets);
    props.onSaved?.(updatedAssets);
  };

  const handleScreenShotRemove = (index: number) => {
    if (!campaignAssets?.screenshots) return;
    const screenshots = [...campaignAssets.screenshots];
    screenshots[index] = 'removed';
    const updatedAssets = {
      ...campaignAssets,
      screenshots: screenshots,
    };
    setCampaignAssets(updatedAssets);
    props.onSaved?.(updatedAssets);
  };

  const getScreenShots = () => {
    if (!campaignAssets?.screenshots) {
      return;
    }
    return campaignAssets?.screenshots.map((screenshot, i) => {
      let imageValue = '';
      if (screenshot instanceof File) {
        imageValue = URL.createObjectURL(screenshot);
      } else {
        imageValue = screenshot;
      }
      if (screenshot === 'removed') {
        return <></>;
      }

      return (
        <Grid.Col span={3} key={`gird-col-${i}`}>
          <ImageUploader
            key={`screenshot-${i}`}
            allowMultipleImages={false}
            expectedDimensions="700x390"
            actualImage={imageValue}
            withRoundedBorders={true}
            onImageChange={images => {
              handleOnImageChange(images[0], i);
            }}
            withCloseButton={true}
            onRemoveButtonClicked={() => handleScreenShotRemove(i)}
          ></ImageUploader>
        </Grid.Col>
      );
    });
  };

  const handleImageAddition = (images: File[]) => {
    if (!campaignAssets?.screenshots) {
      campaignAssets.screenshots = [];
    }
    images.forEach(image => {
      campaignAssets.screenshots?.push(image);
    });
    const updatedAssets = { ...campaignAssets };
    setCampaignAssets(updatedAssets);
    props.onSaved?.(updatedAssets);
  };

  const getTitleWithDescription = (title: string, description: string) => {
    return (
      <Flex direction={'column'} gap={'0.15rem'}>
        <Title order={6}>{title}</Title>
        <Text c={'#868e96'} size="xs">
          {description}
        </Text>
      </Flex>
    );
  };

  const addPlaceHolder = () => {
    return (
      <Grid.Col span={3} key={'gird-col-placeholder'}>
        <ImageUploader
          key={'noImage-placeholder'}
          allowMultipleImages={true}
          withRoundedBorders={true}
          expectedDimensions="700x390"
          actualImage={'noImage'}
          onImageChange={images => {
            handleImageAddition(images);
          }}
        ></ImageUploader>
      </Grid.Col>
    );
  };

  return (
    <Flex p={'1rem'} direction={'column'} gap={'1rem'} w={'100%'}>
      <Divider label="Screenshots"></Divider>
      {getTitleWithDescription(
        'Screenshots',
        'Upload images of size 700 x 390'
      )}
      <Grid w={'100%'}>{gridElements}</Grid>
    </Flex>
  );
};
