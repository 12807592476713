import { Badge, Flex, Text } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';
import { RiMastercardLine, RiVisaFill } from 'react-icons/ri';
import { PaymentDetail } from './ChargeAccordionItemContent';

export interface ChargeAccordionItemProps {
  id: string;
  status: string;
  amount: string;
  currency: string;
  paymentDetail: PaymentDetail;
}

const cardsIcons = new Map<string, ReactNode>([
  ['visa', <RiVisaFill size={'1.5rem'} />],
  ['mastercard', <RiMastercardLine size={'1.5rem'} />],
]); // Add more card icons here

export const ChargeAccordionItem: FunctionComponent<
  ChargeAccordionItemProps
> = props => {
  const { id, status, amount, currency, paymentDetail } = props;
  return (
    <Flex
      direction={'row'}
      w={'100%'}
      align={'center'}
      justify={'space-between'}
    >
      <Text>{id}</Text>
      <Badge bg={status ? 'green' : 'red'}>{status}</Badge>
      <Flex gap={'xs'} align={'center'} justify={'start'}>
        {cardsIcons.get(paymentDetail.cardType)}
        <Text>...{paymentDetail.lastCard4Numbers}</Text>
      </Flex>
      <Text pr={'xl'}>{`${currency} ${amount}`}</Text>
    </Flex>
  );
};

export default ChargeAccordionItem;
