import { Tabs } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';

export interface InformationTabsProps {
  tabDefinitions: TabDefinition[];
}
export interface TabDefinition {
  title: string;
  icon: ReactNode;
  content: ReactNode;
}

const InformationTabs: FunctionComponent<InformationTabsProps> = props => {
  const getTabsTitles = () => {
    return props.tabDefinitions?.map((tab, index) => {
      return (
        <Tabs.Tab
          key={index}
          value={tab.title.toLowerCase()}
          leftSection={tab.icon}
        >
          {tab.title}
        </Tabs.Tab>
      );
    });
  };
  const getTabPanels = () => {
    return props.tabDefinitions?.map((tab, index) => {
      return (
        <Tabs.Panel key={index} value={`${tab.title.toLowerCase()}`}>
          {tab.content}
        </Tabs.Panel>
      );
    });
  };
  return (
    <Tabs
      color="indigo"
      variant="outline"
      radius="md"
      defaultValue={props.tabDefinitions[0].title.toLowerCase()}
    >
      <Tabs.List>{getTabsTitles()}</Tabs.List>
      {getTabPanels()}
    </Tabs>
  );
};

export default InformationTabs;
