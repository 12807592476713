import {
  ActionIcon,
  Button,
  Checkbox,
  Flex,
  Text,
  Tooltip,
} from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { GrAdd } from 'react-icons/gr';
import { CampaignOption } from '../../data/campaign-option';

const maxElementsValue = 2;

export interface CampaignSelectorProps {
  campaignOptions?: CampaignOption[];
  selectedCampaignId?: string;
  onCampaignSelection?: (campaignOption: CampaignOption) => void;
  onAddCampaign?: () => void;
  loadingCampaignsOptions?: boolean;
  addingCampaign?: boolean;
  cloneOption: boolean;
  onCloneOptionChange: (checked: boolean) => void;
}

const CampaignSelector: FunctionComponent<CampaignSelectorProps> = props => {
  const {
    campaignOptions,
    onCampaignSelection,
    selectedCampaignId,
    loadingCampaignsOptions,
    addingCampaign,
    onAddCampaign,
    cloneOption,
    onCloneOptionChange,
  } = props;
  const [isCloneOptionActivated, setIsCloneOptionActivated] =
    useState<boolean>(cloneOption);
  const [selectedCampaign, setSelectedCampaign] =
    useState<CampaignOption | null>(null);

  useEffect(() => {
    if (!campaignOptions) {
      setIsCloneOptionActivated(false);
      return;
    }
    const selectedCampaignOption =
      campaignOptions.find(
        campaignOption =>
          campaignOption.draft_campaign_id === selectedCampaignId
      ) ?? campaignOptions[0];
    setSelectedCampaign(selectedCampaignOption);
    setIsCloneOptionActivated(cloneOption);
    onCampaignSelection?.(selectedCampaignOption);
  }, [selectedCampaignId, campaignOptions]);

  if (!campaignOptions) {
    return <></>;
  }

  const getColor = (campaignOption: CampaignOption) => {
    return selectedCampaign?.draft_campaign_id ===
      campaignOption.draft_campaign_id
      ? '#ffffff'
      : '';
  };

  const handleCampaignSelection = (campaignOption: CampaignOption) => () => {
    setSelectedCampaign(campaignOption);
    onCampaignSelection?.(campaignOption);
  };
  const getCampaignElements = () => {
    if (loadingCampaignsOptions) {
      return (
        <Flex gap={'1rem'} w={'100%'} align={'center'}>
          <Text>Loading...</Text>
        </Flex>
      );
    }
    return (
      <Flex gap={'0.3rem'}>
        {campaignOptions.map((campaignOption, index) => {
          return (
            <Tooltip
              openDelay={500}
              key={`selector-tool-tip-${index}`}
              label={campaignOption.title}
            >
              <Flex
                miw={'5rem'}
                key={`selector-container-${index}`}
                gap={'0.3rem'}
                direction={'column'}
                bd={'1px solid #1c7ed6'}
                bg={
                  selectedCampaign?.draft_campaign_id ===
                  campaignOption.draft_campaign_id
                    ? '#1c7ed6'
                    : ''
                }
                p={'0.5rem'}
                style={{ cursor: 'pointer', borderRadius: '0.5rem' }}
                onClick={handleCampaignSelection(campaignOption)}
              >
                <Text
                  key={`selector-name-${index}`}
                  c={getColor(campaignOption)}
                  size={'1rem'}
                >
                  {campaignOption.title.length >= 15
                    ? `${campaignOption.title.substring(0, 15)} ...`
                    : campaignOption.title}
                </Text>
                <Text
                  key={`selector-is-published-tip-${index}`}
                  c={getColor(campaignOption)}
                  size={'0.7rem'}
                >
                  {campaignOption.published ? 'published' : 'draft'}
                </Text>
              </Flex>
            </Tooltip>
          );
        })}
      </Flex>
    );
  };

  const isAddingNewDraftDisabled = () => {
    if (campaignOptions.length >= maxElementsValue) {
      return true;
    }
    if (loadingCampaignsOptions) {
      return true;
    }
    if (campaignOptions.length === 1) {
      if (!campaignOptions[0].published) {
        return true;
      }
    }
    return false;
  };

  const getAddButton = () => {
    if (loadingCampaignsOptions) {
      return;
    }
    if (!campaignOptions || campaignOptions.length === 0) {
      return (
        <Button loading={addingCampaign} onClick={() => onAddCampaign?.()}>
          Add new draft campaign
        </Button>
      );
    }
    const addCampaignButtonToolTip = () => {
      if (campaignOptions.length === 1) {
        if (!campaignOptions[0].published) {
          return 'You can only have one draft campaign at a time. Publish the current draft campaign to add a new one.';
        }
      }
      if (campaignOptions.length >= maxElementsValue) {
        return 'You can only have two campaigns at a time. One draft and one published. Publish the current draft campaign to add a new one.';
      }
      return 'Add new draft campaign';
    };
    return (
      <Tooltip
        maw={'20rem'}
        multiline
        label={addCampaignButtonToolTip()}
        openDelay={500}
      >
        <ActionIcon
          onClick={() => onAddCampaign?.()}
          disabled={isAddingNewDraftDisabled()}
          loading={addingCampaign}
        >
          <GrAdd />
        </ActionIcon>
      </Tooltip>
    );
  };
  const getCloneCheckBox = () => {
    if (!campaignOptions || campaignOptions.length === 0) {
      return;
    }
    return (
      <Tooltip
        openDelay={500}
        maw={'15rem'}
        label="Activating this option you will generate a new copy of the active campaign"
        multiline
      >
        <Flex gap={'0.3rem'}>
          <Checkbox
            disabled={isAddingNewDraftDisabled()}
            label="Clone"
            labelPosition="right"
            defaultChecked={isCloneOptionActivated}
            onChange={e => onCloneOptionChange(e.currentTarget.checked)}
          />
        </Flex>
      </Tooltip>
    );
  };
  return (
    <Flex gap={'1rem'} w={'100%'} align={'center'}>
      {getCampaignElements()}
      {getAddButton()}
      {getCloneCheckBox()}
    </Flex>
  );
};

export default CampaignSelector;
