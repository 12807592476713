import {
  Center,
  Divider,
  Flex,
  FlexProps,
  Image,
  MantineStyleProp,
  Text,
} from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { LocalImages } from '../../assets/images';
import { NavbarLink, NavLink } from './components/NavbarLink/NavbarLink';

import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlags } from '../../data/feature-flags';
import './SideBar.scss';

export interface SideBarProps {
  membership: string;
  links: NavLink[];
  defaultItemSlug?: string;
  selectedStyles?: MantineStyleProp;
  expanded?: boolean;
  onItemSelected?: (slug: string) => void;
  onMenuClick?: () => void;
  onTierClick?: () => void;
  style?: FlexProps;
}

export const SideBar: FunctionComponent<SideBarProps> = props => {
  const showBillingPlan = useFeatureFlagEnabled(FeatureFlags.ShowBillingPlan);
  const { onTierClick, membership } = props;

  const [activeLink, setActiveLink] = useState<string>(
    props.defaultItemSlug ?? ''
  );

  useEffect(() => {
    setActiveLink(props.defaultItemSlug ?? '');
  }, [props.defaultItemSlug]);

  const handleClick = (slug: string) => {
    setActiveLink(slug);
    props.onItemSelected?.(slug);
  };

  const getTier = () => {
    switch (membership) {
      case 'free':
        return 'Free tier';
      case 'pro':
        return 'Professional';
      case 'enterprise':
        return 'Enterprise';
      default:
        return 'Free';
    }
  };

  function renderBillingPlan() {
    return (
      <>
        <Divider></Divider>
        <Center>
          <Text
            size="md"
            mb={'lg'}
            style={{
              color: `${membership === 'free' ? 'blue' : ''}`,
              cursor: `${membership === 'free' ? 'pointer' : ''}`,
            }}
            onClick={onTierClick}
          >
            {getTier()}
          </Text>
        </Center>
      </>
    );
  }

  return (
    <Flex
      classNames={{ root: 'SideBar' }}
      direction={'column'}
      w={'100%'}
      h={'100%'}
      justify={'space-between'}
      color={'#fff'}
      fz={'1.5rem'}
      gap={'lg'}
      pt={'sm'}
      {...props.style}
    >
      <Flex
        direction={'column'}
        w={'100%'}
        color={'#fff'}
        fz={'1.5rem'}
        gap={'0.3rem'}
        pt={'sm'}
        {...props.style}
      >
        <Flex w={'100%'} justify={'center'} gap={'0.5rem'}>
          <Image
            mah={props.expanded ? '2rem' : '1rem'}
            src={
              props.expanded
                ? LocalImages.horizontalLogo
                : LocalImages.smallLogo
            }
          />
        </Flex>
        {props.links?.map(link => {
          if (link.position !== 'bottom') {
            return (
              <NavbarLink
                link={link}
                key={link.slug}
                disabled={link.disable}
                active={link.slug === activeLink}
                expanded={props.expanded}
                onClick={() => handleClick(link.slug)}
              />
            );
          }
        })}
      </Flex>
      <Flex
        direction={'column'}
        align={'normal'}
        justify={'center'}
        gap={'0.3rem'}
      >
        <Divider></Divider>
        {props.links?.map(link => {
          if (link.position === 'bottom') {
            return (
              <NavbarLink
                link={link}
                key={link.slug}
                disabled={link.disable}
                active={link.slug === activeLink}
                expanded={props.expanded}
                onClick={() => handleClick(link.slug)}
              />
            );
          }
        })}
        {showBillingPlan && renderBillingPlan()}
      </Flex>
    </Flex>
  );
};
