import { configuration } from '../../configuration';
import { ApiKeyGenerator } from '../../data/api-key-generator';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class ApiKeysGeneratorService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'api-key');
  }

  public async getKey(gameName: string): Promise<string> {
    return await this.apiClient.get<string>(this.url(`${gameName}`));
  }

  public async createKey(
    gameName: string,
    apiKeyGenerator: ApiKeyGenerator
  ): Promise<void> {
    await this.apiClient.post<any, any>(
      this.url(`${gameName}`),
      apiKeyGenerator
    );
  }
}

export const apiKeysGeneratorService = new ApiKeysGeneratorService(
  new BearerTokenApiClient(configuration.backendApi.url)
);
