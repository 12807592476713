import { Organization } from '../data/organization';
import { organizationService } from './api-client/organization-service';
import { authentication } from './authentication';
import { localStorageService } from './local-storage-service';

class SetupService {
  public async setUpOrganizations(email: string) {
    const organizations =
      await organizationService.getOrganizationsByEmail(email);
    if (!organizations || organizations.length === 0) {
      return;
    }
    localStorageService.setOrganizations(organizations);
    const selectedOrganization = localStorageService.getSelectedOrganization();
    if (!selectedOrganization) {
      localStorageService.setSelectedOrganization(organizations[0]);
      return organizations[0];
    }
    return selectedOrganization;
  }

  public async setUpCampaigns(organization: Organization) {
    if (!organization) {
      authentication.logout('invalid_user');
      return;
    }

    localStorageService.setSelectedCampaign(
      organization.campaignDetails.filter(
        x => !x.campaignId.startsWith('DRAFT-')
      )[0]
    );
  }
}

export const setupService = new SetupService();
