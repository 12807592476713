import { configuration } from '../../configuration';
import { Quest } from '../../data/quest';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class SsQuestService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'quest');
  }

  public async validate(quest: Quest) {
    console.log('quest', quest);
    return this.apiClient.post(this.url('validate'), {
      ...quest,
      points: +quest.points,
    });
  }
}

export const ssQuestService = new SsQuestService(
  new BearerTokenApiClient(configuration.forgeApi.selfServiceUrl)
);
