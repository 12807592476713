import { Button, Flex, Image, Paper, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { LocalImages } from '../../assets/images';
import './UnauthorizePage.scss';

const page = () => {
  const navigate = useNavigate();
  return (
    <Flex
      className="unauthorize-page"
      justify={'center'}
      align={'center'}
      w={'100%'}
      h="100vh"
    >
      <Paper
        className="unauthorize-container"
        maw="30rem"
        p={'1.5rem 2.5rem'}
        shadow={'xl'}
        radius={'md'}
      >
        <Stack gap={'2rem'}>
          <Stack align="center">
            <Image
              className="LogoImage"
              maw="12rem"
              mah="5rem"
              fit="contain"
              src={LocalImages.logo}
              alt={'logo'}
            />
          </Stack>
          <Text size="md" color="#110f10" ta="center">
            {`You don't have permission to view this page. This might be because you are not logged in, 
            your session has expired, or you don't have the necessary permissions. Please log in again.`}
          </Text>
          <Button
            onClick={() => {
              navigate('/login');
            }}
          >
            Go to login
          </Button>
        </Stack>
      </Paper>
    </Flex>
  );
};

export const UnauthorizePage = page;
