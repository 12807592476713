import { Box, Button, Flex, Modal, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent, useState } from 'react';
export interface TelegramBotModalProps {
  codeFromUrl?: string | null;
  isAddingBot: boolean;
  onSave?: (confirmationCode: string) => void;
}

const TelegramBotModal: FunctionComponent<TelegramBotModalProps> = props => {
  const [opened, { open, close }] = useDisclosure(false);
  const [code, setCode] = useState<string>();
  const { isAddingBot, onSave, codeFromUrl } = props;

  const handleSend = () => {
    if (code) {
      onSave?.(code);
      close();
    }
  };

  return (
    <Box>
      <Flex w={'100%'} align={'end'} gap={'md'} justify={'flex-end'}>
        <Button onClick={open}>Register installation</Button>
      </Flex>
      <Modal
        zIndex={300}
        opened={opened || isAddingBot}
        onClose={close}
        title="Bot instance"
        centered
      >
        <Flex direction={'column'} maw={'30rem'}>
          <TextInput
            defaultValue={codeFromUrl ?? ''}
            placeholder="Enter confirmation code"
            onChange={e => setCode(e.currentTarget.value)}
          />
          <Flex gap={'0.5rem'} justify={'end'} mt={'md'}>
            <Button
              disabled={codeFromUrl !== null && isAddingBot}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              loading={isAddingBot}
              disabled={!code}
              onClick={handleSend}
              color={'blue'}
            >
              Send
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Box>
  );
};

export default TelegramBotModal;
