import {
  Button,
  Center,
  Flex,
  FlexProps,
  Loader,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FunctionComponent } from 'react';
import { SignUpCredentials } from '../../../data/sign-up-credentials';

export interface SignUpFormProps {
  isLoading?: boolean;
  failedSignUpMessage?: string;
  onSignUpClick?: (signUpCredentials: SignUpCredentials) => void;
  backToLogin?: () => void;
  style?: FlexProps;
}

export const SignUpForm: FunctionComponent<SignUpFormProps> = props => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      email: '',
      organizationName: '',
    },
    validate: {
      email: value => (emailRegex.test(value) ? null : 'Invalid email'),
      organizationName: value =>
        value ? null : 'Organization Name is required',
    },
  });

  return (
    <form onSubmit={form.onSubmit(values => props.onSignUpClick?.(values))}>
      {props.failedSignUpMessage && (
        <Text c={'red'}>{props.failedSignUpMessage}</Text>
      )}
      <Flex direction={'column'} gap={'0.75rem'}>
        <TextInput
          type="email"
          label="Email"
          disabled={props.isLoading}
          placeholder="email@organization.com"
          {...form.getInputProps('email')}
        ></TextInput>
        <TextInput
          disabled={props.isLoading}
          label="Organization Name"
          placeholder="My Organization"
          {...form.getInputProps('organizationName')}
        ></TextInput>
        {props.isLoading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <Flex gap={'0.5rem'} direction={'column'}>
            <Button
              disabled={!form.isValid()}
              variant="dark"
              className="btnSignInGoogle"
              onClick={() =>
                props.onSignUpClick?.({
                  email: form.getInputProps('email').value,
                  organizationName:
                    form.getInputProps('organizationName').value,
                })
              }
            >
              Finish Sign-up
            </Button>
            <Button
              variant="dark"
              className="btnSignInGoogle"
              onClick={() => {
                props.backToLogin?.();
              }}
            >
              Back to login
            </Button>
          </Flex>
        )}
      </Flex>
    </form>
  );
};
