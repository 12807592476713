import {
  Anchor,
  Button,
  CheckIcon,
  Flex,
  Image,
  Modal,
  Radio,
  Text,
  Title,
} from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { LocalImages } from '../../assets/images';

export enum Tiers {
  Pro = 'pro',
  Enterprise = 'enterprise',
  Free = 'free',
}

export const defaultUpgradeTiers = [
  {
    label: Tiers.Pro,
    description: 'Access more features',
    price: '$4000/mo',
  },
  {
    label: Tiers.Enterprise,
    description: 'Access even more features',
    price: 'Contact us',
  },
];

export interface TierOption {
  label: string;
  description: string;
  price: string;
}

interface UpsellDialogProps {
  opened: boolean;
  isLoading: boolean;
  onSubscribe?: (value: Tiers) => void;
  onClose?: () => void;
}

export const UpsellDialog: FunctionComponent<UpsellDialogProps> = props => {
  const [opened, setOpened] = useState<boolean>(props.opened);
  const [selectedPlan, setSelectedPlan] = useState<Tiers>(Tiers.Pro);
  const [buttonText, setButtonText] = useState<string>('Subscribe');
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading);

  useEffect(() => {
    setOpened(props.opened);
  }, [props.opened]);

  useEffect(() => {
    if (selectedPlan === Tiers.Pro) {
      setButtonText('Subscribe now');
    } else if (selectedPlan === Tiers.Enterprise) {
      setButtonText('Contact us');
    }
  }, [selectedPlan]);

  return (
    <Modal
      zIndex={600}
      opened={opened}
      onClose={() => props.onClose?.()}
      centered
    >
      <Flex direction={'column'} gap={'lg'} p={'sm'} pt={0} pb={'0'}>
        <Image w={'8rem'} src={LocalImages.logo} />
        <Flex direction={'column'} gap={'0.25rem'}>
          <Title order={3}>Upgrade your plan</Title>
          <Text c={'gray'}>
            Get access to even more customization and features.
          </Text>
        </Flex>
        <Radio.Group
          value={selectedPlan}
          onChange={e => setSelectedPlan(e as Tiers)}
          label="Pick a plan"
          labelProps={{ pb: 'sm' }}
          required
        >
          <Flex direction={'column'} gap={'xs'}>
            {defaultUpgradeTiers.map((tier, index) => {
              return (
                <Radio.Card
                  radius={'md'}
                  key={`tier-${index}`}
                  value={tier.label}
                >
                  <Flex align={'center'} justify={'space-between'} p={'sm'}>
                    <Flex direction={'column'} justify={'center'}>
                      <Flex gap={'0.5rem'} align={'baseline'}>
                        <Title order={4}>
                          {tier.label.charAt(0).toUpperCase() +
                            tier.label.slice(1)}
                        </Title>
                        <Text c={'gray'}>{tier.price}</Text>
                      </Flex>
                      <Text c={'gray'}>{tier.description}</Text>
                    </Flex>
                    <Radio.Indicator icon={CheckIcon} radius={'lg'} />
                  </Flex>
                </Radio.Card>
              );
            })}
          </Flex>
        </Radio.Group>
        <Flex gap={'md'} justify={'center'} w={'100%'}>
          <Button
            variant="outline"
            w={'100%'}
            onClick={() => props.onClose?.()}
          >
            Cancel
          </Button>
          <Button
            w={'100%'}
            disabled={!selectedPlan}
            loading={isLoading}
            color={'blue'}
            onClick={() => {
              setIsLoading(true);
              props.onSubscribe?.(selectedPlan);
            }}
          >
            {buttonText}
          </Button>
        </Flex>
        <Flex w={'100%'} justify={'center'}>
          <Anchor
            href="https://play.forge.gg/build#pricing-section"
            target="_blank"
            underline="hover"
            c={'dark'}
            size="sm"
          >
            View plan comparison
          </Anchor>
        </Flex>
      </Flex>
    </Modal>
  );
};
