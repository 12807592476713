import { Flex } from '@mantine/core';
import { useState } from 'react';
import { FaChartBar } from 'react-icons/fa6';
import { GiFinishLine } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import ActiveUsersCount from '../../components/Metrics/ActiveUsersCount/ActiveUsersCount';
import ClosureReportCard, {
  ClosureReportOptions,
} from '../../components/Metrics/ClosureMetric/ClosureMetric';
import MetricTabs from '../../components/Metrics/MetricTabs/MetricTabs';
import { MetricTableCard } from '../../components/Metrics/QuestAccomplishedByUserCard/MetricTableCard';
import { Report, ReportIdentifier } from '../../data/metrics-data';
import {
  calculateNumberOfPages,
  defaultPagesize,
} from '../../helper/reports-pagination';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useNotification } from '../../hooks/use-notification';
import { useTier } from '../../hooks/use-tier';
import { reportsService } from '../../services/api-client/metrics-service';
import { localStorageService } from '../../services/local-storage-service';
import { MessageStatus } from '../../services/notification-service';
import { withAuthProtection } from '../../services/protect-route-element';
import './ReportPage.scss';

const Page = () => {
  const navigate = useNavigate();
  const campaignId = localStorageService.getSelectedCampaign()?.campaignId;
  const [selectedTab, setSelectedTab] = useState<string | null>(
    'basic-reports'
  );
  const blockchains = [
    'avalanche',
    'base',
    'ethereum',
    'polygon',
    'ronin',
    'solana',
    'sui',
  ];
  const services = ['discord', 'riot', 'steam', 'twitter', 'xbox'];
  const [closureReportOptions, setClosureReportOptions] =
    useState<ClosureReportOptions>({
      selectedBlockchains: blockchains,
      selectedServices: services,
      pageNumber: 1,
      pageSize: defaultPagesize,
    });
  const [{ isFreeTier, component, setIsClose }] = useTier();
  const notification = useNotification();
  const [
    completedQuestsWithUserCountPageNumber,
    setCompletedQuestsWithUserCountPageNumber,
  ] = useState(1);
  const [
    usersWithCompletedQuestsCountPageNumber,
    setUsersWithCompletedQuestsCountPageNumber,
  ] = useState(1);

  const [completedQuestsWithUserCount, completedQuestsWithUserCountLoading] =
    useDataFetcher({
      serviceCall() {
        if (!campaignId) {
          throw new Error('No campaign selected');
        }
        return reportsService.getCompletedQuestsWithUserCount(
          completedQuestsWithUserCountPageNumber,
          campaignId
        );
      },
      dependencies: [completedQuestsWithUserCountPageNumber, selectedTab],
      conditionForExecution: selectedTab === 'basic-reports',
      onError(error: any) {
        notification.notify(
          MessageStatus.Error,
          'Metrics',
          'There was a problem fetching the dashboard metrics: ' +
            error?.response?.data?.message ?? 'Unknown error'
        );
      },
    });

  const [usersWithCompletedQuestsCount, usersWithCompletedQuestsCountLoading] =
    useDataFetcher({
      serviceCall() {
        if (!campaignId) {
          throw new Error('No campaign selected');
        }
        return reportsService.getUsersWithCompletedQuestsCount(
          usersWithCompletedQuestsCountPageNumber,
          campaignId
        );
      },
      dependencies: [usersWithCompletedQuestsCountPageNumber, selectedTab],
      conditionForExecution: selectedTab === 'basic-reports',
      onError(error: any) {
        notification.notify(
          MessageStatus.Error,
          'Metrics',
          'There was a problem fetching the dashboard metrics: ' +
            error?.response?.data?.message ?? 'Unknown error'
        );
      },
    });

  const [activeUsers, loadingActiveUsers] = useDataFetcher({
    serviceCall() {
      if (!campaignId) {
        throw new Error('No campaign selected');
      }
      return reportsService.getCompletedQuestUserCount(campaignId);
    },
    onError(error: any) {
      notification.notify(
        MessageStatus.Error,
        'Metrics',
        'There was a problem fetching the active users count: ' +
          error?.response?.data?.message ?? 'Unknown error'
      );
    },
  });

  const [closureMetricData, closureMetricLoading] = useDataFetcher({
    serviceCall() {
      if (!campaignId) {
        throw new Error('No campaign selected');
      }
      return reportsService.getClosureMetrics(closureReportOptions, campaignId);
    },
    dependencies: [selectedTab, closureReportOptions],
    conditionForExecution: selectedTab === 'closure',
    onError(error: any) {
      notification.notify(
        MessageStatus.Error,
        'Metrics',
        'There was a problem fetching the closure metrics: ' +
          error?.response?.data?.message ?? 'Unknown error'
      );
    },
  });

  const handleUserNameClick = (userName: string) => {
    if (isFreeTier) {
      setIsClose(false);
      return;
    }
    navigate(`/user-lookup?userName=${userName}`);
  };

  const handleReportGeneration = async (
    metricIdentifier: ReportIdentifier,
    payload?: any
  ) => {
    try {
      if (!campaignId) {
        notification.notify(
          MessageStatus.Error,
          'Reports',
          'No campaign selected'
        );
        return;
      }
      reportsService.sendReportRequest(metricIdentifier, campaignId, payload);
      notification.notify(
        MessageStatus.Success,
        'Metrics',
        'Report generation request sent. You will receive an email shortly with the exported report.'
      );
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Metrics',
        'There was a problem fetching the dashboard metrics ' +
          error?.response?.data?.message ?? 'Unknown error'
      );
    }
  };

  const getBasicReports = () => {
    return (
      <Flex
        className="TableMetricsSection"
        w={'100%'}
        direction={'row'}
        gap={'1rem'}
      >
        <MetricTableCard
          key={1}
          metricType={ReportIdentifier.CompletedQuestsWithUserCount}
          onExportData={() =>
            handleReportGeneration(
              ReportIdentifier.CompletedQuestsWithUserCount
            )
          }
          title={'Completed quests with User Count'}
          data={completedQuestsWithUserCount?.data as Report[]}
          isLoading={completedQuestsWithUserCountLoading}
          onPageChange={page => setCompletedQuestsWithUserCountPageNumber(page)}
          totalPageCount={calculateNumberOfPages(
            completedQuestsWithUserCount?.totalRows ?? 1
          )}
          activePage={completedQuestsWithUserCountPageNumber}
        />
        <MetricTableCard
          key={2}
          onUserNameClick={userName => {
            handleUserNameClick(userName);
          }}
          onExportData={() =>
            handleReportGeneration(
              ReportIdentifier.UsersWithCompletedQuestsCount
            )
          }
          metricType={ReportIdentifier.UsersWithCompletedQuestsCount}
          title={'Users with completed quests count'}
          data={usersWithCompletedQuestsCount?.data as Report[]}
          isLoading={usersWithCompletedQuestsCountLoading}
          onPageChange={page =>
            setUsersWithCompletedQuestsCountPageNumber(page)
          }
          totalPageCount={calculateNumberOfPages(
            usersWithCompletedQuestsCount?.totalRows ?? 1
          )}
          activePage={usersWithCompletedQuestsCountPageNumber}
        />
      </Flex>
    );
  };

  const getMetrics = () => {
    return (
      <MetricTabs
        activeTab={selectedTab}
        onTabSelect={e => {
          setSelectedTab(e);
        }}
        options={[
          {
            value: 'basic-reports',
            label: 'Basic Reports',
            element: getBasicReports(),
            icon: <FaChartBar />,
          },
          {
            value: 'closure',
            label: 'Campaign',
            element: (
              <ClosureReportCard
                onExportData={() =>
                  handleReportGeneration(
                    ReportIdentifier.ClosureReport,
                    closureReportOptions
                  )
                }
                blockchains={blockchains}
                services={services}
                onUserNameClick={userName => handleUserNameClick(userName)}
                isLoading={closureMetricLoading}
                options={closureReportOptions}
                onOptionsChange={setClosureReportOptions}
                report={closureMetricData}
              />
            ),
            icon: <GiFinishLine />,
          },
        ]}
      />
    );
  };

  return (
    <Flex p={'1rem'} w={'100%'} className="ReportPageWrapper">
      {component}
      <ActiveUsersCount
        isLoading={loadingActiveUsers}
        userCount={activeUsers ?? 0}
      />
      {getMetrics()}
    </Flex>
  );
};

export const ReportPage = withAuthProtection(Page);
