export enum FeatureFlags {
  ShowApiKeyPage = 'show-api-key-page',
  ShowBillingPage = 'show-billing-page',
  ShowBillingPlan = 'show-billing-plan',
  ShowDiscordPage = 'show-discord-page',
  ShowSignUpButton = 'show-sign-up-button',
  ShowKolsPage = 'show-kols-page',
  ShowTelegramBotPage = 'show-telegram-bot-page',
  ShowReportsPage = 'show-reports-page',
  ShowMultiCampaign = 'show-multi-campaign',
  SupportValidationFlow = 'support-validation-flow',
}
