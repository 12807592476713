import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Flex, Skeleton, Title } from '@mantine/core';
import _ from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { getIcon } from '../../../data/icon-registry';
import { Quest, SortableQuest, SortStructure } from '../../../data/quest';
import QuestElements from '../QuestBasicElement/QuestBasicElement';

export interface QuestsListProps {
  quests: Quest[];
  isLoading?: boolean;
  onQuestToEdit?: (quest: Quest) => void;
  onSortChange: (sortStructure: SortStructure) => void;
}

export const QuestsList: FunctionComponent<QuestsListProps> = props => {
  const getItems = (): SortableQuest[] => {
    return props.quests.map(quest => {
      return {
        id: quest.orderNo,
        quest: quest,
      } as SortableQuest;
    });
  };
  const [sortableQuest, setSortableQuest] =
    useState<SortableQuest[]>(getItems());

  useEffect(() => {
    setSortableQuest(getItems());
  }, [props.quests]);

  const getSkeletons = (amount?: number) => {
    return _.map(new Array(amount ?? 4), (_x, index) => {
      return <Skeleton key={index} height={'100px'} width={'28rem'} />;
    });
  };

  if (!props.isLoading && (!props.quests || props.quests.length === 0)) {
    return (
      <Flex w={'100%'} h={'100%'} justify={'center'} align={'center'}>
        <Title size={'md'} order={1}>
          No quests found for this Campaign
        </Title>
      </Flex>
    );
  }

  const getQuests = () => {
    if (props.isLoading) {
      return getSkeletons();
    }
    return sortableQuest.map(quest => {
      return (
        <QuestElements
          icon={getIcon(quest.quest.icon)}
          key={quest.id}
          sortableQuest={quest}
          onEditClick={quest => {
            props.onQuestToEdit?.(quest);
          }}
        />
      );
    });
  };

  const handelDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setSortableQuest(items => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        const newElements = arrayMove(items, oldIndex, newIndex);
        props.onSortChange({
          campaign_id: props.quests[0].campaign_id,
          sort_values: newElements.map((element, index) => {
            element.quest.orderNo = index + 1;
            return {
              quest_id: element.quest.quest_id,
              orderNo: index + 1,
            };
          }),
        });
        return newElements;
      });
    }
  };

  return (
    <Flex direction={'column'} h={'100%'} gap={'1.25rem'} w={'fit-content'}>
      <DndContext onDragEnd={handelDragEnd}>
        <SortableContext items={sortableQuest}>{getQuests()}</SortableContext>
      </DndContext>
    </Flex>
  );
};
