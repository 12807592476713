import { ActionIcon, Flex, Loader, Switch, TextInput } from '@mantine/core';
import { FunctionComponent } from 'react';
import { BiTrash } from 'react-icons/bi';
import { FaCopy } from 'react-icons/fa';
import { Tweet } from 'react-tweet';
import { FeaturedTweet } from '../../../../data/featured-tweet';

export interface FeaturedTweetCardProps {
  isPublishing?: boolean;
  tweet: FeaturedTweet;
  removingTweet?: boolean;
  onDeleteTweet?: (FeaturedTweet: FeaturedTweet) => void;
  onPublish: (FeaturedTweet: FeaturedTweet) => void;
  onCopy: (tweetCode: string) => void;
}

export const FeaturedTweetCard: FunctionComponent<
  FeaturedTweetCardProps
> = props => {
  return (
    <Flex
      p={'0.5rem'}
      w={'28.125rem'}
      direction={'column'}
      style={{ borderRadius: '0.5rem' }}
      bg={props.tweet.active ? '#f2f2f2' : ''}
    >
      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Flex direction={'row'} gap={'1rem'}>
          <Switch
            defaultChecked
            label={props.tweet.active ? 'Active' : 'Inactive'}
            checked={props.tweet.active}
            onChange={() => {
              props.onPublish?.(props.tweet);
            }}
          />
          {props.isPublishing && <Loader size={'1rem'} />}
        </Flex>

        {props.removingTweet ? (
          <Loader size={'sm'} />
        ) : (
          <ActionIcon variant="transparent">
            <BiTrash onClick={() => props.onDeleteTweet?.(props.tweet)} />
          </ActionIcon>
        )}
      </Flex>
      <Tweet key={props.tweet.id} id={props.tweet.id} />
      <TextInput
        readOnly
        h={'2rem'}
        value={props.tweet.embedHtml}
        rightSection={
          <FaCopy
            cursor={'pointer'}
            onClick={() => props.onCopy?.(props.tweet.embedHtml)}
          />
        }
      />
    </Flex>
  );
};
