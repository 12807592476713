import { Avatar, Flex, Group, Text } from '@mantine/core';
import { FunctionComponent } from 'react';

export interface AccordionElementProps {
  label: string;
  image?: string;
  description: string;
}

const HintAccordionElement: FunctionComponent<
  AccordionElementProps
> = props => {
  const { label, image, description } = props;
  return (
    <Group wrap="nowrap">
      {image && <Avatar src={image} radius="xl" size="lg" />}
      <Flex direction={'column'}>
        <Text>{label}</Text>
        <Text size="sm" c="dimmed" fw={400}>
          {description}
        </Text>
      </Flex>
    </Group>
  );
};

export default HintAccordionElement;
