import {
  Badge,
  Card,
  Flex,
  Image,
  Modal,
  Skeleton,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent } from 'react';
import { Account, Service, ServiceAccountLinked } from '../../data/account';
import AccountAvatar from './components/AccountAvatar';
import BasicInfo from './components/BasicInfo';
import SocialsTable from './components/SocialsTable';
import WalletsAccountTable from './components/WalletsAccountTable';

export interface AccountInfoProps {
  isLoading?: boolean;
  onRemoveAvatar?: () => void;
  accountInfoData?: Account;
}

const serviceUrlRegistry = new Map<string, string | null>([
  ['discord', 'https://discord.com/users/'],
  ['riot', null], // Riot Games does not provide a direct URL to access a user's profile by account name.
  ['steam', 'https://steamcommunity.com/id/'],
  ['twitter', 'https://twitter.com/'],
  ['xbox', 'https://account.xbox.com/en-us/Profile?gamertag='],
]);

const AccountInfo: FunctionComponent<AccountInfoProps> = props => {
  const [opened, { open, close }] = useDisclosure(false);

  if (!props.accountInfoData && !props.isLoading) {
    return (
      <center>
        <Card
          className="AccountInfoWrapper"
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
        >
          No account info found
        </Card>
      </center>
    );
  }

  const clickAvatarHandler = () => {
    if (props.accountInfoData?.avatar) {
      open();
    }
  };

  const getServiceUrl = (service: Service): string => {
    let url = serviceUrlRegistry.get(service.name);
    if (!url) {
      url = '/';
    }
    return url + service.user;
  };

  const geServices = () => {
    const services: ServiceAccountLinked[] = [];
    if (props.accountInfoData?.services) {
      props.accountInfoData.services.forEach(service => {
        {
          services?.push({ name: service.name, value: getServiceUrl(service) });
        }
      });
    }
    return services;
  };
  return (
    <>
      <Card
        className="AccountInfoWrapper"
        w={'100%'}
        h={'100%'}
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
      >
        <Flex className="ElementsContainer" direction="column" gap="md">
          <Flex
            className="TopElementsContainer"
            justify={'space-between'}
            w={'100%'}
            gap="md"
            wrap={'wrap-reverse'}
          >
            <Flex className="LeftElementsContainer" w={'70%'}>
              <Flex gap="md" w={'100%'} direction={'column'}>
                <BasicInfo
                  isLoading={props.isLoading}
                  accountId={props.accountInfoData?.id}
                  dateRegistered={props.accountInfoData?.dateRegistered}
                />
              </Flex>
            </Flex>
            <Flex
              className="RightElementsContainer"
              h={'100%'}
              justify={'start'}
              gap="md"
            >
              <AccountAvatar
                isLoading={props.isLoading}
                avatar={props.accountInfoData?.avatar}
                onclick={() => clickAvatarHandler()}
                onRemoveAvatar={() => props.onRemoveAvatar?.()}
              />
            </Flex>
          </Flex>
          <Flex gap={'sm'} align={'center'}>
            <Title size={'xxs'}>Account Level:</Title>
            {props.isLoading ? (
              <Skeleton height={10} w={'3rem'} />
            ) : (
              <Badge>{props.accountInfoData?.level ?? 'N/A'}</Badge>
            )}
          </Flex>

          <Card>
            <SocialsTable services={geServices()} isLoading={props.isLoading} />
          </Card>
          <Card>
            <WalletsAccountTable
              isLoading={props.isLoading}
              accounts={props.accountInfoData?.accounts}
            />
          </Card>
        </Flex>
      </Card>
      <Modal
        opened={opened}
        display={'flex'}
        radius={'1rem'}
        centered
        onClose={close}
        zIndex={300}
      >
        <Image
          className="AvatarModal"
          src={props.accountInfoData?.avatar ?? ''}
        />
      </Modal>
    </>
  );
};

export default AccountInfo;
