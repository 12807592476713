import { Button, Flex, MultiSelect } from '@mantine/core';
import { FunctionComponent, useState } from 'react';

import {
  ClosureReport,
  ReportIdentifier,
  ServiceReportData,
} from '../../../data/metrics-data';
import {
  calculateNumberOfPages,
  defaultPagesize,
} from '../../../helper/reports-pagination';
import { MetricTableCard } from '../QuestAccomplishedByUserCard/MetricTableCard';

export interface ClosureReportProps {
  onExportData: () => void;
  onUserNameClick: (userName: string) => void;
  isLoading: boolean;
  blockchains: string[];
  services: string[];
  options: ClosureReportOptions;
  onOptionsChange: (options: ClosureReportOptions) => void;
  report?: ServiceReportData<ClosureReport[]>;
  reportLoading?: boolean;
}

export interface ClosureReportOptions {
  selectedBlockchains: string[];
  selectedServices: string[];
  pageNumber: number;
  pageSize: number;
}

const ClosureReportCard: FunctionComponent<ClosureReportProps> = props => {
  const [selectedBlockchains, setSelectedBlockchains] = useState<string[]>(
    props.options.selectedBlockchains
  );
  const [selectedServices, setSelectedServices] = useState<string[]>(
    props.options.selectedServices
  );
  const getFilterSection = () => {
    return (
      <Flex pt={'0.3rem'} direction={'row'} gap={'sm'} align={'end'}>
        <MultiSelect
          clearable
          label={'Blockchain'}
          w={'100%'}
          data={props.blockchains}
          defaultValue={props.blockchains}
          onChange={selectedBlockchains => {
            setSelectedBlockchains(selectedBlockchains);
          }}
        ></MultiSelect>
        <MultiSelect
          clearable
          label={'Service'}
          w={'100%'}
          defaultValue={props.services}
          data={props.services}
          onChange={selectedServices => {
            setSelectedServices(selectedServices);
          }}
        ></MultiSelect>
        <Button
          disabled={
            selectedBlockchains.length === 0 || selectedServices.length === 0
          }
          w={'20%'}
          onClick={() => {
            props.onOptionsChange({
              ...props.options,
              selectedBlockchains: selectedBlockchains,
              selectedServices: selectedServices,
              pageNumber: 1,
            });
          }}
        >
          Apply
        </Button>
      </Flex>
    );
  };
  return (
    <Flex direction="column" gap={'0.3rem'}>
      {getFilterSection()}
      {
        <MetricTableCard
          key={3}
          reportLoading={props.reportLoading}
          onUserNameClick={userName => {
            props.onUserNameClick(userName);
          }}
          metricType={ReportIdentifier.ClosureReport}
          title={'Campaign Report'}
          data={props.report?.data}
          isLoading={props.isLoading}
          onPageChange={page => {
            props.onOptionsChange({ ...props.options, pageNumber: page });
          }}
          totalPageCount={calculateNumberOfPages(
            props.report?.totalRows ?? 1,
            defaultPagesize
          )}
          activePage={props.options.pageNumber}
          onExportData={() => {
            props.onExportData();
          }}
        />
      }
    </Flex>
  );
};

export default ClosureReportCard;
