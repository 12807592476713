export enum MessageStatus {
  Warning = 'warning',
  Loading = 'loading',
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

export enum ToastPositions {
  Top = 'top',
  TopRight = 'top-right',
  TopLeft = 'top-left',
  Bottom = 'bottom',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
}

export interface NotifyOptions {
  status: MessageStatus;
  title: string;
  description: string;
  position?: ToastPositions;
  response?: any;
}

export class NotificationService {
  private _provider: any;
  public constructor(provider: any) {
    this._provider = provider;
  }
  public notify(
    status: MessageStatus,
    title: string,
    description: string,
    position?: ToastPositions
  ) {
    this._provider({
      position: position ?? ToastPositions.TopRight,
      title: title,
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }

  public notifySelfServiceError(notifyOptions: NotifyOptions) {
    this._provider({
      position: notifyOptions.position ?? ToastPositions.TopRight,
      title: notifyOptions.title,
      description: `${notifyOptions.description}${notifyOptions.response?.data?.message ?? notifyOptions.response?.data?.detail ?? notifyOptions.response?.data?.error_msg ?? 'Unknown error'}`,
      status: notifyOptions.status,
      duration: 3000,
      isClosable: true,
    });
  }
}
