import { QuestTemplate } from './templates';

export interface QuestFetchResponse {
  quests: Quest[];
  templates: QuestTemplate[];
}

export interface Quest {
  accomplishments_allowed_per_window: number;
  campaign_id: string;
  description: string;
  enabled: boolean;
  end_datetime: string;
  extra_data: null | any;
  hidden: boolean;
  icon: string | null;
  orderNo: number;
  points: number;
  quest_id: string;
  quest_type: string;
  refresh_interval: number | null;
  start_datetime: string;
  title: string;
  variable_json: null | any;
  template: string;
  quest_variables: null | any;
}

export enum QuestIcons {
  Discord = 'discord',
  Achievement = 'achievement',
  DailyActiveTweet = 'daily-active-tweet',
  Twitter = 'twitter',
  ClickLink = 'click_link',
}

export interface SortStructure {
  campaign_id: string;
  sort_values: SortValue[];
}

export interface SortValue {
  quest_id: string;
  orderNo: number;
}

export interface SortStructure {
  campaign_id: string;
  sort_values: SortValue[];
}

export interface SortableQuest {
  id: number;
  quest: Quest;
}

export interface QuestVariable {
  key: string;
  value: string;
}

export const blackQuest: Quest = {
  accomplishments_allowed_per_window: 0,
  campaign_id: '',
  description: '',
  enabled: false,
  end_datetime: '',
  extra_data: null,
  hidden: false,
  icon: '',
  orderNo: 0,
  points: 0,
  quest_id: '',
  quest_type: '',
  refresh_interval: null,
  start_datetime: '',
  title: '',
  variable_json: null,
  template: '',
  quest_variables: null,
};

export const questThatNeedsTwitter = ['like'];

export interface QuestFieldMetadata {
  isNotInheritedFromGamePage: boolean;
  displayName: string;
  description: string;
  value: any;
  fieldKey: string;
  isInherited: boolean;
  inheritedFromTitle: string;
  inheritedFromUrl: string;
  type: 'calendar' | 'text' | 'boolean' | 'textarea';
}
