import {
  Button,
  Divider,
  Drawer,
  Flex,
  Switch,
  Title,
  TypographyStylesProvider,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CampaignAccountOptions from '../../components/CampaignAccountOptions/CampaignAccountOptions';
import HintsDialog from '../../components/HintsDialog/HintsDialog';
import QuestAccordion from '../../components/Quests/QuestAccordion/QuestAccordion';
import { QuestManagementForm } from '../../components/Quests/QuestManagementForm/QuestManagementForm';
import { QuestsList } from '../../components/Quests/QuestsList/QuestsList';
import { configuration } from '../../configuration';
import { GlobalContext } from '../../context/GlobalContext';
import { actionPageHints } from '../../data/action-page-hints';
import { MetadataDirectedFields } from '../../data/metadata-directed-fields';
import { Path } from '../../data/paths';
import {
  Quest,
  QuestFetchResponse,
  questThatNeedsTwitter,
  SortStructure,
} from '../../data/quest';
import { QuestTemplate } from '../../data/templates';
import { getSupportValidationFlow } from '../../helper/feature-flags';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useFromCheckList } from '../../hooks/use-from-checklist';
import { useNotification } from '../../hooks/use-notification';
import { useTier } from '../../hooks/use-tier';
import { questService } from '../../services/api-client/quest-service';
import { ssQuestService } from '../../services/api-client/ss-quest-service';
import { twitterService } from '../../services/api-client/twitter-service';
import { twitterVerificationService } from '../../services/api-client/twitter-verification-service';
import { localStorageService } from '../../services/local-storage-service';
import { MessageStatus } from '../../services/notification-service';
import { withAuthProtection } from '../../services/protect-route-element';
import { questDataService } from '../../services/quest-data-service';

const preSetOrder = (quests: Quest[]): Quest[] => {
  const newQuests = [...quests].map((quest, index) => {
    quest.orderNo = index + 1;
    return quest;
  });
  return newQuests;
};

const Page = () => {
  const [{ isFreeTier, component, isClose, setIsClose, setContactUsReason }] =
    useTier();
  const {
    loadingCampaignsOptions,
    selectedCampaignOption,
    selectedCampaignData,
    loadingSelectedCampaignData,
    setCampaignOptionsTrigger,
    selectedOrganization,
    campaignOptionsTrigger,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [isFromCheckList, setIsFromChecklist] = useFromCheckList();
  const notification = useNotification();
  const [refresh, setRefresh] = useState(false);
  const [savingCampaign, setSavingCampaign] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState<ReactNode>('Add Quest');
  const [selectedTemplate, setSelectedTemplate] = useState<QuestTemplate>();
  const [selectedQuest, setSelectedQuest] = useState<Quest>();
  const [isOpen, { open, close }] = useDisclosure(false);
  const [templates, setTemplates] = useState<(string | null)[]>([]);
  const [savingConnectedAccount, setSavingConnectedAccount] = useState(false);
  const supportValidationFlowValue = getSupportValidationFlow();
  const [saveConnectedAccountDisable, setSaveConnectedAccountDisable] =
    useState<boolean>(false);
  const [connectedAccountsValues, setConnectedAccountsValues] = useState<
    string[]
  >([]);
  const [groupBy, setGroupBy] = useState<string | null>(
    localStorageService.getActionGroupBy()
  );
  const userEmail = localStorageService.getLoginData()?.user.email ?? '';
  const isForgeStaff =
    userEmail?.endsWith('@forge.gg') ||
    configuration.automation.automationEmails.includes(userEmail);
  const [
    questFetchResponse,
    isLoadingQuestFetchResponse,
    setQuestFetchResponse,
  ] = useDataFetcher<QuestFetchResponse>({
    serviceCall() {
      if (!selectedCampaignData?.campaign_id) {
        return Promise.resolve({ quests: [], templates: [] });
      }
      return questService.getQuestsByCampaignId(
        selectedCampaignData.campaign_id
      );
    },
    dependencies: [refresh, selectedCampaignData],
    conditionForExecution: !!selectedCampaignData?.campaign_id,
    onFinish(response) {
      if (!response || response.quests) {
        return;
      }
      preSetOrder(response.quests);
      setQuestFetchResponse(response);
    },
    onError(error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while fetching the quests: ' +
          error.response.data.message +
          'Please try again.'
      );
    },
  });

  const [twitterUserId, loadingTwitterUserId] = useDataFetcher({
    serviceCall() {
      if (selectedCampaignData?.extra_data?.social?.twitter) {
        const userName =
          selectedCampaignData?.extra_data?.social?.twitter?.split('/').pop() ??
          '';
        return twitterVerificationService.getAccountIdFromUserName(userName);
      }
    },
    dependencies: [selectedCampaignData],
    conditionForExecution: !!selectedCampaignData,
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while fetching the twitter user id. Please try again.'
      );
    },
  });

  const [campaignAccountOptions, loadingCampaignAccountOptions] =
    useDataFetcher({
      serviceCall() {
        if (selectedCampaignOption) {
          return questService.getCampaignAccountsDetails(
            selectedCampaignOption.draft_campaign_id
          );
        }
      },
      dependencies: [selectedCampaignData, selectedCampaignOption, refresh],
      conditionForExecution: !!selectedCampaignOption,
      onError() {
        notification.notify(
          MessageStatus.Error,
          'Quest Management',
          'An error occurred while fetching the campaign account options. Please try again.'
        );
      },
      onFinish() {
        setSaveConnectedAccountDisable(true);
      },
    });

  useEffect(() => {
    const title = (
      <Flex direction={'column'} gap={'xs'}>
        <Title size={'1.2rem'}>
          {selectedQuest
            ? `Edit action "${selectedTemplate?.displayName}"`
            : `Add action "${selectedTemplate?.displayName}"`}
        </Title>
        <Title size={'0.9rem'} c={'gray'}>
          <TypographyStylesProvider>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedTemplate?.template_description ?? '',
              }}
            />
          </TypographyStylesProvider>
        </Title>
      </Flex>
    );
    setDrawerTitle(title);
  }, [selectedTemplate]);

  useEffect(() => {
    localStorageService.setActionGroupBy(groupBy);
  }, [groupBy]);

  useEffect(() => {
    const questTypes = questDataService.getQuestTypes(
      questFetchResponse?.quests
    );
    setTemplates(questTypes);
  }, [questFetchResponse]);

  const handleQuestEdit = (quest: Quest) => {
    setSelectedQuest(quest);
    open();
  };

  const needsActiveTwitterAccount = async (quest: Quest) => {
    if (!selectedOrganization) {
      throw new Error('No campaign selected');
    }
    if (questThatNeedsTwitter.includes(quest.template)) {
      const linkedAccount = await twitterService.getLinkedAccount(
        selectedOrganization?.partnerSlug
      );
      if (!linkedAccount) {
        return true;
      }
    }
    return false;
  };

  const handleSaveQuest = async (quest: Quest, isAddition?: boolean) => {
    try {
      setSavingCampaign(true);
      if (supportValidationFlowValue) {
        await ssQuestService.validate(quest);
      }
      const needsActiveTwitterAccountCheck =
        await needsActiveTwitterAccount(quest);
      if (needsActiveTwitterAccountCheck) {
        notification.notify(
          MessageStatus.Warning,
          'Quest Management',
          `An active Twitter account is required to save this quest.
           Please link your Twitter account in the tweets page and try again.
            In the meantime, the quest will be disabled.`
        );
        quest.enabled = false;
      }
      if (isAddition) {
        await questService.addQuest(quest);
      } else {
        await questService.editQuest(quest);
      }
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      console.error(error);
      notification.notifySelfServiceError({
        status: MessageStatus.Error,
        title: 'Quest Management',
        description: 'An error occurred while saving the quest. ',
        response: error.response,
      });
    } finally {
      setSavingCampaign(false);
      close();
      setRefresh(prev => !prev);
      if (isFromCheckList) {
        setIsFromChecklist(false);
        navigate('/home');
      }
    }
  };

  const handleSaveOrder = async (sortStructure: SortStructure) => {
    try {
      await questService.saveQuestOrder(sortStructure);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while saving the quest order. Please try again.'
      );
    }
  };

  const getMetadataDirectedFields = (): MetadataDirectedFields => {
    return {
      quest_number: getQuestNumber(),
      twitter_user_id: twitterUserId ?? '',
      partner_name: selectedCampaignData?.extra_data.partner_name ?? '',
      campaign_id: selectedCampaignData?.campaign_id ?? '',
      website_link: selectedCampaignData?.extra_data?.website ?? '',
      game_platform: selectedCampaignData?.extra_data.platform ?? '',
      website_description: '',
      discord_handle:
        selectedCampaignData?.extra_data?.social.discord?.split('/').pop() ??
        '',
      youtube_handle:
        selectedCampaignData?.extra_data?.social?.youtube?.split('/').pop() ??
        '',
      twitter_handle:
        selectedCampaignData?.extra_data?.social?.twitter?.split('/').pop() ??
        '',
      telegram_handle:
        selectedCampaignData?.extra_data?.social?.telegram?.split('/').pop() ??
        '',
    };
  };

  const getTemplate = (_selectedTemplate?: QuestTemplate) => {
    if (!questFetchResponse?.templates) {
      return;
    }
    if (_selectedTemplate) {
      return _selectedTemplate;
    }
    const selectedTemplate = questFetchResponse?.templates.find(
      x => x.template_name === selectedQuest?.template
    );

    if (selectedTemplate) {
      return selectedTemplate;
    }

    return questFetchResponse?.templates[0];
  };

  const getQuestNumber = (_selectedTemplate?: QuestTemplate): number => {
    if (!questFetchResponse) {
      return 1;
    }
    const template = getTemplate(_selectedTemplate);
    if (!template) {
      return 1;
    }
    const matchingQuests = questFetchResponse.quests.filter(
      quest => quest.template === template.template_name
    );
    return matchingQuests.length + 1;
  };

  const getManagementForm = () => {
    if (!selectedCampaignData || !questFetchResponse?.templates) return <></>;
    return (
      <QuestManagementForm
        isAddition={!selectedQuest}
        isLoading={
          savingCampaign || isLoadingQuestFetchResponse || loadingTwitterUserId
        }
        campaignId={selectedCampaignData.campaign_id}
        tier={selectedOrganization?.tier ?? 'free'}
        isForgeStaff={isForgeStaff ?? false}
        onSave={(quest, isAddition) => handleSaveQuest(quest, isAddition)}
        onCancel={close}
        onTemplateChange={setSelectedTemplate}
        onUpsellClick={() => upsellCheck('startTime')}
        metadataDirectedFields={getMetadataDirectedFields()}
        currentQuest={selectedQuest}
        getQuestNumber={selectedQuest => getQuestNumber(selectedQuest)}
        questCount={questFetchResponse.quests.length}
        questTemplates={questFetchResponse?.templates}
        gamePage={Path.Game}
      />
    );
  };

  const upsellCheck = (reason: string) => {
    if (isFreeTier && !isForgeStaff) {
      setContactUsReason(reason);
      setIsClose(false);
      return true;
    }
    return false;
  };

  const handleAddQuest = () => {
    if (upsellCheck('AddingQuest') === true) {
      return;
    }
    setSelectedQuest(undefined);
    open();
  };

  const getElements = () => {
    if (!groupBy) {
      return (
        <QuestsList
          quests={questFetchResponse?.quests ?? []}
          onSortChange={handleSaveOrder}
          isLoading={
            isLoadingQuestFetchResponse ||
            loadingSelectedCampaignData ||
            loadingCampaignsOptions ||
            loadingTwitterUserId
          }
          onQuestToEdit={handleQuestEdit}
        />
      );
    }
    return (
      <QuestAccordion
        template={templates}
        quests={questFetchResponse?.quests ?? []}
        onSortChange={handleSaveOrder}
        isLoading={
          isLoadingQuestFetchResponse ||
          loadingSelectedCampaignData ||
          loadingTwitterUserId
        }
        onQuestToEdit={quest => {
          handleQuestEdit(quest);
        }}
      />
    );
  };

  const handleOnConnectedAccountSave = async () => {
    try {
      setSavingConnectedAccount(true);
      if (!selectedCampaignData) {
        throw new Error('No campaign selected');
      }
      await questService.updateCampaignAccounts(
        selectedCampaignData.campaign_id,
        connectedAccountsValues
      );
      setSaveConnectedAccountDisable(true);
      setRefresh(prev => !prev);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while updating the connected accounts. Please try again.'
      );
    } finally {
      setSavingConnectedAccount(false);
    }
  };

  const handleOnConnectedAccountChange = (selectedAccounts: string[]) => {
    setConnectedAccountsValues(selectedAccounts);
    setSaveConnectedAccountDisable(false);
  };

  return (
    <Flex direction={'column'}>
      {isFreeTier && !isClose && component}
      <Flex
        direction={'column'}
        pos={'sticky'}
        top={0}
        style={{ zIndex: 200 }}
        bg={'#f9fafb'}
      >
        <Flex align={'self-end'} gap={'md'} justify={'space-between'} p={'xs'}>
          <CampaignAccountOptions
            data={campaignAccountOptions}
            handleOnConnectedAccountChange={handleOnConnectedAccountChange}
            handleOnConnectedAccountSave={handleOnConnectedAccountSave}
            loadingData={
              loadingCampaignAccountOptions ||
              loadingCampaignsOptions ||
              loadingSelectedCampaignData
            }
            saveConnectedAccountDisable={saveConnectedAccountDisable}
            saving={savingConnectedAccount}
          />
          <Flex align={'center'} gap={'md'} justify={'flex-end'} p={'xs'}>
            <Switch
              defaultChecked={!!groupBy}
              onChange={e => setGroupBy(e.currentTarget.checked ? 'type' : '')}
              label="Group by type"
            ></Switch>
            <Button
              disabled={
                loadingSelectedCampaignData ||
                isLoadingQuestFetchResponse ||
                loadingTwitterUserId
              }
              onClick={() => {
                handleAddQuest();
              }}
            >
              Add
            </Button>
          </Flex>
        </Flex>
        <Divider />
      </Flex>
      <HintsDialog hints={actionPageHints} />
      <Flex
        className="QuestsPageWrapper"
        justify={'center'}
        align={'center'}
        direction={'column'}
        h={'100%'}
        w={'100%'}
        p={'md'}
      >
        {getElements()}

        <Drawer
          key={'add-drawer'}
          zIndex={600}
          opened={isOpen}
          onClose={close}
          title={drawerTitle}
          position="right"
          size={'xl'}
        >
          {getManagementForm()}
        </Drawer>
      </Flex>
    </Flex>
  );
};

export const ActionPage = withAuthProtection(Page);
