import { Anchor, Button, Card, Flex, Image, Text } from '@mantine/core';
import { useState } from 'react';
import { LocalImages } from '../../assets/images';
import { KolsList } from '../../components/Kolls/KolsList/KolsList';
import TopKolls from '../../components/Kolls/TopKolls/TopKolls';
import { Kol, TopKol } from '../../data/kols';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useNotification } from '../../hooks/use-notification';
import { kolsService } from '../../services/api-client/kols-service';
import { MessageStatus } from '../../services/notification-service';
import { withAuthProtection } from '../../services/protect-route-element';
import './KOLsPage.scss';

const Page = () => {
  const notification = useNotification();
  const [refresh, setRefresh] = useState(false);
  const [kols, kolsLoading] = useDataFetcher<Kol[]>({
    serviceCall() {
      return kolsService.getKols();
    },
    dependencies: [refresh],
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Error',
        'Error fetching KOLs list'
      );
    },
  });

  const [topKols, topKolsLoading] = useDataFetcher<TopKol[]>({
    serviceCall() {
      return kolsService.getTopKols();
    },
    dependencies: [refresh],
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Error',
        'Error fetching top KOLs list'
      );
    },
  });

  if ((!kols || kols.length === 0) && !kolsLoading) {
    return (
      <Flex
        mih={'30rem'}
        className="KOLsPageWrapper"
        direction={'column'}
        align={'center'}
        justify={'center'}
        gap={'1rem'}
      >
        <Flex
          direction={'column'}
          align={'center'}
          gap={'1rem'}
          justify={'center'}
        >
          <Image src={LocalImages.kolverse} w={'13.375rem'} />
          <Card p={'2rem'} maw={'25rem'} withBorder={true} radius={'1rem'}>
            <Text ta="center">
              {`There are no KOLs available at the moment. Get in touch we the technical team to request the addition of KOLs by sending an email to `}
            </Text>
            <Anchor href="mailto:support@forge.gg">
              <Text ta="center">{`support@forge.gg `}</Text>
            </Anchor>
          </Card>
          <Flex direction={'row'} gap={'1rem'}>
            <Button onClick={() => setRefresh(!refresh)}>Refresh</Button>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      className="KOLsPageWrapper"
      direction={'column'}
      align={'center'}
      gap={'1rem'}
    >
      <TopKolls isLoading={topKolsLoading} kolls={topKols ?? []} />
      <KolsList kols={kols} isLoading={kolsLoading} />
    </Flex>
  );
};

export const KOLsPage = withAuthProtection(Page);
