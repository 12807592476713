import {
  Button,
  Divider,
  Drawer,
  Flex,
  MultiSelect,
  Skeleton,
  Switch,
  Title,
  Tooltip,
  TypographyStylesProvider,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HintsDialog from '../../components/HintsDialog/HintsDialog';
import QuestAccordion from '../../components/Quests/QuestAccordion/QuestAccordion';
import { QuestManagementForm } from '../../components/Quests/QuestManagementForm/QuestManagementForm';
import { QuestsList } from '../../components/Quests/QuestsList/QuestsList';
import { configuration } from '../../configuration';
import { actionPageHints } from '../../data/action-page-hints';
import { Campaign } from '../../data/campaign';
import { MetadataDirectedFields } from '../../data/metadata-directed-fields';
import { Path } from '../../data/paths';
import {
  Quest,
  QuestFetchResponse,
  questThatNeedsTwitter,
  SortStructure,
} from '../../data/quest';
import { QuestTemplate } from '../../data/templates';
import { getSupportValidationFlow } from '../../helper/feature-flags';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useFromCheckList } from '../../hooks/use-from-checklist';
import { useNotification } from '../../hooks/use-notification';
import { useTier } from '../../hooks/use-tier';
import { campaignService } from '../../services/api-client/campaign-service';
import { questService } from '../../services/api-client/quest-service';
import { ssQuestService } from '../../services/api-client/ss-quest-service';
import { twitterService } from '../../services/api-client/twitter-service';
import { twitterVerificationService } from '../../services/api-client/twitter-verification-service';
import { localStorageService } from '../../services/local-storage-service';
import { MessageStatus } from '../../services/notification-service';
import { withAuthProtection } from '../../services/protect-route-element';
import { questDataService } from '../../services/quest-data-service';

const preSetOrder = (quests: Quest[]): Quest[] => {
  const newQuests = [...quests].map((quest, index) => {
    quest.orderNo = index + 1;
    return quest;
  });
  return newQuests;
};

const Page = () => {
  const [{ isFreeTier, component, isClose, setIsClose, setContactUsReason }] =
    useTier();
  const navigate = useNavigate();
  const [isFromCheckList, setIsFromChecklist] = useFromCheckList();
  const notification = useNotification();
  const [refresh, setRefresh] = useState(false);
  const campaignDetail = localStorageService.getSelectedCampaign();
  const [savingCampaign, setSavingCampaign] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
  const selectedOrganization = localStorageService.getSelectedOrganization();
  const [selectedTemplate, setSelectedTemplate] = useState<QuestTemplate>();
  const [drawerTitle, setDrawerTitle] = useState<ReactNode>();
  const [selectedQuest, setSelectedQuest] = useState<Quest>();
  const [isOpen, { open, close }] = useDisclosure(false);
  const [templates, setTemplates] = useState<(string | null)[]>([]);
  const [savingConnectedAccount, setSavingConnectedAccount] = useState(false);
  const supportValidationFlowValue = getSupportValidationFlow();
  const [saveConnectedAccountDisable, setSaveConnectedAccountDisable] =
    useState<boolean>(false);
  const [connectedAccountsValues, setConnectedAccountsValues] = useState<
    string[]
  >([]);
  const [groupBy, setGroupBy] = useState<string | null>(
    localStorageService.getActionGroupBy()
  );
  const userEmail = localStorageService.getLoginData()?.user.email ?? '';
  const isForgeStaff =
    userEmail?.endsWith('@forge.gg') ||
    configuration.automation.automationEmails.includes(userEmail);
  const [, loadingCampaign] = useDataFetcher({
    serviceCall() {
      if (!selectedOrganization) {
        notification.notify(
          MessageStatus.Error,
          'Quest Management',
          'No partner selected'
        );
        return Promise.resolve([]);
      }
      return campaignService.getAll(selectedOrganization.partnerSlug);
    },
    onFinish(campaigns) {
      if (!campaigns) {
        return;
      }
      setSelectedCampaign(campaigns[0]);
    },
  });

  const [
    questFetchResponse,
    isLoadingQuestFetchResponse,
    setQuestFetchResponse,
  ] = useDataFetcher<QuestFetchResponse>({
    serviceCall() {
      if (!selectedCampaign?.campaign_id) {
        return Promise.resolve({ quests: [], templates: [] });
      }
      return questService.getQuestsByCampaignId(selectedCampaign.campaign_id);
    },
    onFinish(response) {
      if (!response || response.quests) {
        return;
      }
      preSetOrder(response.quests);
      setQuestFetchResponse(response);
    },
    onError(error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while fetching the quests: ' +
          error?.response?.data?.message +
          'Please try again.'
      );
    },
    dependencies: [refresh, selectedCampaign],
    conditionForExecution: !!selectedCampaign?.campaign_id,
  });

  const [twitterUserId, loadingTwitterUserId] = useDataFetcher({
    serviceCall() {
      if (selectedCampaign?.extra_data?.social?.twitter) {
        const userName =
          selectedCampaign?.extra_data?.social?.twitter?.split('/').pop() ?? '';
        return twitterVerificationService.getAccountIdFromUserName(userName);
      }
    },
    dependencies: [selectedCampaign],
    conditionForExecution: !!selectedCampaign,
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while fetching the twitter user id. Please try again.'
      );
    },
  });

  const [campaignAccountOptions, loadingCampaignAccountOptions] =
    useDataFetcher({
      serviceCall() {
        if (selectedCampaign) {
          return questService.getCampaignAccountsDetails(
            selectedCampaign.campaign_id
          );
        }
      },
      dependencies: [selectedCampaign, refresh],
      conditionForExecution: !!selectedCampaign,
      onError() {
        notification.notify(
          MessageStatus.Error,
          'Quest Management',
          'An error occurred while fetching the campaign account options. Please try again.'
        );
      },
    });

  useEffect(() => {
    const title = (
      <Flex direction={'column'} gap={'xs'}>
        <Title size={'1.2rem'}>
          {selectedQuest
            ? `Edit action "${selectedTemplate?.displayName}"`
            : `Add action "${selectedTemplate?.displayName}"`}
        </Title>
        <TypographyStylesProvider>
          <div
            dangerouslySetInnerHTML={{
              __html: selectedTemplate?.template_description ?? '',
            }}
          />
        </TypographyStylesProvider>
      </Flex>
    );
    setDrawerTitle(title);
  }, [selectedTemplate]);

  useEffect(() => {
    localStorageService.setActionGroupBy(groupBy);
  }, [groupBy]);

  useEffect(() => {
    const questTypes = questDataService.getQuestTypes(
      questFetchResponse?.quests
    );
    setTemplates(questTypes);
  }, [questFetchResponse]);

  const handleQuestEdit = (quest: Quest) => {
    setSelectedQuest(quest);
    open();
  };

  const needsActiveTwitterAccount = async (quest: Quest) => {
    if (!selectedOrganization) {
      throw new Error('No campaign selected');
    }
    if (questThatNeedsTwitter.includes(quest.template)) {
      const linkedAccount = await twitterService.getLinkedAccount(
        selectedOrganization?.partnerSlug
      );
      if (!linkedAccount) {
        return true;
      }
    }
    return false;
  };

  const handleSaveQuest = async (quest: Quest, isAddition?: boolean) => {
    try {
      setSavingCampaign(true);
      if (supportValidationFlowValue) {
        await ssQuestService.validate(quest);
      }
      const needsActiveTwitterAccountCheck =
        await needsActiveTwitterAccount(quest);
      if (needsActiveTwitterAccountCheck) {
        notification.notify(
          MessageStatus.Warning,
          'Quest Management',
          `An active Twitter account is required to save this quest.
           Please link your Twitter account in the tweets page and try again.
            In the meantime, the quest will be disabled.`
        );
        quest.enabled = false;
      }
      if (isAddition) {
        await questService.addQuest(quest);
      } else {
        await questService.editQuest(quest);
      }
    } catch (error: any) {
      notification.notifySelfServiceError({
        status: MessageStatus.Error,
        title: 'Quest Management',
        description: 'An error occurred while saving the quest. ',
        response: error.response,
      });
    } finally {
      setSavingCampaign(false);
      close();
      setRefresh(prev => !prev);
      if (isFromCheckList) {
        setIsFromChecklist(false);
        navigate('/home');
      }
    }
  };

  const handleSaveOrder = async (sortStructure: SortStructure) => {
    try {
      await questService.saveQuestOrder(sortStructure);
    } catch (error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while saving the quest order. Please try again.'
      );
    }
  };

  const getMetadataDirectedFields = (): MetadataDirectedFields => {
    return {
      quest_number: getQuestNumber(),
      twitter_user_id: twitterUserId ?? '',
      partner_name: selectedCampaign?.extra_data.partner_name ?? '',
      campaign_id: selectedCampaign?.campaign_id ?? '',
      website_link: selectedCampaign?.extra_data?.website ?? '',
      game_platform: selectedOrganization?.gameName ?? '',
      website_description: '',
      discord_handle:
        selectedCampaign?.extra_data?.social.discord?.split('/').pop() ?? '',
      youtube_handle:
        selectedCampaign?.extra_data?.social?.youtube?.split('/').pop() ?? '',
      twitter_handle:
        selectedCampaign?.extra_data?.social?.twitter?.split('/').pop() ?? '',
      telegram_handle:
        selectedCampaign?.extra_data?.social?.telegram?.split('/').pop() ?? '',
    };
  };

  const getTemplate = (_selectedTemplate?: QuestTemplate) => {
    if (!questFetchResponse?.templates) {
      return;
    }
    if (_selectedTemplate) {
      return _selectedTemplate;
    }
    const selectedTemplate = questFetchResponse?.templates.find(
      x => x.template_name === selectedQuest?.template
    );

    if (selectedTemplate) {
      return selectedTemplate;
    }

    return questFetchResponse?.templates[0];
  };

  const getQuestNumber = (_selectedTemplate?: QuestTemplate): number => {
    if (!questFetchResponse) {
      return 1;
    }
    const template = getTemplate(_selectedTemplate);
    if (!template) {
      return 1;
    }
    const matchingQuests = questFetchResponse.quests.filter(
      quest => quest.template === template.template_name
    );
    return matchingQuests.length + 1;
  };

  const getManagementForm = () => {
    if (
      !selectedCampaign ||
      !questFetchResponse?.templates ||
      !selectedOrganization
    )
      return <></>;
    return (
      <QuestManagementForm
        isAddition={!selectedQuest}
        isLoading={
          savingCampaign || isLoadingQuestFetchResponse || loadingTwitterUserId
        }
        campaignId={selectedCampaign.campaign_id}
        tier={selectedOrganization?.tier ?? 'free'}
        isForgeStaff={isForgeStaff ?? false}
        onSave={(quest, isAddition) => handleSaveQuest(quest, isAddition)}
        onTemplateChange={setSelectedTemplate}
        onCancel={close}
        onUpsellClick={() => upsellCheck('startTime')}
        metadataDirectedFields={getMetadataDirectedFields()}
        currentQuest={selectedQuest}
        getQuestNumber={selectedQuest => getQuestNumber(selectedQuest)}
        questCount={questFetchResponse.quests.length}
        questTemplates={questFetchResponse?.templates}
        gamePage={Path.GameCustomization}
      />
    );
  };

  const upsellCheck = (reason: string) => {
    if (isFreeTier && !isForgeStaff) {
      setContactUsReason(reason);
      setIsClose(false);
      return true;
    }
    return false;
  };

  const handleAddQuest = () => {
    if (upsellCheck('AddingQuest') === true) {
      return;
    }
    setSelectedQuest(undefined);
    open();
  };

  const getElements = () => {
    if (!groupBy) {
      return (
        <QuestsList
          quests={questFetchResponse?.quests ?? []}
          onSortChange={handleSaveOrder}
          isLoading={
            isLoadingQuestFetchResponse ||
            loadingCampaign ||
            loadingTwitterUserId
          }
          onQuestToEdit={handleQuestEdit}
        />
      );
    }
    return (
      <QuestAccordion
        template={templates}
        quests={questFetchResponse?.quests ?? []}
        onSortChange={handleSaveOrder}
        isLoading={
          isLoadingQuestFetchResponse || loadingCampaign || loadingTwitterUserId
        }
        onQuestToEdit={quest => {
          handleQuestEdit(quest);
        }}
      />
    );
  };

  const handleOnConnectedAccountSave = async () => {
    try {
      setSavingConnectedAccount(true);
      if (!selectedCampaign) {
        throw new Error('No campaign selected');
      }
      await questService.updateCampaignAccounts(
        selectedCampaign.campaign_id,
        connectedAccountsValues
      );
      setRefresh(prev => !prev);
    } catch (error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while updating the connected accounts. Please try again.'
      );
    } finally {
      setSavingConnectedAccount(false);
      setSaveConnectedAccountDisable(false);
    }
  };

  const handleOnConnectedAccountChange = (selectedAccounts: string[]) => {
    setConnectedAccountsValues(selectedAccounts);
    setSaveConnectedAccountDisable(true);
  };

  return (
    <Flex direction={'column'}>
      {isFreeTier && !isClose && component}
      <Flex
        direction={'column'}
        pos={'sticky'}
        top={0}
        style={{ zIndex: 200 }}
        bg={'#f9fafb'}
      >
        <Flex align={'self-end'} gap={'md'} justify={'space-between'} p={'xs'}>
          {campaignAccountOptions ? (
            <Flex gap={'1rem'} align={'flex-end'}>
              <Flex direction={'column'} gap={'0.2rem'} align={'flex-start'}>
                <Tooltip
                  multiline
                  maw={'20rem'}
                  label="Required user accounts are the accounts that users must connect to in order to complete the actions."
                >
                  <Title size={'0.9em'}>Required User Accounts</Title>
                </Tooltip>
                <MultiSelect
                  disabled={
                    !selectedCampaign ||
                    !campaignAccountOptions ||
                    loadingCampaignAccountOptions
                  }
                  data={campaignAccountOptions?.options.map(x => x.accountName)}
                  defaultValue={campaignAccountOptions?.accounts.map(
                    x => x.accountName
                  )}
                  onChange={handleOnConnectedAccountChange}
                  w={'20rem'}
                />
              </Flex>
              <Button
                loading={
                  loadingCampaignAccountOptions || savingConnectedAccount
                }
                disabled={!saveConnectedAccountDisable}
                onClick={() => handleOnConnectedAccountSave()}
              >
                Save
              </Button>
            </Flex>
          ) : (
            <Skeleton w={'20%'} h={'3rem'} />
          )}
          <Flex align={'center'} gap={'md'} justify={'flex-end'} p={'xs'}>
            <Switch
              defaultChecked={!!groupBy}
              onChange={e => setGroupBy(e.currentTarget.checked ? 'type' : '')}
              label="Group by type"
            ></Switch>
            <Button
              disabled={
                loadingCampaign ||
                isLoadingQuestFetchResponse ||
                loadingTwitterUserId
              }
              onClick={() => {
                handleAddQuest();
              }}
            >
              Add
            </Button>
          </Flex>
        </Flex>
        <Divider />
      </Flex>
      <HintsDialog hints={actionPageHints} />
      <Flex
        className="QuestsPageWrapper"
        justify={'center'}
        align={'center'}
        direction={'column'}
        h={'100%'}
        w={'100%'}
        p={'md'}
      >
        {getElements()}

        <Drawer
          key={'add-drawer'}
          zIndex={600}
          opened={isOpen}
          onClose={close}
          title={drawerTitle}
          position="right"
          size={'xl'}
        >
          {getManagementForm()}
        </Drawer>
      </Flex>
    </Flex>
  );
};

export const QuestsPage = withAuthProtection(Page);
