import { configuration } from '../configuration';
import { authentication } from './authentication';

export const linkTwitterAccount = async (
  partnerSlug: string,
  successRedirect: string,
  failureRedirct: string
): Promise<void> => {
  const accessToken = authentication.getAccessToken();

  if (!accessToken) {
    throw new Error('User authentication is required for this operation.');
  }

  const redirectBaseUrl = `${window.location.protocol}//${window.location.host}/`;
  const absoluteSuccessRedirect = `${redirectBaseUrl}${successRedirect.startsWith('/') ? successRedirect.slice(1) : successRedirect}`;
  const absoluteFailureRedirect = `${redirectBaseUrl}${failureRedirct.startsWith('/') ? failureRedirct.slice(1) : failureRedirct}`;

  const formUrl = `${configuration.forgeApi.baseUrl}/self-serve-accounts/auth/twitter?partner_slug=${partnerSlug}&success_redirect=${absoluteSuccessRedirect}&failure_redirect=${absoluteFailureRedirect}`;
  const form = document.createElement('form');
  form.action = formUrl;
  form.method = 'POST';

  const accessTokenInput = document.createElement('input');
  accessTokenInput.type = 'hidden';
  accessTokenInput.name = 'jwt_token';
  accessTokenInput.value = accessToken;
  form.appendChild(accessTokenInput);

  document.body.appendChild(form);
  form.submit();
};
