import {
  Button,
  Center,
  Flex,
  FlexProps,
  Loader,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FunctionComponent } from 'react';

export interface LoginCredentials {
  email: string;
}
export interface LoginFormProps {
  passwordRegex?: RegExp;
  passwordErrorMessage?: string;
  isLoading?: boolean;
  failedSignInMessage?: string;
  isSignUpActive?: boolean;
  onSignInClick?: (loginCredentials: LoginCredentials) => void;
  onSendMagicLink?: (email: string) => void;
  goToSignUp?: () => void;
  style?: FlexProps;
}

export const LoginForm: FunctionComponent<LoginFormProps> = props => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      email: '',
    },
    validate: {
      email: value => (emailRegex.test(value) ? null : 'Invalid email'),
    },
  });

  return (
    <form onSubmit={form.onSubmit(values => props.onSignInClick?.(values))}>
      {props.failedSignInMessage && (
        <Text c={'red'}>{props.failedSignInMessage}</Text>
      )}
      <Flex direction={'column'} gap={'0.75rem'}>
        <TextInput
          type="email"
          label="Email"
          disabled={props.isLoading}
          placeholder="email@example.com"
          {...form.getInputProps('email')}
        ></TextInput>
        {props.isLoading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <Flex gap={'0.5rem'} direction={'column'}>
            <Button
              variant="dark"
              disabled={!form.isValid()}
              className="btnSignInGoogle"
              onClick={() =>
                props.onSendMagicLink?.(form.getInputProps('email').value)
              }
            >
              Send magic link
            </Button>
            <Button
              variant="dark"
              hidden={!props.isSignUpActive}
              className="btnSignInGoogle"
              onClick={() => {
                props.goToSignUp?.();
              }}
            >
              SignUp
            </Button>
          </Flex>
        )}
      </Flex>
    </form>
  );
};
