import { useState } from 'react';

export const useFromCheckList = (): [
  isFromCheckList: boolean,
  setFromCheckList: React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const params = new URLSearchParams(window.location.search);
  const [fromCheckList, setFromCheckList] = useState<boolean>(
    params.get('fromCheckList') === 'true' ?? false
  );

  return [fromCheckList, setFromCheckList];
};
