// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Report {}

export interface UserReport extends Report {
  userId: string;
  userName: string;
}

export interface PaginatedReport {
  reports: Report[];
  pageCount: PageCount;
}
export interface PageCount {
  count: number;
}

export interface CompletedQuestReport extends Report {
  questId: string;
  quest: string;
  count: number;
  totalRows: number;
}

export interface PositivePointUserReport extends UserReport {
  finalPoints: number;
}

export interface UsersWithCompletedQuestsCountReport extends UserReport {
  accomplishmentCount: number;
  totalRows: number;
}

export interface ClosureReportDB extends ClosureReport {
  totalRows: number;
}

export interface ClosureReport extends Report {
  name: string;
  points: number;
  username: string;
  memberId: string;
  wallet: string;
  blockchain: string;
  serviceName: string;
}

export enum ReportIdentifier {
  CompletedQuestsWithUserCount = 'CompletedQuestsWithUserCount',
  UsersWithPositivePoints = 'UsersWithPositivePoints',
  UsersWithCompletedQuestsCount = 'UsersWithCompletedQuestsCount',
  ClosureReport = 'ClosureReport',
}

export interface ReportsData {
  usersWithCompletedQuestsCount: UsersWithCompletedQuestsCountReport[];
  usersWithPositivePoints: PositivePointUserReport[];
}

export interface ServiceReportData<T> {
  data: T;
  type: ReportIdentifier;
  totalRows: number;
}
