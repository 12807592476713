import {
  ActionIcon,
  Anchor,
  Button,
  Flex,
  NativeSelect,
  Text,
  TextInput,
} from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getIcon } from '../../../data/icon-registry';
import {
  defaultNotUserVisibleFields,
  MetadataDirectedFields,
} from '../../../data/metadata-directed-fields';
import { Quest, QuestFieldMetadata, QuestVariable } from '../../../data/quest';
import { QuestTemplate } from '../../../data/templates';
import { formatDate, getUTCDate } from '../../../helper/dates';
import { handleVariablesMap } from '../../../services/api-client/quest-service';
import {
  handleVariables,
  questDataService,
} from '../../../services/quest-data-service';
import QuestElement from '../QuestBasicElement/QuestBasicElement';
import QuestCommonFields from '../QuestCommonFields/QuestCommonFields';

interface QuestAdditionFormProps {
  isAddition: boolean;
  isForgeStaff: boolean;
  campaignId: string;
  tier: string;
  metadataDirectedFields: MetadataDirectedFields;
  questTemplates: QuestTemplate[];
  isLoading?: boolean;
  onSave?: (quest: Quest, isAddition: boolean) => void;
  onTemplateChange: (template: QuestTemplate) => void;
  onCancel?: () => void;
  currentQuest?: Quest;
  onUpsellClick?: () => void;
  getQuestNumber: (selectedTemplate: QuestTemplate) => number;
  questCount: number;
  gamePage: string;
}

export const QuestManagementForm: FunctionComponent<
  QuestAdditionFormProps
> = props => {
  const {
    currentQuest,
    metadataDirectedFields,
    isAddition,
    isLoading,
    questTemplates,
    campaignId,
    tier,
    questCount,
    onSave,
    onCancel,
    gamePage,
    onTemplateChange,
  } = props;
  const navigate = useNavigate();
  const [allowAddition, setAllowAddition] = useState(false);
  const [formError, setFormError] = useState('');
  const [elementsInError, setElementInError] = useState<string[]>([]);
  const [questTemplate, setQuestTemplate] = useState<QuestTemplate>(
    questDataService.initializeQuestTemplate(questTemplates, currentQuest)
  );
  const [quest, setQuest] = useState<Quest>(
    questDataService.initializeQuest(
      questTemplate,
      campaignId,
      isAddition
        ? props.getQuestNumber(questTemplate) + 1
        : metadataDirectedFields['quest_number'],
      questCount,
      metadataDirectedFields,
      currentQuest
    )
  );

  const [questVariables, setQuestVariables] = useState<QuestVariable[]>(
    questDataService.initializeQuestVariables(quest, questTemplate, questCount)
  );
  const [questMetadataFields, setQuestMetadataFields] =
    useState<QuestFieldMetadata[]>();

  useEffect(() => {
    const questFields = buildQuestFields(questTemplate);
    const initializedQuest = questDataService.initializeQuest(
      questTemplate,
      campaignId,
      isAddition
        ? props.getQuestNumber(questTemplate) + 1
        : metadataDirectedFields['quest_number'],
      questCount,
      metadataDirectedFields,
      currentQuest,
      questFields
    );
    setQuestMetadataFields(questFields);
    handelMultiActive(questTemplate);
    setQuest(initializedQuest);
    onTemplateChange(questTemplate);

    const initialFieldErrors: string[] = [];
    questFields?.forEach(field => {
      if (!field.fieldKey || !field.value || field.value === '') {
        initialFieldErrors.push(field.fieldKey);
      }
    });
    setElementInError(initialFieldErrors);
  }, [questTemplate]);

  const evaluateInheritValue = (variable: keyof MetadataDirectedFields) => {
    if (!questTemplate || !props.metadataDirectedFields) {
      return '';
    }
    return props.metadataDirectedFields[variable];
  };

  const buildQuestFields = (templateObj: QuestTemplate) => {
    if (!templateObj) {
      return;
    }

    const templateVariables = templateObj.template_variables
      .join(',')
      .replaceAll('{', '')
      .replaceAll('}', '')
      .split(',')
      .map(x => x as keyof MetadataDirectedFields);
    const questMetadataFields: QuestFieldMetadata[] = [];
    templateVariables.forEach(variable => {
      const isInherited = questDataService.determineIfInheritedField(variable);
      const fieldValue = isInherited
        ? evaluateInheritValue(variable)
        : questVariables.find(x => x.key === variable)?.value;
      const newQuestField: QuestFieldMetadata = {
        description:
          templateObj.templateVariablesMetadata?.[variable]?.description,
        displayName: variable.charAt(0).toUpperCase() + variable.slice(1),
        inheritedFromTitle: isInherited ? 'Game Info' : '',
        inheritedFromUrl: isInherited ? '/game-customization' : '',
        value: fieldValue,
        fieldKey: variable,
        isInherited,
        type: 'text',
        isNotInheritedFromGamePage:
          questDataService.determineIfNotInheritedFieldFromGamePage(variable),
      };
      questMetadataFields.push(newQuestField);
    });
    return questMetadataFields;
  };

  const handleInputChange = (fieldKey: string, value: string) => {
    const tempQuestVariables = questDataService.getQuestVariables(
      questVariables,
      fieldKey,
      value
    );
    const tempTitle = questDataService.getElement(
      questTemplate,
      questVariables,
      'title',
      metadataDirectedFields
    );
    const tempDescription = questDataService.getElement(
      questTemplate,
      questVariables,
      'description',
      metadataDirectedFields
    );

    const tempJsonVariables = questDataService.getElementJson(
      questTemplate,
      questVariables,
      'variable_json',
      metadataDirectedFields
    );
    const tempExtraData = questDataService.getElementJson(
      questTemplate,
      questVariables,
      'extra_data',
      metadataDirectedFields
    );

    setQuest({
      ...quest,
      title: tempTitle,
      description: tempDescription,
      variable_json: tempJsonVariables,
      extra_data: tempExtraData,
      quest_variables: { ...quest.quest_variables, [fieldKey]: value },
    });
    setQuestVariables(tempQuestVariables);
  };

  const getDynamicDefaultValue = (fieldValue: QuestFieldMetadata) => {
    if (!fieldValue) {
      return '';
    }
    if (fieldValue.fieldKey === 'link') {
      return decodeURIComponent(fieldValue.value);
    }
    if (handleVariables?.includes(fieldValue.fieldKey)) {
      return fieldValue.value?.replace(/\s+/g, '').replaceAll('@', '');
    }
    return fieldValue.value;
  };

  const errorCheck = (fieldKey: string, value?: string | number) => {
    if (!value || value === '' || value === '0') {
      setElementInError([...elementsInError, fieldKey]);
      return;
    }
    setElementInError(elementsInError.filter(e => e !== fieldKey));
    return;
  };

  const getError = (field: keyof Quest) => {
    if (!isAddition) {
      if (elementsInError.includes(field)) {
        return `${field.charAt(0).toUpperCase() + field.slice(1).replaceAll('_', ' ')} field is required`;
      }
    }
    return undefined;
  };

  const getDynamicInputs = () => {
    return questMetadataFields?.map(field => {
      if (Object.keys(defaultNotUserVisibleFields).includes(field.fieldKey)) {
        return;
      }
      return (
        <TextInput
          required
          description={`${field.description ? field.description + '.' : ''} ${
            field.isInherited && !field.isNotInheritedFromGamePage
              ? 'This field is inherited from the game page'
              : ''
          }`}
          leftSection={
            handleVariablesMap?.includes(field.fieldKey) ? (
              <Text key={`left-section-${quest.quest_id}-${field.fieldKey}`}>
                @
              </Text>
            ) : undefined
          }
          error={getError(field.fieldKey as keyof Quest)}
          key={`${quest.quest_id}-${field.fieldKey}`}
          disabled={field.isInherited}
          rightSection={
            field.isInherited &&
            !field.isNotInheritedFromGamePage && (
              <Anchor onClick={() => navigate(gamePage)}>
                <ActionIcon>
                  <FaEdit />
                </ActionIcon>
              </Anchor>
            )
          }
          defaultValue={getDynamicDefaultValue(field)}
          label={field.displayName?.replaceAll('_', ' ')}
          onChange={value => {
            handleInputChange(field.fieldKey, value.currentTarget.value);
            errorCheck(field.fieldKey, value.currentTarget.value);
          }}
        />
      );
    });
  };

  const getDynamicInputsValue = (fieldKey: keyof Quest) => {
    if (!quest || !questTemplate || !questMetadataFields || isAddition) {
      return;
    }

    const field = questMetadataFields.find(x => x.fieldKey === fieldKey);
    if (!field) {
      return (
        <TextInput
          required
          description={`Action ${fieldKey}`}
          error={getError(fieldKey)}
          key={`dynamic-field-${fieldKey}`}
          defaultValue={decodeURIComponent(quest[fieldKey])}
          label={fieldKey.charAt(0).toUpperCase() + fieldKey.slice(1)}
          onChange={value => {
            setQuest({ ...quest, [fieldKey]: value.currentTarget.value });
            errorCheck(fieldKey, value.currentTarget.value);
          }}
        />
      );
    }
  };

  const isDateEditable = () => {
    const formattedStartTime = getUTCDate(quest.start_datetime);
    const today = getUTCDate(new Date());

    if (!isAddition && formattedStartTime < today) {
      return false;
    }
    return true;
  };

  const renderUI = () => {
    if (!questTemplate) {
      return <Text>There is not template selected</Text>;
    }
    if (!quest) {
      return <Text>There is a problem with your quest initialization</Text>;
    }
    const dynamicTitle = getDynamicInputsValue('title');
    const dynamicDescription = getDynamicInputsValue('description');
    const dynamicInputs = getDynamicInputs();
    return [
      selectSection(),
      dynamicTitle,
      dynamicDescription,
      dynamicInputs,
      <QuestCommonFields
        key={`quest-common-fields-${quest.quest_id}`}
        isAddition={isAddition}
        questId={quest.quest_id}
        points={quest.points}
        tier={tier}
        isDateEditable={isDateEditable()}
        isForgeStaff={props.isForgeStaff}
        startDatetime={new Date(quest.start_datetime)}
        enabled={quest.enabled}
        onUpsellClick={props.onUpsellClick}
        onPointsChanges={value => {
          setQuest({ ...quest, points: value });
          errorCheck('points', value);
        }}
        onStartDateChanges={value => {
          setQuest({ ...quest, start_datetime: formatDate(value) });
        }}
        onHiddenChanges={value => {
          setQuest({ ...quest, hidden: value });
        }}
        onEnabledChanges={value => {
          setQuest({ ...quest, enabled: value });
        }}
      />,
    ];
  };

  const selectSection = () => {
    if (isAddition)
      return (
        <NativeSelect
          styles={{ input: { color: 'black' } }}
          mb={formError ? '1rem' : ''}
          error={formError}
          errorProps={{ position: 'top' }}
          label={'Select the quest type'}
          data={questTemplates.map(x => x.displayName ?? x.template_name)}
          h={'3rem'}
          onChange={handleTemplateChange}
        />
      );
  };

  const handleTemplateChange = (
    element: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setQuestVariables([]);
    setElementInError([]);
    if (!isAddition) {
      return;
    }
    const template = questTemplates.find(x =>
      [x.displayName, x.template_name].includes(element.currentTarget.value)
    );
    if (!template) return;
    const questFields = buildQuestFields(template);
    setQuestTemplate(template);
    onTemplateChange(template);
    setQuestMetadataFields(questFields);
    handelMultiActive(template);
  };

  const handleSave = () => {
    onSave?.(quest, isAddition);
  };

  const handelMultiActive = (questTemplate: QuestTemplate) => {
    if (
      isAddition &&
      !questTemplate.multi_active &&
      props.getQuestNumber(questTemplate) > 1
    ) {
      setFormError('This type of quest does not allow multiple instance');
      setAllowAddition(false);
      return;
    }
    setFormError('');
    setAllowAddition(true);
  };

  return (
    <Flex direction="column" h={'100%'} gap={'2rem'} justify={'space-between'}>
      <Flex direction={'column'} gap={'1rem'} justify={'space-between'}>
        {renderUI()}
      </Flex>
      <Flex gap={'1rem'} justify={'end'}>
        <Button variant="outline" onClick={() => onCancel?.()}>
          Cancel
        </Button>
        <Button
          disabled={!allowAddition || elementsInError.length > 0}
          onClick={handleSave}
          loading={isLoading}
        >
          Save
        </Button>
      </Flex>
      {quest && (
        <Flex direction={'column'}>
          <Text>Preview</Text>
          <QuestElement
            icon={getIcon(quest.icon)}
            isPreview
            sortableQuest={{ quest: quest, id: 0 }}
          />
        </Flex>
      )}
    </Flex>
  );
};
