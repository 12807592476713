import { Flex, Skeleton, Title } from '@mantine/core';
import { FunctionComponent } from 'react';
import { TopKol } from '../../../data/kols';
import KolCard from '../KollCard/KolCard';

export interface TopKollsProps {
  isLoading?: boolean;
  kolls: TopKol[];
  onContactClick?: (topKol: TopKol) => void;
}

const TopKolls: FunctionComponent<TopKollsProps> = props => {
  const slides = props.kolls.map(item => (
    <KolCard
      topKol={item}
      onContactClick={topKoll => props.onContactClick?.(topKoll)}
    />
  ));

  const getContent = () => {
    if (props.isLoading) {
      return (
        <Flex
          direction="row"
          justify={'center'}
          w={'100%'}
          gap={'1rem'}
          wrap={'wrap'}
        >
          {Array.from({ length: 4 }).map(() => (
            <Skeleton h={'28.1rem'} w={'16.1rem'} />
          ))}
        </Flex>
      );
    }

    if (!props.kolls.length) {
      return (
        <center>
          <Title size={'1.5rem'}>No Top KOLs found</Title>
        </center>
      );
    }

    return (
      <center>
        <Flex
          w={'100%'}
          justify={'center'}
          align={'center'}
          gap={'1rem'}
          direction={'row'}
        >
          {slides}
        </Flex>
      </center>
    );
  };

  return (
    <Flex
      className="TopKolsSection"
      w={'100%'}
      direction={'column'}
      gap={'1rem'}
    >
      <Title w={'100%'} size={'1.8rem'}>
        Featured
      </Title>
      {getContent()}
    </Flex>
  );
};

export default TopKolls;
