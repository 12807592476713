import { Text, Tooltip, UnstyledButton } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';
import './NavbarLink.scss';

export interface NavLink {
  position?: 'bottom' | 'top';
  icon: ReactNode;
  label: string;
  slug: string;
  disable: boolean;
}

export interface NavbarLinkProps {
  link: NavLink;
  active?: boolean;
  expanded?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const NavbarLink: FunctionComponent<NavbarLinkProps> = props => {
  if (props.link.disable) return null;
  return (
    <Tooltip
      classNames={{ tooltip: 'Tooltip' }}
      transitionProps={{ duration: 300 }}
      label={props.link.label}
      hidden={props.expanded}
      position="right"
      offset={0}
    >
      <UnstyledButton
        disabled={props.disabled}
        className={`Link ${props.expanded ? '' : 'Collapsed'}`}
        onClick={() => props.onClick?.()}
        data-active={props.active ?? undefined}
      >
        {props.link.icon}
        {props.expanded && <Text>{props.link.label}</Text>}
      </UnstyledButton>
    </Tooltip>
  );
};
