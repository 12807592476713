import { Dispatch, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tiers, UpsellDialog } from '../components/UpsellDialog/UpsellDialog';
import { upSellService } from '../services/api-client/up-sell-service';
import { authentication } from '../services/authentication';
import { localStorageService } from '../services/local-storage-service';
import { MessageStatus } from '../services/notification-service';
import { useFromCheckList } from './use-from-checklist';
import { useNotification } from './use-notification';

export interface UseTierProps {
  isFreeTier: boolean;
  component: ReactNode;
  isClose: boolean;
  setIsClose: Dispatch<React.SetStateAction<boolean>>;
  setContactUsReason: Dispatch<React.SetStateAction<string>>;
}

export const useTier = (
  onCloseCallback?: () => void
): [useTierProps: UseTierProps] => {
  const navigate = useNavigate();
  const notification = useNotification();
  const [isFromCheckList, setIsFromChecklist] = useFromCheckList();
  const [contactUsReason, setContactUsReason] = useState('');
  const [isFreeTier, setIsFreeTier] = useState<boolean>(true);
  const [component, setComponent] = useState<ReactNode>(null);
  const [isClose, setIsClose] = useState<boolean>(true);
  const tier = localStorageService.getSelectedOrganization()?.tier;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const upsellComponent = (
    <UpsellDialog
      isLoading={isLoading}
      opened={!isClose}
      onClose={() => {
        setIsClose(true);
        onCloseCallback?.();
      }}
      onSubscribe={plan => handleSubscribe(plan)}
    />
  );

  const handelContactUs = async (plan: string) => {
    const user = authentication.getUser();
    if (!user || !user.email) {
      throw new Error('User not found');
    }
    const upSellData = {
      email: user.email,
      plan: plan,
      reason: contactUsReason,
      userName: user.nickname,
    };

    await upSellService.sendContactUsEmail(upSellData);
    notification.notify(
      MessageStatus.Success,
      'Success',
      'Request received, we will contact you soon'
    );
  };

  const handleSubscribe = async (plan: Tiers) => {
    try {
      setIsLoading(true);
      if (plan === Tiers.Pro) {
        const email = authentication.getUser()?.email;
        if (!email) {
          throw new Error('User not found');
        }
        const partnerSlug =
          localStorageService.getSelectedOrganization()?.partnerSlug;
        if (!partnerSlug) {
          throw new Error('Partner slug not found');
        }
        const getCheckoutLink = await upSellService.getProCheckoutLink(
          email,
          partnerSlug
        );
        window.location.href = getCheckoutLink;
        return;
      }
      await handelContactUs(plan);
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        'Error while subscribing:' + error.message
      );
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsClose(true);
      if (isFromCheckList) {
        setIsFromChecklist(false);
        navigate('/home');
      }
      onCloseCallback?.();
    }
  };

  useEffect(() => {
    if (tier === 'free') {
      setIsFreeTier(true);
    } else {
      setIsFreeTier(false);
    }
    setComponent(upsellComponent);
  }, [isClose, tier]);

  return [{ isFreeTier, component, isClose, setIsClose, setContactUsReason }];
};
