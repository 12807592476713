export interface Campaign {
  campaign_id: string;
  title: string;
  subtitle: string;
  genre: string;
  created_at: Date;
  start_datetime: Date;
  end_datetime: Date;
  extra_data: ExtraDataObject;
  description: string;
}

export interface Socials {
  discord: string;
  twitter: string;
  youtube: string;
  telegram: string;
  opensea: string;
  medium: string;
}

export interface ExtraDataObject {
  poster: string;
  banner: string;
  icon: string;
  social: Socials;
  website: string;
  platform: string;
  play_now: string;
  developer: string;
  game_mode: string;
  background: string;
  screenshots: string[];
  partner_name: string;
  release_date: string;
  week_quests_bg: string;
}

export interface CampaignDetail {
  campaignId: string;
  title: string;
  hidden: boolean;
}

export interface MetaDataObject {
  points_name: string;
  features_enabled: string[];
  features_metadata: FeaturesMetadataObject;
  account_linking_quests: AccountLinkingQuest[];
}

export interface FeaturesMetadataObject {
  hype_gauge?: HypeGauge;
  nft_multiplier?: NftMultiplier;
  social_multiplier?: SocialMultiplier;
}

export interface HypeGauge {
  icon_url: string;
  description: string;
}

export interface SocialMultiplier {
  values: MultiplayerValue[];
  description: string;
}

export interface NftMultiplier {
  values: MultiplayerValue[];
  img_url: string;
  description: string;
  wallets_supported: string[];
}

export interface MultiplayerValue {
  multiplier: number;
  description: string;
}

export interface AccountLinkingQuest {
  id: string;
  title: string;
  points: number;
  description: string;
}

export const testCampaign: Campaign = {
  campaign_id: '1',
  title: 'Campaign 1',
  subtitle: 'Subtitle 1',
  genre: 'Fantasy',
  created_at: new Date(),
  start_datetime: new Date(),
  end_datetime: new Date(),
  extra_data: {
    poster: 'https://via.placeholder.com/1920x1080',
    banner: 'https://via.placeholder.com/1920x1080',
    icon: 'https://via.placeholder.com/150',
    social: {
      discord: 'https://discord.com/invite/chaneel',
      twitter: 'https://twitter.com',
      youtube: 'https://youtube.com',
      medium: 'https://medium.com',
      opensea: 'https://opensea.io',
      telegram: 'https://telegram.org',
    },
    website: 'https://example.com',
    platform: 'Windows',
    play_now: 'https://play.example.com',
    developer: 'Developer',
    game_mode: 'Single Player',
    background: 'https://via.placeholder.com/1920x1080',
    screenshots: ['https://via.placeholder.com/1920x1080'],
    partner_name: 'Partner',
    release_date: '2022-01-01',
    week_quests_bg: 'https://via.placeholder.com/1920x1080',
  },
  description: 'Description 1',
};

const excludedElements = ['web_hook_url', 'partner_internal_name', 'banner'];

const getErrorsInExtraData = (campaign: Campaign) => {
  const errors: string[] = [];
  Object.keys(campaign.extra_data).forEach(extraDataKey => {
    if (
      campaign.extra_data[extraDataKey as keyof Campaign['extra_data']] === ''
    ) {
      errors.push(extraDataKey);
    }
  });
  return errors;
};
export const getErrors = (campaign: Campaign) => {
  const errors: string[] = [];
  Object.keys(campaign).forEach(key => {
    if (key === 'extra_data') {
      getErrorsInExtraData(campaign).forEach(element => {
        errors.push(element);
      });
    }
    campaign[key as keyof Campaign] === '' && errors.push(key);
  });
  return errors.filter(x => !excludedElements.includes(x));
};
