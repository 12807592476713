import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Button,
  Card,
  CopyButton,
  Flex,
  Image,
  rem,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { FunctionComponent } from 'react';
import { LocalImages } from '../../assets/images';
import { configuration } from '../../configuration';
import { TelegramChannel } from '../../data/telegram-channel';
import { ForgeTable, Row } from '../Table/ForgeTable';
import TelegramBotModal from './components/TelegramBotModal';

export interface TelegramBotChannelListProps {
  isLoading?: boolean;
  telegramChannels?: TelegramChannel[];
  isAddingBot: boolean;
  onSave?: (code: string) => void;
}

const TelegramBotChannelList: FunctionComponent<
  TelegramBotChannelListProps
> = props => {
  const { isLoading, telegramChannels, onSave, isAddingBot } = props;
  const headers = [
    '#',
    'Chat Id',
    'Title',
    'Type',
    'Admin',
    'Bot status',
    'Registered on',
    'Users joined by link',
    'Users in chat',
  ];

  const isBotPresent = (isPresent: boolean) => {
    let badgeValue;
    if (isPresent) {
      badgeValue = <Badge color="green">P</Badge>;
    } else {
      badgeValue = <Badge color="orange">NP</Badge>;
    }
    return (
      <Tooltip
        label={isPresent ? 'Bot is present (P)' : 'Bot is not present (NP)'}
      >
        {badgeValue}
      </Tooltip>
    );
  };

  const getDate = (date: Date) => {
    return `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`;
  };

  const getEmptyState = () => {
    return (
      <Flex
        direction={'column'}
        align={'center'}
        gap={'1rem'}
        justify={'center'}
      >
        <Image
          src={LocalImages.telegram}
          maw={'13.375rem'}
          alt={'Empty state'}
        />
        <Card p={'2rem'} maw={'25rem'} withBorder={true} radius={'1rem'}>
          <Text ta="center">
            With our bot you can create actions for your users to join your
            groups or channels, and also create In-Game actions for your game
          </Text>
        </Card>
        <Flex direction={'row'} gap={'1rem'}>
          <Anchor href={configuration.telegramBot.url}>
            <Button>Get Telegram Bot</Button>
          </Anchor>
          <TelegramBotModal
            onSave={code => {
              onSave?.(code);
            }}
            isAddingBot={isAddingBot}
          />
        </Flex>
      </Flex>
    );
  };

  const getContent = () => {
    if ((!telegramChannels || telegramChannels.length === 0) && !isLoading) {
      return getEmptyState();
    }
    return (
      <Box w={'100%'}>
        <Title pb={'1rem'} order={2}>
          Chats and groups
        </Title>
        <ForgeTable
          skeletonAmount={6}
          striped
          headers={headers}
          isLoading={isLoading}
          rows={getRows()}
        />
      </Box>
    );
  };

  const getIdElement = (id: string) => {
    return (
      <Flex align={'center'}>
        {id}
        <CopyButton value={id} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? 'Copied' : 'Copy'}
              withArrow
              position="right"
            >
              <ActionIcon
                color={copied ? 'teal' : 'gray'}
                variant="subtle"
                onClick={copy}
              >
                {copied ? (
                  <IconCheck style={{ width: rem(16) }} />
                ) : (
                  <IconCopy style={{ width: rem(16) }} />
                )}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </Flex>
    );
  };

  const getRows = (): Row[] => {
    if (!telegramChannels) return [];
    return telegramChannels.map((channel, index) => {
      return {
        elements: [
          index + 1,
          getIdElement(channel.id),
          channel.title,
          channel.type.charAt(0).toUpperCase() + channel.type.slice(1),
          channel.partnerUserFullName,
          isBotPresent(channel.isBotPresent),
          getDate(new Date(channel.createdAt)),
          channel.linkUsage?.toString(),
          channel.usersOnChat?.toString(),
        ],
      };
    });
  };

  return (
    <Flex
      direction={'column'}
      align={'center'}
      p={'1rem'}
      w={'100%'}
      gap={'1rem'}
    >
      {getContent()}
    </Flex>
  );
};

export default TelegramBotChannelList;
