import { configuration } from '../../configuration';
import { CollaboratorInvite } from '../../data/collaborator-invite';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class InvitationService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'invitation');
  }

  public async getCollaboratorInvites(
    partnerSlug: string
  ): Promise<CollaboratorInvite[]> {
    const url = `${this.url()}partner/${partnerSlug}`;
    return await this.apiClient.get<CollaboratorInvite[]>(url);
  }

  public async addNewInvite(email: string, partnerSlug: string) {
    const url = `${this.url()}${partnerSlug}/${email}`;
    await this.apiClient.post(url, {});
  }

  public async setInvitationActive(email: string) {
    await this.apiClient.put(`${this.url()}activate/${email}`, {});
  }
}
export const invitationService = new InvitationService(
  new BearerTokenApiClient(configuration.backendApi.url)
);
