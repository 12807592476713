import { Divider, Flex, Skeleton, Title } from '@mantine/core';
import ChargesAccordion from '../../components/Billing/ChargesAccordion/ChargesAccordion';
import { StripeCharge } from '../../data/stripe-transaction-data';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { upSellService } from '../../services/api-client/up-sell-service';
import { localStorageService } from '../../services/local-storage-service';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const organization =
    localStorageService.getSelectedOrganization()?.partnerSlug;
  const [charges, loadingCharges] = useDataFetcher<StripeCharge[]>({
    serviceCall() {
      if (!organization) {
        throw new Error('Invalid partner');
      }
      return upSellService.getLoyaltyProgramTransactions(organization);
    },
  });

  if (loadingCharges) {
    return (
      <Flex
        w={'100%'}
        p={'5rem'}
        gap={'xl'}
        direction={'column'}
        justify={'center'}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton key={index} height={'5rem'} width={'100%'} />
        ))}
      </Flex>
    );
  }

  if (!charges) {
    return (
      <Flex w={'100%'} p={'5rem'} justify={'center'}>
        No transactions
      </Flex>
    );
  }

  const getChargesAccordion = () => {
    if (charges.length === 0) {
      return (
        <Flex w={'100%'} p={'5rem'} justify={'center'}>
          No transactions
        </Flex>
      );
    }
    return <ChargesAccordion charges={charges} />;
  };

  return (
    <Flex
      w={'100%'}
      direction={'column'}
      gap={'1rem'}
      p={'3rem'}
      justify={'center'}
    >
      <Title size={'1.5rem'}>Transactions</Title>
      <Divider w={'100%'} />
      {getChargesAccordion()}
    </Flex>
  );
};

export const BillingPage = withAuthProtection(Page);
