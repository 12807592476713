import { Anchor, Divider, Flex, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import TelegramBotModal from '../../components/TelegramBotChannelsList/components/TelegramBotModal';
import TelegramBotChannelsList from '../../components/TelegramBotChannelsList/TelegramBotChannelList';
import { TelegramChannel } from '../../data/telegram-channel';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useNotification } from '../../hooks/use-notification';
import { telegramBotService } from '../../services/api-client/telegram-bot-sercice';
import { localStorageService } from '../../services/local-storage-service';
import { MessageStatus } from '../../services/notification-service';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [codeFromUrl] = useState<string | null>(urlParams.get('addbot'));
  const notificationService = useNotification();
  const partNerSlug =
    localStorageService.getSelectedOrganization()?.partnerSlug;
  const [refresh, setRefresh] = useState(false);
  const [isAddingBot, setIsAddingBot] = useState(false);
  const [telegramChannels, isLoading] = useDataFetcher<TelegramChannel[]>({
    serviceCall() {
      if (partNerSlug) {
        return telegramBotService.getChannels(partNerSlug);
      }
    },
    dependencies: [partNerSlug, refresh],
    conditionForExecution: !!partNerSlug,
  });

  useEffect(() => {
    const sendConfirmationCode = async (code: string | null) => {
      if (codeFromUrl) {
        await handleConfirmationCode(codeFromUrl);
      }
    };
    sendConfirmationCode(codeFromUrl);
  }, [codeFromUrl]);

  const handleConfirmationCode = async (code: string) => {
    try {
      if (partNerSlug) {
        setIsAddingBot(true);
        await telegramBotService.setConfirmationCode(code, partNerSlug);
        setIsAddingBot(false);
        setRefresh(!refresh);
      }
    } catch (error: any) {
      notificationService.notify(
        MessageStatus.Error,
        'Confirmation code',
        error.response.data.message ?? 'There was a problem sending the code'
      );
    } finally {
      setIsAddingBot(false);
    }
  };

  const getInstallationComponent = () => {
    if (telegramChannels && telegramChannels.length !== 0) {
      return (
        <TelegramBotModal
          codeFromUrl={codeFromUrl}
          onSave={code => {
            handleConfirmationCode(code);
          }}
          isAddingBot={isAddingBot}
        />
      );
    }
  };
  return (
    <Flex w={'100%'} direction={'column'} align={'center'}>
      <Flex
        w={'100%'}
        direction={'column'}
        pos={'sticky'}
        top={0}
        style={{ zIndex: 200 }}
        bg={'#f9fafb'}
      >
        <Flex
          direction={'row'}
          w={'100%'}
          justify={'flex-end'}
          align={'center'}
          pr={'xs'}
          pl={'xs'}
          p={'xs'}
          gap={'0.5rem'}
        >
          {getInstallationComponent()}

          <Tooltip label="Navigate to documentation" content="Help">
            <Anchor href="https://docs.forge.gg/telegram-bot/introduction">
              <BiHelpCircle size={30} />
            </Anchor>
          </Tooltip>
        </Flex>

        <Divider />
      </Flex>
      <TelegramBotChannelsList
        isLoading={isLoading}
        telegramChannels={telegramChannels}
        onSave={code => {
          handleConfirmationCode(code);
        }}
        isAddingBot={isAddingBot}
      />
    </Flex>
  );
};

export const TelegramBotPage = withAuthProtection(Page);
