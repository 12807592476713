import { Flex, Text, Title } from '@mantine/core';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  return (
    <Flex w={'100%'} mt={'xl'} direction={'column'} align={'center'}>
      <Title>Discord Page</Title>
      <Text c={'gray'}>Under Construction</Text>
    </Flex>
  );
};

export const DiscordPage = withAuthProtection(Page);
