export const configuration = {
  cedApiKey: "8fff83109e5c87daa418dae55783ef62e4e11b3e55333e8b43826d7f3af3a6f239ae45b48897409abffa3228615f488a5378",
  environment: "production",
  version: "1.0.207",
  automation: {
    automationEmails: "forge-staff@uh4yskxo.mailosaur.net",
  },
  backendApi: {
    url: "https://api.points-dashboard-prod.forge.gg",
    avatarUrl: "https://forge.gg/avatar/",
  },
  forgeApi: {
    baseUrl: "https://forge.gg/api",
    selfServiceUrl: "https://forge.gg/self-serve-api",
  },
  frigade: {
    apiKey: "api_public_QZQP7HYrwRTKx1xvBM2ecmZ77E9RC1led8q8mRFZw2dhpsN4WLkjd7Cm2hkDs0MR",
  },
  posthog: {
    apiHost: "https://us.i.posthog.com",
    apiKey: "phc_rP7FRwsHr74LsjpCXezmmuXmTcZMmuRqo1wzyEdwnMT",
  },
  stripe: {
    apiKey: "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",
    productId: "prod_QSBKxAu56tEuvJ",
    proPaymentLinkId: "dR65ohd775R10Ba146",
    proPriceId: "price_1Pe68V00qO7QSexTItQSdwSG",
  },
  telegramBot: {
    url: "https://t.me/forge_actions_bot",
  },
  userRegistry: {
    domain: "auth.dash.forge.gg",
    clientId: "sbgL7kry0RuKD058LkXb81YaNfyFHvwv",
    audience: "https://dash.forge.gg/api",
  },
};