export interface MetadataDirectedFields {
  campaign_id: string;
  //Campaign Mechanics
  quest_number: number;
  partner_name: string;
  //Website
  website_description: string;
  website_link: string;
  game_platform: string;

  //telegram
  telegram_handle: string;

  //YouTube
  youtube_handle: string;
  // youtube_account_name: string;

  //twitter
  twitter_handle: string;
  twitter_user_id: string;

  //discord
  // channel_name: string;
  discord_handle: string;
}

export interface NotUserVisibleFields {
  campaign_id: string;
  quest_number: number;
}

export const defaultNotUserVisibleFields: NotUserVisibleFields = {
  campaign_id: '',
  quest_number: 1,
};

export const defaultMetadataDirectedFields: MetadataDirectedFields = {
  campaign_id: '',
  quest_number: 1,
  website_description: '',
  telegram_handle: '',
  twitter_handle: '',
  website_link: '',
  partner_name: '',
  discord_handle: '',
  youtube_handle: '',
  game_platform: '',
  twitter_user_id: '',
};

export const notInheritedFromGamePage = ['game_platform'];
