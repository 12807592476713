import { Paper, PaperProps } from '@mantine/core';
import { FunctionComponent } from 'react';

export interface CardProps extends PaperProps {
  children?: React.ReactNode;
}

export const Card: FunctionComponent<CardProps> = props => {
  return (
    <Paper
      w={'100%'}
      h={'100%'}
      bg={'#6085A7'}
      radius={'1.25rem'}
      p={'1rem'}
      {...props}
    >
      {props.children}
    </Paper>
  );
};
