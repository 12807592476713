import {
  Avatar,
  Button,
  Card,
  CardSection,
  Flex,
  Image,
  Text,
  Title,
} from '@mantine/core';
import { FunctionComponent } from 'react';
import { FaXTwitter } from 'react-icons/fa6';
import { TopKol } from '../../../data/kols';

export interface KolCardProps {
  topKol?: TopKol;
  onContactClick?: (topKol: TopKol) => void;
}

const KolCard: FunctionComponent<KolCardProps> = props => {
  const { topKol } = props;
  const getCardContent = () => {
    return (
      <Flex
        direction="column"
        align={'center'}
        gap={'1rem'}
        w={'100%'}
        h={'100%'}
        bg={'white'}
        mb={'1rem'}
      >
        <Flex
          mt={'1rem'}
          className="UserNameSection"
          align={'center'}
          direction={'column'}
          gap={'0.2rem'}
        >
          <Title size={'sm'}>{topKol?.userName ?? '--'}</Title>
          <Text size="xs">{topKol?.userTag ?? '--'}</Text>
        </Flex>
        <Flex align={'center'} gap={'0.3rem'} className="FollowersSection">
          <FaXTwitter />
          <Text size="sm">{topKol?.numberOfFollowers ?? '--'} followers</Text>
        </Flex>
        <Button
          className="FollowButton"
          disabled={!topKol || !topKol?.userTag}
          onClick={() => topKol && props.onContactClick?.(topKol)}
        >
          Contact
        </Button>
        <Text fw={700}>{topKol?.price ?? '--'}</Text>
      </Flex>
    );
  };

  const getImage = () => {
    if (topKol?.profileImage) {
      return (
        <Image
          radius={'100%'}
          w={'8rem'}
          src={topKol.profileImage}
          alt={topKol.userName}
        />
      );
    }
    return <Avatar mih={'15.1rem'} radius={0} w={'100%'} />;
  };
  return (
    <Card
      className="KollCard"
      mah={'28.1rem'}
      maw={'16.1rem'}
      mih={'28.1rem'}
      miw={'16.1rem'}
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
    >
      <CardSection p={'3rem'} className="ImageSection">
        {getImage()}
      </CardSection>
      <CardSection className="ContentSection">{getCardContent()}</CardSection>
    </Card>
  );
};
export default KolCard;
