import { CampaignDetail } from '../data/campaign';
import { LoginData } from '../data/login-data';
import { Organization } from '../data/organization';

enum localStorageKey {
  LoginData = 'loginData',
  SelectedCampaign = 'selectedCampaign',
  Campaigns = 'campaigns',
  PartnerMembership = 'partnerMembership',
  Organizations = 'organizations',
  SelectedOrganization = 'selectedOrganization',
  InvalidToken = 'invalidToken',
  ActualPage = 'actualPage',
  ActionGroupBy = 'actionGroupBy',
  CloneOption = 'cloneOption',
}

class LocalStorageService {
  public clearAppData() {
    localStorage.clear();
  }

  public setLoginData(loginData: LoginData) {
    localStorage.setItem(localStorageKey.LoginData, JSON.stringify(loginData));
  }

  public getLoginData(): LoginData | null {
    const data = localStorage.getItem(localStorageKey.LoginData);
    return this._parseData<LoginData>(data);
  }

  public getSelectedCampaign(): CampaignDetail | null {
    const data = localStorage.getItem(localStorageKey.SelectedCampaign);
    if (!data) {
      return null;
    }
    return this._parseData<CampaignDetail>(data);
  }

  public setActualPage(pathname: string) {
    localStorage.setItem(localStorageKey.ActualPage, pathname);
  }

  public getActualPage() {
    const data = localStorage.getItem(localStorageKey.ActualPage);
    return data;
  }

  public setSelectedCampaign(value: CampaignDetail | null) {
    localStorage.setItem(
      localStorageKey.SelectedCampaign,
      JSON.stringify(value) || ''
    );
  }

  public getCampaigns() {
    const data = localStorage.getItem(localStorageKey.Campaigns);
    if (!data) {
      return [];
    }
    return this._parseData<CampaignDetail[]>(data);
  }

  public setCampaigns(value: CampaignDetail[] | null) {
    localStorage.setItem(
      localStorageKey.Campaigns,
      JSON.stringify(value) || ''
    );
  }

  public getOrganizations() {
    const data = localStorage.getItem(localStorageKey.Organizations);
    return this._parseData<Organization[]>(data);
  }

  public setOrganizations(value: Organization[]) {
    localStorage.setItem(
      localStorageKey.Organizations,
      JSON.stringify(value) || ''
    );
  }

  public setSelectedOrganization(value: Organization) {
    localStorage.setItem(
      localStorageKey.SelectedOrganization,
      JSON.stringify(value) || ''
    );
  }

  public getSelectedOrganization(): Organization | null {
    const data = localStorage.getItem(localStorageKey.SelectedOrganization);
    if (!data) {
      return null;
    }
    return this._parseData<Organization>(data);
  }

  public getActionGroupBy(): string | null {
    return localStorage.getItem(localStorageKey.ActionGroupBy);
  }

  public setActionGroupBy(groupBy: string | null) {
    localStorage.setItem(localStorageKey.ActionGroupBy, groupBy || '');
  }

  public getCloneOption(): boolean {
    const data = localStorage.getItem(localStorageKey.CloneOption);
    return data === 'true';
  }

  public setCloneOption(value: boolean) {
    localStorage.setItem(localStorageKey.CloneOption, value.toString());
  }

  private _parseData<T>(data: any): T {
    return data !== null ? JSON.parse(data) : null;
  }
}

export const localStorageService = new LocalStorageService();
