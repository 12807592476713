export const genresData = [
  'Action',
  'Action RPG',
  'Action-adventure',
  'Adventure',
  'Battle Royale',
  'Casual',
  'Fighting',
  'First-person shooter',
  'MMOR',
  'Party',
  'Platformer',
  'Puzzle',
  'Racing',
  'Real-time strategy',
  'RPG',
  'Shooter',
  'Simulation',
  'Sports',
  'Stealth',
  'Strategy game',
  'Survival',
  'Tactical role-playing',
];

export const getSeparateElements = (elements: string | undefined) => {
  if (!elements || elements.length === 0) {
    return [];
  }
  return elements.replaceAll(', ', ',').split(',') ?? [];
};
