import { SkeletonCircle } from '@chakra-ui/react';
import { Badge, Flex, Title } from '@mantine/core';
import { FunctionComponent } from 'react';

export interface ActiveUsersCountProps {
  userCount: number;
  isLoading?: boolean;
}

const ActiveUsersCount: FunctionComponent<ActiveUsersCountProps> = props => {
  return (
    <Flex
      className="ActiveUsersSection"
      w={'100%'}
      direction={'row'}
      gap={'0.5rem'}
      align={'center'}
      justify={'flex-end'}
      p={'1rem'}
    >
      <Title size={'md'}>Active Users:</Title>
      {!props.isLoading ? (
        <Badge>{props.userCount}</Badge>
      ) : (
        <SkeletonCircle size="5" />
      )}
    </Flex>
  );
};

export default ActiveUsersCount;
