import { Avatar, Flex, Text } from '@mantine/core';
import { FunctionComponent } from 'react';
import { Kol } from '../../../data/kols';

const KolName: FunctionComponent<Kol> = props => {
  const getProfileImage = () => {
    if (props.profileImage) {
      return <Avatar src={props.profileImage} size={40} />;
    }
    return <Avatar size={40} />;
  };
  return (
    <Flex className="KolNameSection" align={'center'} gap={'0.3rem'}>
      {getProfileImage()}
      <Flex className="KolNameTextSection" direction={'column'} gap={'0.5rem'}>
        <Text size={'1rem'} fw={700}>
          {props.userName ?? '--'}
        </Text>
        <Text size={'0.75rem'}>{props.userTag ?? '--'}</Text>
      </Flex>
    </Flex>
  );
};

export default KolName;
