import {
  Anchor,
  Button,
  Card,
  Flex,
  Group,
  Pagination,
  ScrollAreaAutosize,
  Title,
} from '@mantine/core';

import { FunctionComponent, ReactNode } from 'react';
import { FaFileExport } from 'react-icons/fa';
import { GiHillConquest } from 'react-icons/gi';
import { ImUserCheck } from 'react-icons/im';
import { TbDeviceHeartMonitorFilled, TbShieldQuestion } from 'react-icons/tb';
import {
  ClosureReport,
  CompletedQuestReport,
  PositivePointUserReport,
  Report,
  ReportIdentifier,
  UsersWithCompletedQuestsCountReport,
} from '../../../data/metrics-data';
import { ForgeTable, Row } from '../../Table/ForgeTable';

export interface MetricTableCardProps {
  onUserNameClick?: (userName: string) => void;
  activePage: number;
  title: string;
  metricType: ReportIdentifier;
  data?: Report[];
  isLoading?: boolean;
  onPageChange?: (page: number) => void;
  totalPageCount: number;
  onExportData?: () => void;
  reportLoading?: boolean;
}

export const MetricTableCard: FunctionComponent<
  MetricTableCardProps
> = props => {
  const getRows = (): Row[] => {
    if (!props.data) {
      return [];
    }
    return props.data?.map((data, index) => {
      return (
        metricOutputMap
          .get(props.metricType)
          ?.row(index, data, userName => props.onUserNameClick?.(userName)) ?? {
          elements: [],
        }
      );
    });
  };

  return (
    <Flex direction={'column'} w={'100%'} align={'end'} gap={'lg'}>
      <Card
        shadow="sm"
        w={'100%'}
        padding="lg"
        radius="md"
        withBorder
        bg={'#eff4f6'}
      >
        <Group>
          <Flex
            w={'100%'}
            direction={'row'}
            align={'end'}
            justify={'space-between'}
          >
            <Flex gap={'0.5rem'} align={'center'} justify={'center'}>
              {metricOutputMap.get(props.metricType)?.icon}
              <Title size={'md'}>{props.title}</Title>
            </Flex>
            <Button
              loading={props.reportLoading}
              disabled={!props.data || props.isLoading}
              leftSection={<FaFileExport />}
              onClick={() => {
                props.onExportData?.();
              }}
            >
              Export
            </Button>
          </Flex>
        </Group>
        <Group justify="space-between" mt="md" mb="xs"></Group>
        <Group>
          <ScrollAreaAutosize w={'100%'} h={'35rem'}>
            <ForgeTable
              skeletonAmount={6}
              isLoading={props.isLoading}
              headers={metricOutputMap.get(props.metricType)?.headers() ?? []}
              rows={getRows()}
            />
          </ScrollAreaAutosize>
        </Group>
        {!props.isLoading && (
          <Flex pt={'0.5rem'} w={'100%'} justify={'end'} direction={'row'}>
            <Pagination
              total={props.totalPageCount}
              onChange={page => {
                props.onPageChange?.(page);
              }}
              value={props.activePage}
            />
          </Flex>
        )}
      </Card>
    </Flex>
  );
};

export const metricOutputMap = new Map<
  ReportIdentifier,
  {
    row: (
      index: number,
      data: Report,
      onClick?: (userName: string) => void
    ) => Row;
    headers: () => string[];
    icon: ReactNode;
  }
>([
  [
    ReportIdentifier.CompletedQuestsWithUserCount,
    {
      row: (index: number, data: Report) =>
        mapCompletedQuestsWithUserCount(index, data as CompletedQuestReport),
      headers: () => ['#', 'Quest', 'User count'],
      icon: <GiHillConquest size={40} />,
    },
  ],
  [
    ReportIdentifier.UsersWithPositivePoints,
    {
      row: (index: number, data: Report) =>
        mapUsersWithPositivePoints(index, data as PositivePointUserReport),
      headers: () => ['#', 'User Id', 'User Name', 'Points'],
      icon: <ImUserCheck size={40} />,
    },
  ],
  [
    ReportIdentifier.UsersWithCompletedQuestsCount,
    {
      row: (
        index: number,
        data: Report,
        onClick?: (userName: string) => void
      ) =>
        mapUsersWithCompletedQuestsCount(
          index,
          data as UsersWithCompletedQuestsCountReport,
          (userName: string) => onClick?.(userName)
        ),
      headers: () => ['#', 'User Id', 'User Name', 'Completed Quests'],
      icon: <TbShieldQuestion size={40} />,
    },
  ],
  [
    ReportIdentifier.ClosureReport,
    {
      row: (
        index: number,
        data: Report,
        onClick?: (userName: string) => void
      ) =>
        mapClosureReport(index, data as ClosureReport, (userName: string) =>
          onClick?.(userName)
        ),
      headers: () => [
        '#',
        'Name',
        'Points',
        'Service Name',
        'Blockchain',
        'User Name',
        'MemberId',
        'Wallet',
      ],
      icon: <TbDeviceHeartMonitorFilled size={40} />,
    },
  ],
]);

const mapClosureReport = (
  index: number,
  data: ClosureReport,
  onClick: (userName: string) => void
): Row => {
  return {
    elements: [
      index + 1,
      <Anchor style={{ color: 'blue' }} onClick={() => onClick(data.name)}>
        {data.name}
      </Anchor>,
      data.points,
      data.serviceName,
      data.blockchain,
      data.username,
      data.memberId,
      data.wallet,
    ],
  } as Row;
};

const mapUsersWithCompletedQuestsCount = (
  index: number,
  data: UsersWithCompletedQuestsCountReport,
  onClick: (userName: string) => void
): Row => {
  return {
    elements: [
      index + 1,
      data.userId,
      <Anchor style={{ color: 'blue' }} onClick={() => onClick(data.userName)}>
        {data.userName}
      </Anchor>,
      data.accomplishmentCount,
    ],
  } as Row;
};

const mapUsersWithPositivePoints = (
  index: number,
  data: PositivePointUserReport
): Row => {
  return {
    elements: [index + 1, data.userId, data.userName, data.finalPoints],
  } as Row;
};

const mapCompletedQuestsWithUserCount = (
  index: number,
  data: CompletedQuestReport
): Row => {
  return {
    elements: [index + 1, data.quest, data.count],
  } as Row;
};
