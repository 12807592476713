import { Flex } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VerticalCampaign } from '../../components/Campaign/VerticalCampaign/VerticalCampaign';
import { PublishDialog } from '../../components/PublishDialog/PublishDialog';
import { CampaignAssetModification } from '../../data/campaign-assets';
import { Campaign } from '../../data/campaign.js';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useFromCheckList } from '../../hooks/use-from-checklist';
import { useNotification } from '../../hooks/use-notification';
import { campaignService } from '../../services/api-client/campaign-service';
import { authentication } from '../../services/authentication';
import { localStorageService } from '../../services/local-storage-service';
import { MessageStatus } from '../../services/notification-service';
import { withAuthProtection } from '../../services/protect-route-element';
import './CampaignCustomizationPage.scss';
const Page = () => {
  const navigate = useNavigate();
  const [isFromCheckList, setIsFromChecklist] = useFromCheckList();
  const notification = useNotification();
  const selectedCampaign = localStorageService.getSelectedCampaign();
  const [savingEditedCampaign, setSavingEditedCampaign] =
    useState<boolean>(false);
  const [campaignsTrigger, setCampaignsTrigger] = useState<boolean>(false);
  const [isPublishOpened, setIsPublishOpened] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const partnerSlug =
    localStorageService.getSelectedOrganization()?.partnerSlug;

  const [campaignDetail] = useDataFetcher({
    serviceCall() {
      if (!partnerSlug) {
        throw new Error('Invalid partner');
      }
      return campaignService.getAllCampaignDetails(partnerSlug);
    },
    dependencies: [campaignsTrigger],
    onError(error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error fetching campaign details: ${error.message}`
      );
    },
  });

  const [campaigns, isLoadingCampaigns, setCampaigns] = useDataFetcher<
    Campaign[]
  >({
    serviceCall() {
      if (!partnerSlug) {
        throw new Error('Invalid partner');
      }
      return campaignService.getAll(partnerSlug);
    },
    dependencies: [campaignsTrigger],
    onError(error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error fetching campaign list: ${error.message}`
      );
    },
  });

  const handleSaveEditedCampaign = async (
    editedCampaign: Campaign,
    newAssets: CampaignAssetModification
  ) => {
    if (!campaigns) {
      return;
    }
    try {
      setSavingEditedCampaign(true);
      const index = campaigns?.findIndex(
        c => c.campaign_id === editedCampaign.campaign_id
      );
      if (index !== -1) {
        await campaignService.edit(editedCampaign);
        const temCampaigns = [...campaigns];
        temCampaigns[index] = editedCampaign;
        setCampaigns(temCampaigns);
        if (!selectedCampaign) {
          authentication.logout('invalid_user');
          return;
        }
        localStorageService.setSelectedCampaign({
          ...selectedCampaign,
          title: editedCampaign.title,
        });
      }
      const assetsPromises = [
        campaignService.saveScreenShotsAssets(
          newAssets,
          editedCampaign.campaign_id
        ),
        campaignService.saveIconAsset(newAssets, editedCampaign.campaign_id),
        campaignService.saveBackgroundAsset(
          newAssets,
          editedCampaign.campaign_id
        ),
        campaignService.savePoster(newAssets, editedCampaign.campaign_id),
        campaignService.saveBannerAsset(newAssets, editedCampaign.campaign_id),
        campaignService.saveWeekQuestsBgAsset(
          newAssets,
          editedCampaign.campaign_id
        ),
      ];
      await Promise.all(assetsPromises);
      notification.notify(
        MessageStatus.Success,
        'Success',
        'Campaign edited successfully'
      );
      setCampaignsTrigger(!campaignsTrigger);
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Game Error',
        error.response.data.message ?? 'Error editing the campaign'
      );
    } finally {
      setSavingEditedCampaign(false);
      if (isFromCheckList) {
        setIsFromChecklist(false);
        navigate('/home');
      }
    }
  };

  const handleOnCampaignPublished = async (published: boolean) => {
    if (!partnerSlug) {
      return;
    }
    try {
      setIsPublishLoading(true);
      await campaignService.publishCampaign(partnerSlug, published);
      const temSelectedCampaign = localStorageService.getSelectedCampaign();
      if (temSelectedCampaign) {
        temSelectedCampaign.hidden = !published;
        localStorageService.setSelectedCampaign(temSelectedCampaign);
      }
      window.location.reload();
    } catch (error) {
      setIsPublishLoading(false);
      notification.notify(
        MessageStatus.Error,
        'Error',
        'Error publishing the campaign'
      );
    }
  };

  if (!campaignDetail) {
    return null;
  }

  return (
    <Flex className="CampaignCustomizationWrapper" w={'100%'}>
      <VerticalCampaign
        isCampaignPublished={!campaignDetail[0]?.hidden}
        onCampaignPublished={hidden => {
          if (hidden) {
            setIsPublishOpened(true);
          } else {
            handleOnCampaignPublished(false);
          }
        }}
        campaign={campaigns?.[0]}
        isLoading={isLoadingCampaigns}
        savingNewCampaign={false}
        savingEditedCampaign={savingEditedCampaign}
        removingCampaign={false}
        onEditCampaignSave={(
          campaign: Campaign,
          newAssets: CampaignAssetModification
        ) => {
          return handleSaveEditedCampaign(campaign, newAssets);
        }}
      ></VerticalCampaign>
      <PublishDialog
        opened={isPublishOpened}
        isLoading={isPublishLoading}
        onPublish={() => handleOnCampaignPublished(true)}
        onClose={() => setIsPublishOpened(false)}
      />
    </Flex>
  );
};
export const CampaignCustomizationPage = withAuthProtection(Page);
