import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ActionIcon, Flex, Text, Title } from '@mantine/core';
import parse from 'html-react-parser';
import { FunctionComponent, ReactNode } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { Quest, SortableQuest } from '../../../data/quest';
import './QuestBasicElement.scss';

export interface QuestElementsProps {
  isPreview?: boolean;
  sortableQuest: SortableQuest;
  onEditClick?: (quest: Quest) => void;
  icon: ReactNode;
}

const QuestElements: FunctionComponent<QuestElementsProps> = props => {
  const { quest } = props.sortableQuest;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.sortableQuest.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Flex
      className="QuestElementsWrapper"
      miw={'28.75rem'}
      mih={'5.0625rem'}
      maw={'48.75rem'}
      pos={'relative'}
      style={style}
      opacity={quest.enabled ? 1 : 0.5}
    >
      <Flex
        {...listeners}
        {...attributes}
        ref={setNodeRef}
        justify={'space-between'}
        align={'center'}
        w={'100%'}
      >
        <Flex gap={'1rem'} align={'center'}>
          {props.icon}
          <Flex direction={'column'} gap={'0.25rem'}>
            <Title className="QuestTitle">{quest.title}</Title>
            <Text className="QuestDescription">
              {parse(
                quest.description?.replaceAll(
                  '_target="blank"',
                  'target="_blank"'
                ) ?? ''
              )}
            </Text>
          </Flex>
        </Flex>
        <Text className="QuestPoints LinearBlueText">{quest.points} pts</Text>
      </Flex>

      <Text
        className="DisabledText LinearBlueText"
        bottom={'5px'}
        right={'1rem'}
        pos={'absolute'}
        size={'xs'}
      >
        {quest.enabled ? 'enabled' : 'disabled'}
      </Text>

      {!props.isPreview && (
        <ActionIcon
          z-index={600}
          disabled={!quest.template}
          className="EditIcon"
          top={'5px'}
          right={'1rem'}
          pos={'absolute'}
          size="sm"
          radius="sm"
          variant="light"
          color="blue"
          onClick={() => props.onEditClick?.(quest)}
        >
          <MdModeEdit />
        </ActionIcon>
      )}
    </Flex>
  );
};

export default QuestElements;
