import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlags } from '../data/feature-flags';
import { authentication } from '../services/authentication';

const user = authentication.getUser();

const staffCheck =
  user?.email?.endsWith('@forge.gg') || user?.email?.endsWith('@merthin.com');
const reportWhiteList = user?.email?.endsWith('@sandbox.game');

export const getShowDiscordPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowDiscordPage) || staffCheck;
export const getShowBillingPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowBillingPage) || staffCheck;
export const getShowKolsPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowKolsPage) || staffCheck;
export const getShowTelegramBotPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowTelegramBotPage) || staffCheck;
export const getShowReportsPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowReportsPage) ||
  staffCheck ||
  reportWhiteList;
export const getShowMultiCampaign = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowMultiCampaign) || staffCheck;
export const getShowSignUpPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowSignUpButton) || staffCheck;
export const getSupportValidationFlow = () =>
  useFeatureFlagEnabled(FeatureFlags.SupportValidationFlow);
export const showNewReportPage = () =>
  getShowReportsPage() && getShowMultiCampaign();
