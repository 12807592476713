import { Anchor, Flex, ScrollArea, Title } from '@mantine/core';
import { FunctionComponent } from 'react';
import { WalletAccount } from '../../../data/account';
import { ForgeTable, Row } from '../../Table/ForgeTable';

export interface SocialsProps {
  isLoading?: boolean;
  accounts?: WalletAccount[];
}

const WalletsAccountTable: FunctionComponent<SocialsProps> = props => {
  const headers = ['Account', 'Wallet Addr', 'Link'];
  const accountLinkRegistry = new Map<string, string>([
    ['ethereum', 'https://etherscan.io/address/'],
    ['sui', 'https://suiscan.xyz/mainnet/account/'],
    ['polygon', 'https://polygonscan.com/address/'],
    ['solana', 'https://explorer.solana.com/address/'],
    ['avalanche', 'https://subnets.avax.network/c-chain/address/'],
    ['ronin', 'https://app.roninchain.com/address/'],
  ]);

  const getAccountLink = (account: WalletAccount) => {
    if (!account.blockchain) {
      return '';
    }
    const value =
      accountLinkRegistry.get(account.blockchain) + account.wallet_addr;
    return <Anchor href={value}>{value}</Anchor>;
  };

  const getRows = (): Row[] => {
    if (!props.accounts) {
      return [];
    }
    return props.accounts.map(account => {
      if (!account) {
        return { elements: [] };
      }
      return {
        elements: [
          account.blockchain,
          account.wallet_addr,
          getAccountLink(account),
        ],
      };
    });
  };

  return (
    <Flex direction={'column'}>
      <Title size={'s'}>Linked Wallets</Title>
      <ScrollArea>
        <ForgeTable
          headers={headers}
          rows={getRows()}
          isLoading={props.isLoading}
        />
      </ScrollArea>
    </Flex>
  );
};

export default WalletsAccountTable;
