import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Campaign } from '../data/campaign';
import { CampaignOption } from '../data/campaign-option';
import { Organization } from '../data/organization';
import { noContentPages } from '../data/paths';
import { useDataFetcher } from '../hooks/use-data-fetcher';
import { useNotification } from '../hooks/use-notification';
import { campaignService } from '../services/api-client/campaign-service';
import { ssPartnerService } from '../services/api-client/ss-partner-service';
import { campaignDataService } from '../services/cmpaign-data-service';
import { localStorageService } from '../services/local-storage-service';
import { MessageStatus } from '../services/notification-service';

interface GlobalProviderProps {
  children: ReactNode;
}

export interface GlobalState {
  selectedCampaignOption?: CampaignOption;
  setSelectCampaignOption: Dispatch<SetStateAction<CampaignOption | undefined>>;
  setCampaignOptionsTrigger: Dispatch<SetStateAction<boolean | undefined>>;
  campaignOptionsTrigger?: boolean;
  campaignOptions?: CampaignOption[];
  setCampaignOptions: Dispatch<SetStateAction<CampaignOption[] | undefined>>;
  loadingCampaignsOptions?: boolean;
  selectedCampaignData?: Campaign;
  setSelectedCampaignData: Dispatch<SetStateAction<Campaign | undefined>>;
  loadingSelectedCampaignData?: boolean;
  partnerSlug?: string;
  setSelectedCampaignDataTrigger: Dispatch<SetStateAction<boolean | undefined>>;
  selectedCampaignDataTrigger?: boolean;
  selectedOrganization?: Organization;
  setSelectedOrganization: Dispatch<SetStateAction<Organization | undefined>>;
  setAddingCampaign: Dispatch<SetStateAction<boolean | undefined>>;
  addingCampaign?: boolean;
  isCampaignValid?: boolean;
  setIsCampaignValid: Dispatch<SetStateAction<boolean | undefined>>;
  publishingCampaign?: boolean;
  setPublishingCampaign: Dispatch<SetStateAction<boolean | undefined>>;
}

const initialGlobalContextValues: GlobalState = {
  setCampaignOptionsTrigger: () => null,
  setSelectCampaignOption: () => null,
  setSelectedCampaignData: () => null,
  setSelectedCampaignDataTrigger: () => null,
  setSelectedOrganization: () => null,
  setCampaignOptions: () => null,
  setAddingCampaign: () => null,
  setIsCampaignValid: () => null,
  setPublishingCampaign: () => null,
};

export const GlobalContext = createContext<GlobalState>(
  initialGlobalContextValues
);

const GlobalProvider = (props: GlobalProviderProps) => {
  const notification = useNotification();
  const [partnerSlug] = useState<string | undefined>(
    localStorageService.getSelectedOrganization()?.partnerSlug
  );
  const [selectedOrganization, setSelectedOrganization] = useState<
    Organization | undefined
  >(localStorageService.getSelectedOrganization() ?? undefined);
  const [campaignOptionsTrigger, setCampaignOptionsTrigger] =
    useState<boolean>();
  const [selectedCampaignDataTrigger, setSelectedCampaignDataTrigger] =
    useState<boolean>();
  const [selectedCampaignOption, setSelectCampaignOption] =
    useState<CampaignOption>();
  const [addingCampaign, setAddingCampaign] = useState<boolean>();
  const [isCampaignValid, setIsCampaignValid] = useState<boolean>();
  const [publishingCampaign, setPublishingCampaign] = useState<boolean>();

  const [campaignOptions, loadingCampaignsOptions, setCampaignOptions] =
    useDataFetcher({
      serviceCall() {
        if (partnerSlug) {
          return ssPartnerService.getDraftCampaigns(partnerSlug);
        }
      },
      dependencies: [partnerSlug, campaignOptionsTrigger],
      conditionForExecution:
        !!partnerSlug && !noContentPages.includes(window.location.pathname),
      onFinish(response) {
        if (!response) {
          return;
        }
        if (!selectedCampaignOption) {
          setSelectCampaignOption(response[0]);
          return;
        }
      },
      onError(error: any) {
        notification.notify(
          MessageStatus.Error,
          'Error',
          `Error fetching campaigns options: ${error?.response?.data?.message ?? 'Unknown error'}`
        );
      },
    });

  const [
    selectedCampaignData,
    loadingSelectedCampaignData,
    setSelectedCampaignData,
  ] = useDataFetcher({
    serviceCall() {
      if (selectedCampaignOption) {
        return campaignService.getCampaignById(
          selectedCampaignOption.draft_campaign_id
        );
      }
    },
    dependencies: [selectedCampaignOption, selectedCampaignDataTrigger],
    conditionForExecution: !!selectedCampaignOption,
    onError(error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error fetching campaign data: ${error?.response?.data?.message ?? 'Unknown error'}`
      );
    },
  });

  useEffect(() => {
    if (selectedCampaignData) {
      console.log(selectedCampaignData);
      const isValid =
        campaignDataService.isCampaignValidToPublish(selectedCampaignData);
      setIsCampaignValid(isValid);
      return;
    }
    setIsCampaignValid(false);
  }, [selectedCampaignData]);

  return (
    <GlobalContext.Provider
      value={{
        campaignOptions,
        loadingCampaignsOptions,
        setCampaignOptionsTrigger,
        setSelectCampaignOption,
        setSelectedCampaignData,
        setCampaignOptions,
        campaignOptionsTrigger,
        selectedCampaignOption,
        selectedCampaignData,
        loadingSelectedCampaignData,
        partnerSlug,
        setSelectedCampaignDataTrigger,
        selectedCampaignDataTrigger,
        setSelectedOrganization,
        selectedOrganization,
        setAddingCampaign,
        addingCampaign,
        isCampaignValid,
        setIsCampaignValid,
        publishingCampaign,
        setPublishingCampaign,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
