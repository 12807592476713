import { TextInput } from '@mantine/core';
import { FunctionComponent, useState } from 'react';
import { Campaign } from '../../../../data/campaign';

export interface SocialElementProp {
  defaultValue: string;
  fieldName: keyof Campaign['extra_data']['social'];
  onUpdate: (
    fieldName: keyof Campaign['extra_data']['social'],
    newValue: string
  ) => void;
}

const prefixRegistry = new Map<keyof Campaign['extra_data']['social'], string>([
  ['discord', 'https://discord.com/invite/'],
  ['twitter', 'https://x.com/'],
  ['youtube', 'https://www.youtube.com/@'],
  ['telegram', 'https://t.me/'],
  ['medium', 'https://medium.com/@'],
  ['opensea', 'https://opensea.io/collection/'],
]);

const SocialElement: FunctionComponent<SocialElementProp> = props => {
  const { defaultValue, fieldName, onUpdate } = props;
  const prefix = prefixRegistry.get(fieldName);
  const placeHolder = `${prefix}...`;
  const [inputValue, setInputValue] = useState(defaultValue);

  const checkTwitterOptions = (value: string) => {
    if (!prefix) {
      throw new Error('Prefix not found');
    }
    if (
      value.includes('https://twitter.com/') ||
      value.includes('https://x.com/intent/follow?screen_name=')
    ) {
      return value.replace('https://twitter.com/', prefix);
    }
    return value;
  };

  const handleInputChange = (value: string) => {
    let tempValue = value;
    if (!prefix || !placeHolder) {
      throw new Error('Prefix not found');
    }
    tempValue = checkTwitterOptions(tempValue);
    if (tempValue === prefix || tempValue === placeHolder) {
      setInputValue('');
      onUpdate(fieldName, '');
      return;
    }
    tempValue = tempValue.replaceAll(prefix, '').replaceAll('...', '');
    if (prefix.endsWith('@')) {
      tempValue = tempValue.replace('@', '');
    }
    tempValue = prefix + tempValue;
    tempValue.trim();
    setInputValue(tempValue);
    if (tempValue !== prefix && tempValue !== placeHolder) {
      onUpdate(fieldName, tempValue);
    } else {
      onUpdate(fieldName, '');
    }
  };

  return (
    <TextInput
      opacity={inputValue === placeHolder ? 0.7 : 1}
      label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
      prefix={prefixRegistry.get(fieldName)}
      defaultValue={defaultValue}
      placeholder={prefix + '...'}
      w={'100%'}
      value={inputValue}
      onChange={event => {
        handleInputChange(event.target.value);
      }}
    />
  );
};

export default SocialElement;
