import { Image } from '@mantine/core';
import {
  AiOutlineAntDesign,
  AiOutlineApi,
  AiOutlineSearch,
  AiOutlineUsergroupAdd,
} from 'react-icons/ai';
import { BiHomeSmile, BiMoneyWithdraw, BiSolidReport } from 'react-icons/bi';
import { FaDiscord, FaRocket, FaTelegram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { LocalImages } from '../assets/images';
import { NavLink } from '../components/SideBar/components/NavbarLink/NavbarLink';
import { Path } from '../data/paths';
import {
  getShowBillingPage,
  getShowDiscordPage,
  getShowKolsPage,
  getShowMultiCampaign,
  getShowReportsPage,
  getShowTelegramBotPage,
} from './feature-flags';

export const getDefaultLinks = (): NavLink[] => [
  {
    label: 'Home',
    slug: Path.Home,
    icon: <BiHomeSmile size={'1.625rem'} />,
    disable: false,
  },
  {
    label: 'Reports',
    slug: Path.Report,
    icon: <BiSolidReport size={'1.625rem'} />,
    disable: !getShowReportsPage() ?? true,
  },
  {
    label: 'Reports New',
    slug: Path.ReportNew,
    icon: <BiSolidReport size={'1.625rem'} />,
    disable: !getShowMultiCampaign() ?? true,
  },
  {
    label: 'Game',
    slug: Path.GameCustomization,
    icon: <AiOutlineAntDesign size={'1.625rem'} />,
    disable: false,
  },
  {
    label: 'Game-New',
    slug: Path.Game,
    icon: <AiOutlineAntDesign size={'1.625rem'} />,
    disable: !getShowMultiCampaign() ?? true,
  },
  {
    label: 'Actions',
    slug: Path.Actions,
    icon: <FaRocket size={'1.625rem'} />,
    disable: false,
  },
  {
    label: 'Actions-New',
    slug: Path.ActionsNew,
    icon: <FaRocket size={'1.625rem'} />,
    disable: !getShowMultiCampaign() ?? true,
  },
  {
    label: 'Tweets',
    slug: Path.Tweets,
    icon: <FaXTwitter size={'1.625rem'} />,
    disable: false,
  },
  {
    label: 'Tweets-New',
    slug: Path.TweetsNew,
    icon: <FaXTwitter size={'1.625rem'} />,
    disable: !getShowMultiCampaign() ?? true,
  },
  {
    label: 'User Lookup',
    slug: Path.UserLookup,
    icon: <AiOutlineSearch size={'1.625rem'} />,
    disable: false,
  },
  {
    label: 'Teams',
    slug: Path.Collaborators,
    icon: <AiOutlineUsergroupAdd size={'1.625rem'} />,
    disable: false,
    position: 'bottom',
  },
  {
    label: 'Billing',
    slug: Path.Billing,
    icon: <BiMoneyWithdraw size={'1.625rem'} />,
    disable: !getShowBillingPage() ?? true,
    position: 'bottom',
  },
  {
    label: 'Discord',
    slug: Path.Discord,
    icon: <FaDiscord size={'1.625rem'} />,
    disable: !getShowDiscordPage ?? true,
  },
  {
    label: 'API',
    slug: Path.Api,
    icon: <AiOutlineApi size={'1.625rem'} />,
    disable: false,
  },
  {
    label: 'KOLVerse',
    slug: Path.Kols,
    icon: <Image src={LocalImages.kolverse} w={'1.625rem'} />,
    disable: !getShowKolsPage() ?? true,
  },
  {
    label: 'Telegram',
    slug: Path.TelegramBot,
    icon: <FaTelegram size={'1.625rem'} />,
    disable: !getShowTelegramBotPage() ?? true,
  },
];
