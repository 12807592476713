import { configuration } from '../../configuration';
import { FeaturedTweet } from '../../data/featured-tweet';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class TweetsService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'featured-tweets');
  }

  public async getFeaturedTweets(campaignId: string): Promise<FeaturedTweet[]> {
    return await this.apiClient.get(this.url(`campaign/${campaignId}`));
  }

  public async deleteFeaturedTweets(ids: string[], campaignId: string) {
    return await this.apiClient.delete(this.url(`campaign/${campaignId}`), {
      data: ids,
    });
  }

  public async addFeaturedTweet(
    tweetId: string,
    htmlCode: string,
    partnerSlug: string,
    campaignId: string
  ): Promise<FeaturedTweet> {
    return await this.apiClient.post(this.url(`${partnerSlug}/${campaignId}`), {
      id: tweetId,
      embedHtml: htmlCode,
    });
  }

  public async publishFeaturedTweet(tweetId: string[], campaignId: string) {
    const url = this.url(`/active/campaign/${campaignId}`);
    return await this.apiClient.post(url, {
      ids: tweetId,
    });
  }
}
export const tweetsService = new TweetsService(
  new BearerTokenApiClient(configuration.backendApi.url, true)
);
