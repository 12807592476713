import { ScrollArea } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';
import './Layout.scss';

export interface LayoutProps {
  variant?: 'FullSidebar' | undefined;
  children: ReactNode;
  toolBar?: ReactNode;
  sideBar?: ReactNode;
  footer?: ReactNode;
  sidebarExpanded?: boolean;
  sidebarHidden?: boolean;
  isControlledSidebar?: boolean;
}

export const Layout: FunctionComponent<LayoutProps> = props => {
  const getLayoutClassnames = () => {
    if (props.sidebarHidden && props.sidebarExpanded) return '';
    if (props.sidebarHidden) return 'Hidden';
    if (!props.sidebarExpanded) return 'Collapsed';
    return '';
  };

  return (
    <>
      {props.variant === 'FullSidebar' ? (
        <div
          className={`Layout FullSidebar ${props.isControlledSidebar ? '' : 'Automatic'}`}
        >
          <div className="LayoutWrapper">
            {props.isControlledSidebar ? (
              <div className={`LayoutSidebar ${getLayoutClassnames()}`}>
                {props.sideBar && (
                  <div className="LayoutSidebarWrapper">{props.sideBar}</div>
                )}
              </div>
            ) : (
              <div className="LayoutSidebar">
                {props.sideBar && (
                  <div className="LayoutSidebarWrapper">{props.sideBar}</div>
                )}
              </div>
            )}
            <div className="LayoutContent">
              {props.toolBar && (
                <div className="LayoutToolBar">{props.toolBar}</div>
              )}
              <ScrollArea className="LayoutContentWrapper">
                {props.children}
              </ScrollArea>
              {props.footer && (
                <div className="LayoutFooter">
                  <div className="LayoutFooterWrapper">{props.footer}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`Layout ${props.isControlledSidebar ? '' : 'Automatic'}`}
        >
          {props.toolBar && (
            <div className="LayoutToolBar">{props.toolBar}</div>
          )}
          <div className="LayoutWrapper">
            <div className={`LayoutSidebar ${getLayoutClassnames()}`}>
              {props.sideBar && (
                <div className="LayoutSidebarWrapper">{props.sideBar}</div>
              )}
            </div>
            <div className="LayoutContent">
              <div className="LayoutContentWrapper">{props.children}</div>
              <div className="LayoutFooter">
                {props.footer && (
                  <div className="LayoutFooterWrapper">{props.footer}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
