import { FaDiscord } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { GiClick, GiHillConquest } from 'react-icons/gi';
import { GrAchievement } from 'react-icons/gr';
import { QuestIcons } from './quest';

export const iconRegistry = new Map<string, JSX.Element>([
  [QuestIcons.Discord, <FaDiscord size={'2rem'} />],
  [QuestIcons.Achievement, <GrAchievement size={'2rem'} />],
  [QuestIcons.DailyActiveTweet, <FaXTwitter size={'2rem'} />],
  [QuestIcons.Twitter, <FaXTwitter size={'2rem'} />],
  [QuestIcons.ClickLink, <GiClick size={'2rem'} />],
]);

export const getIcon = (icon?: string | null) => {
  if (!icon) {
    return <GiHillConquest size={'2rem'} />;
  }
  return iconRegistry.get(icon) ?? <GiHillConquest size={'2rem'} />;
};
