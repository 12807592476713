import { configuration } from '../../configuration';
import { StripeCharge } from '../../data/stripe-transaction-data';
import { StripeLink, UpSellData } from '../../data/up-sell-data';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class UpSellService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'up-sell');
  }

  public async getProCheckoutLink(
    email: string,
    partnerSlug: string
  ): Promise<string> {
    const link = await this.apiClient.post<
      { email: string; partner: string },
      StripeLink
    >(this.url('pro-checkout-link'), {
      email,
      partner: partnerSlug,
    });
    return link.url;
  }

  public async sendContactUsEmail(upSellData: UpSellData): Promise<void> {
    await this.apiClient.post(this.url(), upSellData);
  }

  public async getLoyaltyProgramTransactions(
    partnerSlug: string
  ): Promise<StripeCharge[]> {
    const items = await this.apiClient.get<StripeCharge[]>(
      this.url(`transactions/${partnerSlug}`)
    );
    return items;
  }
}
export const upSellService = new UpSellService(
  new BearerTokenApiClient(configuration.backendApi.url, false)
);
