import { ClosureReportOptions } from '../../components/Metrics/ClosureMetric/ClosureMetric';
import { configuration } from '../../configuration';
import {
  ClosureReport,
  ClosureReportDB,
  CompletedQuestReport,
  ReportIdentifier,
  ServiceReportData,
  UsersWithCompletedQuestsCountReport,
} from '../../data/metrics-data';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

const pageSize = 20;

class ReportsService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'metrics');
  }

  public async sendReportRequest(
    metricIdentifier: ReportIdentifier,
    campaignId: string,
    payload?: any
  ) {
    switch (metricIdentifier) {
      case ReportIdentifier.ClosureReport: {
        await this.apiClient.post<any, any>(this.url('closure-export'), {
          services: payload.selectedServices,
          blockchains: payload.selectedBlockchains,
          campaignId: campaignId,
        });
        break;
      }
      case ReportIdentifier.CompletedQuestsWithUserCount: {
        await this.apiClient.post<any, any>(
          this.url(`users-count-by-completed-quests-export/${campaignId}`),
          {}
        );
        break;
      }
      case ReportIdentifier.UsersWithCompletedQuestsCount: {
        await this.apiClient.post<any, any>(
          this.url(`users-with-completed-quests-count-export/${campaignId}`),
          {}
        );
        break;
      }
      default: {
        throw new Error('Invalid metric identifier');
      }
    }
  }

  public async getClosureMetrics(
    closureReportOptions: ClosureReportOptions,
    campaignId: string
  ): Promise<ServiceReportData<ClosureReport[]>> {
    const metric = await this.apiClient.post<any, ClosureReportDB[]>(
      this.url('closure'),
      {
        services: closureReportOptions.selectedServices,
        blockchains: closureReportOptions.selectedBlockchains,
        campaignId: campaignId,
        pageNumber: closureReportOptions.pageNumber,
        pageSize: closureReportOptions.pageSize,
      }
    );
    return {
      data: metric,
      type: ReportIdentifier.ClosureReport,
      totalRows: metric.length > 0 ? metric[0].totalRows : 0,
    };
  }

  public async sendClosureExportRequest(
    closureReportOptions: ClosureReportOptions,
    campaignId: string
  ): Promise<void> {
    await this.apiClient.post<any, any>(this.url('closure-export'), {
      services: closureReportOptions.selectedServices,
      blockchains: closureReportOptions.selectedBlockchains,
      campaignId: campaignId,
    });
  }

  public async getCompletedQuestsWithUserCount(
    pageNumber: number,
    campaignId: string
  ): Promise<ServiceReportData<CompletedQuestReport[]>> {
    const url = this.url(
      `users-count-by-completed-quests/${campaignId}?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    const metric = await this.apiClient.get<any>(url);
    return {
      data: metric,
      type: ReportIdentifier.CompletedQuestsWithUserCount,
      totalRows: metric.length > 0 ? metric[0].totalRows : 0,
    };
  }

  public async getCompletedQuestUserCount(campaignId: string): Promise<number> {
    const url = this.url(`completed-quest-user-count/${campaignId}`);
    const response = await this.apiClient.get<{ users_count: number }>(url);
    return response.users_count;
  }

  public async getUsersWithCompletedQuestsCount(
    pageNumber: number,
    campaignId: string
  ): Promise<ServiceReportData<UsersWithCompletedQuestsCountReport[]>> {
    const url = this.url(
      `users-with-completed-quests-count/${campaignId}?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    const metric = await this.apiClient.get<any>(url);
    return {
      data: metric,
      type: ReportIdentifier.UsersWithCompletedQuestsCount,
      totalRows: metric.length > 0 ? metric[0].totalRows : 0,
    };
  }
}

export const reportsService = new ReportsService(
  new BearerTokenApiClient(configuration.backendApi.url, true)
);
