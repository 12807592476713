import {
  Button,
  Flex,
  MultiSelect,
  Skeleton,
  Title,
  Tooltip,
} from '@mantine/core';
import { FunctionComponent } from 'react';
import { CampaignAccountDetails } from '../../data/campaign-account';

export interface CampaignAccountOptionsOrgs {
  loadingData?: boolean;
  saving?: boolean;
  data?: CampaignAccountDetails;
  saveConnectedAccountDisable?: boolean;
  handleOnConnectedAccountChange: (value: string[]) => void;
  handleOnConnectedAccountSave: () => void;
}

const CampaignAccountOptions: FunctionComponent<
  CampaignAccountOptionsOrgs
> = props => {
  const {
    loadingData,
    data,
    handleOnConnectedAccountChange,
    handleOnConnectedAccountSave,
    saveConnectedAccountDisable,
    saving,
  } = props;

  if (loadingData) {
    return <Skeleton w={'20%'} h={'3rem'} />;
  }

  return (
    <Flex gap={'1rem'} align={'flex-end'}>
      <Flex direction={'column'} gap={'0.2rem'} align={'flex-start'}>
        <Tooltip
          multiline
          maw={'20rem'}
          label="Required user accounts are the accounts that users must connect to in order to complete the actions."
        >
          <Title size={'0.9em'}>Required User Accounts</Title>
        </Tooltip>
        <MultiSelect
          disabled={!data}
          data={data?.options.map(x => x.accountName)}
          defaultValue={data?.accounts.map(x => x.accountName)}
          onChange={handleOnConnectedAccountChange}
          w={'20rem'}
        />
      </Flex>
      <Button
        loading={loadingData || saving}
        disabled={saveConnectedAccountDisable}
        onClick={handleOnConnectedAccountSave}
      >
        Save
      </Button>
    </Flex>
  );
};

export default CampaignAccountOptions;
