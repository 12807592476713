import { Campaign } from '../data/campaign';

class CampaignDataService {
  public isCampaignValidToPublish(campaign: Campaign) {
    return this._basicCheck(campaign) && this._extraDataCheck(campaign);
  }
  private _basicCheck(campaign: Campaign) {
    if (
      !campaign.title ||
      campaign.title === '' ||
      !campaign.subtitle ||
      campaign.subtitle === '' ||
      !campaign.description ||
      campaign.description === '' ||
      !campaign.genre ||
      campaign.genre === '' ||
      !campaign.start_datetime ||
      !campaign.end_datetime
    ) {
      return false;
    }
    return true;
  }

  private _extraDataCheck(campaign: Campaign) {
    if (
      !campaign.extra_data ||
      !campaign.extra_data.background ||
      !campaign.extra_data.poster ||
      !campaign.extra_data.week_quests_bg ||
      !campaign.extra_data.icon ||
      !campaign.extra_data.developer ||
      !campaign.extra_data.game_mode ||
      !campaign.extra_data.platform ||
      !campaign.extra_data.release_date ||
      !campaign.extra_data.website ||
      !campaign.extra_data.play_now ||
      !campaign.extra_data.partner_name
    ) {
      return false;
    }
    return true;
  }
}

export const campaignDataService = new CampaignDataService();
