import { Divider, Flex, Text, Textarea, TextInput } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';

export interface BasicAssetsSectionProps {
  title?: string;
  subtitle?: string;
  onTitleChange?: (title: string) => void;
  onSubtitleChange?: (subtitle: string) => void;
  setPublishEnabled?: (enabled: boolean) => void;
}

const BasicInfoSection: FunctionComponent<BasicAssetsSectionProps> = props => {
  const { onSubtitleChange: onDescriptionChange, onTitleChange } = props;
  const [title, setTitle] = useState(props.title);
  const [subtitle, setSubtitle] = useState(props.subtitle);
  useEffect(() => {
    evaluateConditionForPublish();
  }, [title, subtitle]);

  const evaluateConditionForPublish = () => {
    const isFormValid =
      !!title && title !== '' && !!subtitle && subtitle !== '';
    props.setPublishEnabled?.(isFormValid);
  };

  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <Divider label={'Basics'} />
      <TextInput
        error={!title || title === '' ? 'This field is required' : null}
        required
        defaultValue={title}
        label={'Game Name'}
        description={'Please include the full name of your game'}
        onChange={event => {
          setTitle(event.currentTarget.value);
          onTitleChange?.(event.currentTarget.value);
        }}
      />
      <Flex direction={'column'} align={'flex-end'} w={'100%'} gap={'0.15rem'}>
        <Textarea
          error={!subtitle || subtitle === '' ? 'This field is required' : null}
          defaultValue={subtitle}
          required
          maxLength={500}
          resize="vertical"
          label={'Loyalty Program Description'}
          description={`Get users excited about your game/project and 
          give them specific details about the rewards they can earn
          by participating in your Loyalty Program. Please be specific
           and include the exact rewards users can expect to receive by
            participating and the end-date for your campaign here.`}
          onChange={event => {
            setSubtitle(event.currentTarget.value);
            onDescriptionChange?.(event.currentTarget.value);
          }}
        />
        {
          <Text size="xs" c={'#868e96'}>
            Limited to 500 characters
          </Text>
        }
      </Flex>
    </Flex>
  );
};

export default BasicInfoSection;
