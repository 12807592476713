import { Badge, Card, Flex, Text } from '@mantine/core';
import { FunctionComponent } from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { RiRefreshLine } from 'react-icons/ri';
import { CollaboratorInvite } from '../../../data/collaborator-invite';

export interface CollaboratorInviteProps {
  invite: CollaboratorInvite;
  onRefresh?: () => void;
}

const CollaboratorInviteElement: FunctionComponent<
  CollaboratorInviteProps
> = props => {
  return (
    <Card w={'100%'} h={'100%'} shadow="sm" padding="lg" radius="md" withBorder>
      <Flex
        direction={'row'}
        w={'100%'}
        gap={'1rem'}
        align={'self-start'}
        justify={'space-between'}
      >
        <Flex direction={'row'} gap={'1rem'} align={'center'}>
          <AiOutlineUserAdd size={'1.5rem'} />
          <Text>
            {props.invite.email ? `Invitation for: ${props.invite.email}` : ''}
          </Text>
        </Flex>
        <Flex gap={'1rem'} align={'center'}>
          {props.invite.status !== 'claimed' && (
            <RiRefreshLine
              cursor={'pointer'}
              size={'1rem'}
              onClick={() => {
                props.onRefresh?.();
              }}
            />
          )}
          <Badge bg={props.invite.status === 'claimed' ? 'green' : 'orange'}>
            {props.invite.status}
          </Badge>
        </Flex>
      </Flex>
    </Card>
  );
};

export default CollaboratorInviteElement;
