import logoGradBackground from './resources/background-grad-logo.svg';
import horizontalLogo from './resources/horizontal-logo.svg';
import kolverse from './resources/kolverse.svg';
import logo from './resources/logo.svg';
import logoBackground from './resources/logo_background.svg';
import monster from './resources/monster.svg';
import smallLogo from './resources/small-logo.svg';
import telegram from './resources/telegram-icon.svg';
import union from './resources/union.png';
import wolfIcon from './resources/wolf-icon.svg';

export const LocalImages = {
  logo: logo,
  smallLogo: smallLogo,
  horizontalLogo: horizontalLogo,
  wolfIcon: wolfIcon,
  logoBackground: logoBackground,
  logoGradBackground: logoGradBackground,
  union: union,
  monster: monster,
  telegram: telegram,
  kolverse: kolverse,
};
