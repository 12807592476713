import { Button, Divider, Flex } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GameEditionSection from '../../components/Game/GameEditionSection';
import { GlobalContext } from '../../context/GlobalContext';
import { Campaign } from '../../data/campaign';
import { CampaignAssetModification } from '../../data/campaign-assets';
import { getSkeletons } from '../../helper/skeletons';
import { useFromCheckList } from '../../hooks/use-from-checklist';
import { useNotification } from '../../hooks/use-notification';
import { campaignService } from '../../services/api-client/campaign-service';

import { ssPartnerService } from '../../services/api-client/ss-partner-service';
import { MessageStatus } from '../../services/notification-service';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const notification = useNotification();
  const {
    loadingCampaignsOptions,
    selectedCampaignOption,
    selectedCampaignData,
    loadingSelectedCampaignData,
    partnerSlug,
    campaignOptionsTrigger,
    setCampaignOptionsTrigger,
    setSelectedCampaignDataTrigger,
    selectedCampaignDataTrigger,
    setAddingCampaign,
    addingCampaign,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [addingDraft, setAddingDraft] = useState<boolean>(false);
  const [isFromCheckList, setIsFromChecklist] = useFromCheckList();
  const [savingEditedCampaign, setSavingEditedCampaign] =
    useState<boolean>(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [publishingCampaign, setPublishingCampaign] = useState<boolean>(false);
  const [assets, setAssets] = useState<CampaignAssetModification>({});
  const [selectedCampaign, setSelectedCampaign] = useState<
    Campaign | undefined
  >(selectedCampaignData);

  useEffect(() => {
    setSelectedCampaign(selectedCampaignData);
  }, [selectedCampaignData]);

  const handleSaveEditedCampaign = async () => {
    if (!selectedCampaign) {
      return;
    }
    try {
      setSavingEditedCampaign(true);
      await campaignService.edit(selectedCampaign);
      const assetsPromises = [
        campaignService.saveScreenShotsAssets(
          assets,
          selectedCampaign.campaign_id
        ),
        campaignService.saveIconAsset(assets, selectedCampaign.campaign_id),
        campaignService.saveBackgroundAsset(
          assets,
          selectedCampaign.campaign_id
        ),
        campaignService.savePoster(assets, selectedCampaign.campaign_id),
        campaignService.saveBannerAsset(assets, selectedCampaign.campaign_id),
        campaignService.saveWeekQuestsBgAsset(
          assets,
          selectedCampaign.campaign_id
        ),
      ];
      await Promise.all(assetsPromises);
      setSelectedCampaignDataTrigger(!selectedCampaignDataTrigger);
      notification.notify(
        MessageStatus.Success,
        'Success',
        'Campaign edited successfully'
      );
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Game Error',
        error.response.data.message ?? 'Error editing the campaign'
      );
    } finally {
      setEnableSave(false);
      setSavingEditedCampaign(false);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
      if (isFromCheckList) {
        setIsFromChecklist(false);
        navigate('/home');
      }
    }
  };

  const handelAddNewDraft = async () => {
    try {
      setAddingDraft(true);
      setAddingCampaign(true);
      if (!partnerSlug) {
        return;
      }
      await ssPartnerService.addDraftCampaign(partnerSlug);
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error adding new draft: ${error?.response?.data?.message ?? 'Unknown error'}`
      );
    } finally {
      setAddingDraft(false);
      setAddingCampaign(false);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    }
  };

  if (
    loadingSelectedCampaignData ||
    loadingCampaignsOptions ||
    savingEditedCampaign ||
    publishingCampaign
  ) {
    return (
      <Flex
        align={'center'}
        gap={'1rem'}
        direction={'column'}
        justify={'center'}
        h={'100%'}
        w={'100%'}
        p={'2rem'}
      >
        {getSkeletons(10)}
      </Flex>
    );
  }

  const handleCampaignChange = (
    campaign: Campaign,
    assets: CampaignAssetModification
  ) => {
    setSelectedCampaign(campaign);
    setAssets(assets);
  };

  return (
    <Flex
      direction={'column'}
      className="GamePage"
      w={'100%'}
      align={'center'}
      h={'100%'}
    >
      <Flex
        direction={'column'}
        w={'100%'}
        pos={'sticky'}
        top={0}
        style={{ zIndex: 200 }}
        bg={'#f9fafb'}
      >
        <Flex align={'center'} justify={'flex-end'} gap={'md'} p={'xs'}>
          <Button
            loading={savingEditedCampaign}
            disabled={!enableSave}
            onClick={handleSaveEditedCampaign}
          >
            Save
          </Button>
        </Flex>
        <Divider />
      </Flex>
      <GameEditionSection
        campaign={selectedCampaignData}
        addingDraft={addingDraft || addingCampaign}
        addNewDraft={handelAddNewDraft}
        onCampaignChange={handleCampaignChange}
        setSaveEnabled={setEnableSave}
      ></GameEditionSection>
    </Flex>
  );
};

export const GamePage = withAuthProtection(Page);
