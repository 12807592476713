import * as Frigade from '@frigade/react';
import { Card, Flex } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const navigate = useNavigate();
  return (
    <Flex className="HomePageWrapper" justify={'center'} align={'center'}>
      <Card w={'100%'} h={'100%'} m={'lg'} withBorder={false} bg="transparent">
        <Frigade.Checklist.Collapsible
          forceMount={true}
          dismissible={false}
          flowId="flow_8NW9cxYj"
          onPrimary={e => {
            navigate(e.primaryButton?.target ?? '/');
          }}
        />
      </Card>
    </Flex>
  );
};
export const HomePage = withAuthProtection(Page);
