import {
  Anchor,
  Flex,
  FlexProps,
  Image,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { FunctionComponent } from 'react';
import { SignUpCredentials } from '../../data/sign-up-credentials';
import './SignUp.scss';
import { SignUpForm } from './SignUpForm/SignUpForm';

export interface SignUpProps {
  failedSignUpMessage?: string | undefined;
  logo?: string;
  termsAgreementMessage?: string;
  isLoading?: boolean;
  onSignUpClick?: (loginCredentials: SignUpCredentials) => void;
  backToLogin?: () => void;
  style?: FlexProps;
}

export const SignUp: FunctionComponent<SignUpProps> = props => {
  return (
    <Flex
      className="login-page"
      justify={'center'}
      align={'center'}
      w={'100%'}
      h="100vh"
      {...props.style}
    >
      <Paper
        className="login-container"
        maw="30rem"
        p={'1.5rem 2.5rem'}
        shadow={'xl'}
        radius={'md'}
      >
        <Stack gap={'2rem'}>
          <Stack align="center">
            {props.logo && (
              <Image
                className="LogoImage"
                maw="12rem"
                mah="5rem"
                fit="contain"
                src={props.logo}
              />
            )}
          </Stack>
          <SignUpForm
            isLoading={props.isLoading}
            onSignUpClick={props.onSignUpClick}
            backToLogin={props.backToLogin}
            failedSignUpMessage={props.failedSignUpMessage}
          />
          <Stack gap={'0.5rem'} align="center">
            <Anchor
              href="https://forge-gg.gitbook.io/the-fine-print"
              target="_blank"
              size="xs"
              underline="always"
              c={'dark'}
            >
              Terms of Service
            </Anchor>
            <Text size="xs" c="dark" ta="center">
              {props.termsAgreementMessage ??
                'By continuing, you acknowledge that you have read, understood, and agree to our terms and condition'}
            </Text>
          </Stack>
        </Stack>
      </Paper>
    </Flex>
  );
};
