import { IconButton } from '@chakra-ui/react';
import { Accordion, Group, HoverCard, Text } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';
import { FaRegLightbulb } from 'react-icons/fa';
import HintAccordionElement from './components/HintAccordionElement';

export interface HintsDialogProps {
  hints: HintElement[];
}

export interface HintElement {
  content: ReactNode;
  id: string;
  label: string;
  image?: string;
  description: string;
}

const HintsDialog: FunctionComponent<HintsDialogProps> = props => {
  const { hints } = props;
  const items = hints.map(item => (
    <Accordion.Item value={item.id} key={item.label}>
      <Accordion.Control>
        <HintAccordionElement {...item} />
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="sm">{item.content}</Text>
      </Accordion.Panel>
    </Accordion.Item>
  ));
  return (
    <Group
      justify="right"
      right={'-2rem'}
      bottom={'-4rem'}
      p={'6rem'}
      pos={'absolute'}
    >
      <HoverCard
        width={320}
        shadow="md"
        withArrow
        openDelay={200}
        closeDelay={400}
      >
        <HoverCard.Target>
          <IconButton
            pl={'1rem'}
            pr={'1rem'}
            bg={'#1c7ed6'}
            color="white"
            style={{
              cursor: 'pointer',
              borderBottomRightRadius: '0%',
            }}
            aria-label={''}
          >
            <FaRegLightbulb size={'1.3rem'} />
          </IconButton>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <Accordion chevronPosition="right" variant="contained">
            {items}
          </Accordion>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

export default HintsDialog;
