import { Button, Flex, Text, Title } from '@mantine/core';
import { FunctionComponent } from 'react';
import { FaXTwitter } from 'react-icons/fa6';
import { Kol } from '../../../data/kols';
import { ForgeTable, Row } from '../../Table/ForgeTable';
import KolName from '../KolName/KolName';

export interface KolsListProps {
  kols?: Kol[];
  isLoading?: boolean;
  onConnectKol?: (kol: Kol) => void;
}

export const KolsList: FunctionComponent<KolsListProps> = props => {
  const getHeaders = () => ['#', 'Name', 'Follower', 'Past Project', 'Price'];

  const getFollower = (kol: Kol) => {
    return (
      <Flex align={'center'} gap={'0.3rem'} className="FollowersSection">
        <FaXTwitter />
        <Text size="sm">{kol?.numberOfFollowers ?? '--'} followers</Text>
      </Flex>
    );
  };

  const getPastProject = (kol: Kol) => {
    return (
      <Text size="sm" ml={!kol.pastProjects ? '2rem' : '0'}>
        {kol.pastProjects ? kol.pastProjects.join(', ') : '---'}
      </Text>
    );
  };

  const getPrice = (price: string) => {
    return (
      <Text fw={700} size="sm">
        {price ?? '--'}
      </Text>
    );
  };

  const getContactButton = (kol: Kol) => {
    return (
      <Button
        className="FollowButton"
        onClick={() => props.onConnectKol?.(kol)}
        c={'pointer'}
        color={'blue'}
      >
        Contact
      </Button>
    );
  };

  const getRows = (): Row[] => {
    if (!props.kols) {
      return [];
    }
    return props.kols.map((kol, index) => {
      return {
        elements: [
          index + 1,
          <KolName {...kol} />,
          getFollower(kol),
          getPastProject(kol),
          getPrice(kol.price),
          getContactButton(kol),
        ],
      };
    });
  };

  return (
    <Flex direction={'column'} w={'100%'} mb={'2rem'}>
      <Title size={'1.8rem'}>All KOLs</Title>
      <ForgeTable
        isLoading={props.isLoading}
        headers={getHeaders()}
        rows={getRows()}
      />
    </Flex>
  );
};
