import { configuration } from '../../configuration';
import { TelegramChannel } from '../../data/telegram-channel';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

export const handleVariablesMap = ['twitter_handle', 'youtube_handle'];

class TelegramBotService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'telegram-bot');
  }

  public async getChannels(partnerSlug: string): Promise<TelegramChannel[]> {
    return this.apiClient.get<TelegramChannel[]>(this.url(partnerSlug));
  }

  public async setConfirmationCode(
    code: string,
    partnerSlug: string
  ): Promise<void> {
    return this.apiClient.post(this.url(partnerSlug), { code });
  }
}

export const telegramBotService = new TelegramBotService(
  new BearerTokenApiClient(configuration.backendApi.url)
);
